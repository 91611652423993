/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  background: #FAFAFA;
  min-height: 100vh; }
  .Wrapper .Header {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    padding: 16px;
    width: 100%;
    z-index: 500;
    display: flex;
    align-items: center;
    color: #202124;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box; }
    .Wrapper .Header .Icon {
      cursor: pointer;
      margin-right: 8px; }
    .Wrapper .Header .Title {
      margin-top: 4px; }
    .Wrapper .Header.SecondNav {
      opacity: 0;
      background: #FFFFFF;
      transition: opacity 0.3s;
      -webkit-transition: opacity 0.3s;
      -moz-transition: opacity 0.3s;
      box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
      -webkit-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
      -moz-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
      -ms-transform: 0px 1px 4px rgba(32, 33, 36, 0.05);
      -o-transform: 0px 1px 4px rgba(32, 33, 36, 0.05); }
    .Wrapper .Header.Show {
      opacity: 1; }
  .Wrapper .Content {
    position: relative;
    z-index: 1; }
    .Wrapper .Content .Title {
      text-align: center;
      font-size: 2rem;
      color: #202124;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
    .Wrapper .Content .SubTitle {
      font-size: 1.6rem;
      line-height: 1.6em;
      color: #202124;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
    .Wrapper .Content .TextContent {
      z-index: 1;
      padding: 0 16px 16px;
      position: relative;
      margin: -64px 16px 0; }
    .Wrapper .Content .SearchForm {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center; }
      .Wrapper .Content .SearchForm .FirstIcon {
        left: 12px;
        position: absolute; }
      .Wrapper .Content .SearchForm .Input {
        width: 100%;
        border: 1px solid #E6E6E6;
        color: #6A6E73;
        padding: 11px 16px 11px 36px;
        font-size: 1.4rem;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        transition: border .3s, color .3s;
        -webkit-transition: border .3s, color .3s;
        -moz-transition: border .3s, color .3s;
        font-family: "Geomanist-Regular", sans-serif;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; }
      .Wrapper .Content .SearchForm .Input:focus {
        outline: none; }
    .Wrapper .Content .List {
      padding: 32px 16px;
      margin: 0; }
      .Wrapper .Content .List a {
        text-decoration: none;
        color: #6A6E73; }
      .Wrapper .Content .List .Item {
        background: #FFFFFF;
        list-style: none;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        padding: 16px 16px 16px 24px;
        box-shadow: 1px 1px 10px rgba(32, 33, 36, 0.05);
        -webkit-box-shadow: 1px 1px 10px rgba(32, 33, 36, 0.05);
        -moz-box-shadow: 1px 1px 10px rgba(32, 33, 36, 0.05);
        -ms-transform: 1px 1px 10px rgba(32, 33, 36, 0.05);
        -o-transform: 1px 1px 10px rgba(32, 33, 36, 0.05);
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        cursor: pointer; }
        .Wrapper .Content .List .Item .Icon {
          display: flex;
          width: 50px; }
        .Wrapper .Content .List .Item .Description {
          flex: 1;
          font-size: 1.3rem; }
          .Wrapper .Content .List .Item .Description .Name {
            font-size: 1.4rem;
            line-height: 1.3em;
            color: #202124;
            font-family: "Geomanist-Medium", sans-serif;
            font-weight: 500; }
          .Wrapper .Content .List .Item .Description .SubName {
            padding: 6px 0; }
  .Wrapper .Footer {
    width: 100%;
    display: flex;
    justify-content: center; }
    .Wrapper .Footer .Filter {
      width: 90px;
      background: #FFFFFF;
      margin-bottom: 16px;
      border-radius: 16px;
      -webkit-border-radius: 16px;
      -moz-border-radius: 16px;
      box-shadow: 0 1px 8px rgba(32, 33, 36, 0.1);
      -webkit-box-shadow: 0 1px 8px rgba(32, 33, 36, 0.1);
      -moz-box-shadow: 0 1px 8px rgba(32, 33, 36, 0.1);
      -ms-transform: 0 1px 8px rgba(32, 33, 36, 0.1);
      -o-transform: 0 1px 8px rgba(32, 33, 36, 0.1); }
      .Wrapper .Footer .Filter .Item {
        font-size: 1.2rem;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        color: #202124;
        padding: 0 16px;
        margin: 6px 0;
        display: flex;
        align-items: center; }
        .Wrapper .Footer .Filter .Item .Label {
          margin-left: 8px; }
      .Wrapper .Footer .Filter .Item:first-child {
        border-right: 1px solid #f5f5f5; }
