/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  width: 100%;
  height: 230px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  .Wrapper .Title {
    text-align: center;
    position: relative;
    color: #FFFFFF;
    font-size: 2.8rem;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    z-index: 2; }
  .Wrapper .Overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(32, 33, 36, 0.4);
    z-index: 1; }
  .Wrapper .Image {
    position: absolute;
    left: 50%;
    top: 50%;
    height: auto;
    width: 100%;
    min-height: 230px;
    min-width: 800px;
    transition: height 0.2s;
    -webkit-transition: height 0.2s;
    -moz-transition: height 0.2s;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%); }
