/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .Item {
  border-bottom: thin solid #f5f5f5;
  padding: 24px; }
  .Wrapper .Item .Label {
    display: flex;
    align-items: center; }
    .Wrapper .Item .Label label {
      font-size: 1.4rem;
      color: #202124;
      margin-left: 10px;
      margin-top: 5px;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
  .Wrapper .Item .Content {
    border: thin solid #f5f5f5;
    padding: 10px 11px;
    border-radius: 4px;
    margin-top: 16px;
    font-size: 1.4rem;
    color: #202124;
    position: relative;
    cursor: pointer;
    color: #818589; }
    .Wrapper .Item .Content .Input {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .Wrapper .Item .Content .Input .Icon {
        position: absolute;
        right: 6px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out; }
        .Wrapper .Item .Content .Input .Icon.IsActive {
          transform: rotate(-180deg);
          -webkit-transform: rotate(-180deg);
          -moz-transform: rotate(-180deg);
          -ms-transform: rotate(-180deg);
          -o-transform: rotate(-180deg); }
    .Wrapper .Item .Content .Ticket {
      position: absolute;
      bottom: -100;
      width: 100%;
      max-height: 250px;
      background: #FFFFFF;
      margin-left: -12px;
      margin-top: 23px;
      border-radius: 4px;
      border: thin solid #E6E6E6;
      overflow-y: scroll;
      color: #202124;
      z-index: 500; }
      .Wrapper .Item .Content .Ticket .Item {
        padding: 12px;
        border-bottom: thin solid #f5f5f5;
        cursor: pointer; }
        .Wrapper .Item .Content .Ticket .Item .Body {
          display: flex;
          justify-content: space-between;
          align-items: flex-start; }
          .Wrapper .Item .Content .Ticket .Item .Body .Title {
            width: 210px;
            font-size: 1.4rem;
            line-height: 1.8rem; }
        .Wrapper .Item .Content .Ticket .Item .Footer {
          display: flex;
          justify-content: space-between;
          margin-top: 12px; }
          .Wrapper .Item .Content .Ticket .Item .Footer label {
            font-size: 1.2rem;
            color: #818589; }
          .Wrapper .Item .Content .Ticket .Item .Footer .Price {
            font-size: 1.6rem;
            color: #CD2033;
            font-family: "Geomanist-Medium", sans-serif;
            font-weight: 500; }
    .Wrapper .Item .Content #StyleScroll::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      background-color: #FFFFFF; }
    .Wrapper .Item .Content #StyleScroll::-webkit-scrollbar {
      width: 3px;
      background-color: #FFFFFF; }
    .Wrapper .Item .Content #StyleScroll::-webkit-scrollbar-thumb {
      background-color: #c2c2c2; }
  .Wrapper .Item .Selected {
    border: thin solid #f5f5f5;
    margin-top: 20px;
    border-radius: 4px;
    position: relative; }
    .Wrapper .Item .Selected .Item {
      padding: 12px;
      border-bottom: thin solid #f5f5f5;
      cursor: pointer; }
      .Wrapper .Item .Selected .Item .Body {
        display: flex;
        justify-content: space-between;
        align-items: flex-start; }
        .Wrapper .Item .Selected .Item .Body .Title {
          width: 210px;
          font-size: 1.4rem;
          line-height: 1.8rem;
          color: #202124; }
        .Wrapper .Item .Selected .Item .Body .Icon {
          position: absolute;
          right: 6px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out; }
          .Wrapper .Item .Selected .Item .Body .Icon.IsActive {
            transform: rotate(-180deg);
            -webkit-transform: rotate(-180deg);
            -moz-transform: rotate(-180deg);
            -ms-transform: rotate(-180deg);
            -o-transform: rotate(-180deg); }
      .Wrapper .Item .Selected .Item .Footer {
        display: flex;
        justify-content: space-between;
        margin-top: 12px; }
        .Wrapper .Item .Selected .Item .Footer label {
          font-size: 1.2rem;
          color: #818589; }
        .Wrapper .Item .Selected .Item .Footer .Price {
          font-size: 1.6rem;
          color: #CD2033;
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500; }
    .Wrapper .Item .Selected .Ticket {
      position: absolute;
      bottom: -100;
      width: 100%;
      max-height: 250px;
      background: #FFFFFF;
      margin-top: 13px;
      border-radius: 4px;
      border: thin solid #E6E6E6;
      overflow-y: scroll;
      color: #202124;
      z-index: 500; }
      .Wrapper .Item .Selected .Ticket .Item {
        padding: 12px;
        border-bottom: thin solid #f5f5f5;
        cursor: pointer; }
        .Wrapper .Item .Selected .Ticket .Item .Body {
          display: flex;
          justify-content: space-between;
          align-items: flex-start; }
          .Wrapper .Item .Selected .Ticket .Item .Body .Title {
            width: 210px;
            font-size: 1.4rem;
            line-height: 1.8rem; }
        .Wrapper .Item .Selected .Ticket .Item .Footer {
          display: flex;
          justify-content: space-between;
          margin-top: 12px; }
          .Wrapper .Item .Selected .Ticket .Item .Footer label {
            font-size: 1.2rem;
            color: #818589; }
          .Wrapper .Item .Selected .Ticket .Item .Footer .Price {
            font-size: 1.6rem;
            color: #CD2033;
            font-family: "Geomanist-Medium", sans-serif;
            font-weight: 500; }
    .Wrapper .Item .Selected #StyleScroll::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      background-color: #FFFFFF; }
    .Wrapper .Item .Selected #StyleScroll::-webkit-scrollbar {
      width: 3px;
      background-color: #FFFFFF; }
    .Wrapper .Item .Selected #StyleScroll::-webkit-scrollbar-thumb {
      background-color: #c2c2c2; }

@keyframes openDropdown {
  0% {
    opacity: 0;
    transform: translateY(-30%);
    -webkit-transform: translateY(-30%);
    -moz-transform: translateY(-30%);
    -ms-transform: translateY(-30%);
    -o-transform: translateY(-30%); }
  30% {
    opacity: .3;
    transform: translateY(-5%);
    -webkit-transform: translateY(-5%);
    -moz-transform: translateY(-5%);
    -ms-transform: translateY(-5%);
    -o-transform: translateY(-5%); }
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0); } }

@keyframes closeDropdown {
  0% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0); }
  50% {
    opacity: 0;
    transform: translateY(-10%);
    -webkit-transform: translateY(-10%);
    -moz-transform: translateY(-10%);
    -ms-transform: translateY(-10%);
    -o-transform: translateY(-10%); }
  100% {
    opacity: 0;
    transform: translateY(-10%);
    -webkit-transform: translateY(-10%);
    -moz-transform: translateY(-10%);
    -ms-transform: translateY(-10%);
    -o-transform: translateY(-10%); } }

.Open {
  animation: openDropdown 0.3s ease-out;
  -webkit-animation: openDropdown 0.3s ease-out;
  -moz-animation: openDropdown 0.3s ease-out;
  -ms-transform: openDropdown 0.3s ease-out;
  -o-transform: openDropdown 0.3s ease-out; }

.Close {
  animation: closeDropdown 0.3s ease-out;
  -webkit-animation: closeDropdown 0.3s ease-out;
  -moz-animation: closeDropdown 0.3s ease-out;
  -ms-transform: closeDropdown 0.3s ease-out;
  -o-transform: closeDropdown 0.3s ease-out; }
