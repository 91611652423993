/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.PromoList {
  position: relative;
  width: 100%;
  margin-bottom: 16px;
  padding-bottom: 8px; }
  .PromoList .Item {
    padding: 10px 0;
    margin: 5px 10px;
    position: relative; }
    .PromoList .Item .ImageWrapper {
      height: 190px;
      width: 335px;
      overflow: hidden;
      position: relative;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      box-shadow: 0 4px 6px 2px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0 4px 6px 2px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0 4px 6px 2px rgba(0, 0, 0, 0.1);
      -ms-transform: 0 4px 6px 2px rgba(0, 0, 0, 0.1);
      -o-transform: 0 4px 6px 2px rgba(0, 0, 0, 0.1); }
      .PromoList .Item .ImageWrapper img {
        position: absolute;
        left: 50%;
        top: 50%;
        height: 100%;
        width: auto;
        transition: height 0.2s;
        -webkit-transition: height 0.2s;
        -moz-transition: height 0.2s;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%); }
  .PromoList .Item:focus {
    outline: none; }

.DotWrapper {
  position: absolute;
  bottom: -24px;
  z-index: 300; }

.Arrow {
  z-index: 1;
  position: absolute;
  top: 50%; }
  .Arrow.PrevArrow {
    cursor: pointer;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    display: block;
    left: 20px; }
    .Arrow.PrevArrow svg {
      margin-bottom: -75%; }
  .Arrow.NextArrow {
    cursor: pointer;
    display: block;
    right: 20px; }
    .Arrow.NextArrow svg {
      margin-top: -75%; }
