/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  position: absolute;
  background: #FFFFFF;
  width: 100%;
  overflow: hidden;
  z-index: 500;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.08);
  -ms-transform: 0 5px 25px 0 rgba(0, 0, 0, 0.08);
  -o-transform: 0 5px 25px 0 rgba(0, 0, 0, 0.08); }
  .Wrapper .List {
    max-height: 400px;
    margin: 0;
    padding: 11px 0;
    font-size: 1.4rem;
    color: #6A6E73;
    overflow: auto; }
    .Wrapper .List .Item {
      cursor: pointer;
      padding: 11px 20px;
      transition: background 0.3s;
      -webkit-transition: background 0.3s;
      -moz-transition: background 0.3s; }
    .Wrapper .List .Item:hover {
      background: #FAFAFA; }

@keyframes openAutocomplete {
  0% {
    opacity: 0;
    transform: translateY(-30%);
    -webkit-transform: translateY(-30%);
    -moz-transform: translateY(-30%);
    -ms-transform: translateY(-30%);
    -o-transform: translateY(-30%); }
  30% {
    opacity: .3;
    transform: translateY(-5%);
    -webkit-transform: translateY(-5%);
    -moz-transform: translateY(-5%);
    -ms-transform: translateY(-5%);
    -o-transform: translateY(-5%); }
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0); } }

@keyframes closeAutocomplete {
  0% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0); }
  50% {
    opacity: 0;
    transform: translateY(-10%);
    -webkit-transform: translateY(-10%);
    -moz-transform: translateY(-10%);
    -ms-transform: translateY(-10%);
    -o-transform: translateY(-10%); }
  100% {
    opacity: 0;
    transform: translateY(-10%);
    -webkit-transform: translateY(-10%);
    -moz-transform: translateY(-10%);
    -ms-transform: translateY(-10%);
    -o-transform: translateY(-10%); } }

.Open {
  animation: openAutocomplete 0.3s;
  -webkit-animation: openAutocomplete 0.3s;
  -moz-animation: openAutocomplete 0.3s;
  -ms-transform: openAutocomplete 0.3s;
  -o-transform: openAutocomplete 0.3s; }

.Close {
  animation: closeAutocomplete 0.3s;
  -webkit-animation: closeAutocomplete 0.3s;
  -moz-animation: closeAutocomplete 0.3s;
  -ms-transform: closeAutocomplete 0.3s;
  -o-transform: closeAutocomplete 0.3s; }
