/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.datepicker.default {
  background-color: #FFFFFF !important; }

.datepicker.default .datepicker-wheel {
  border-top: none !important;
  border-bottom: none !important;
  background: #f5f5f5 !important;
  font-family: "Geomanist-Regular", sans-serif; }

.datepicker .datepicker-col-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0 !important; }

.datepicker .datepicker-navbar {
  padding: 0 !important;
  overflow: hidden; }

.datepicker.default .datepicker-navbar-btn:first-child {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important; }

.datepicker.default .datepicker-navbar-btn:last-child {
  display: none; }

.datepicker.default .datepicker-scroll li {
  font-size: 1.8rem;
  color: #c2c2c2 !important;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500; }

.datepicker.default .datepicker-scroll li:nth-child(6) {
  color: #202124 !important;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500; }
