/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .Head {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500; }
  .Wrapper .Head .Title {
    font-size: 2rem;
    color: #202124; }
    .Wrapper .Head .Title .DownloadBtn {
      display: inline-block;
      padding: 0 1.4em;
      font-size: 1.3rem;
      text-align: center;
      border: none;
      color: #FFFFFF;
      height: 34px;
      display: flex;
      align-items: center;
      text-decoration: none;
      background: linear-gradient(to right, #CD2033, #e44631);
      background: -moz-linear-gradient(to right, #CD2033, #e44631);
      background: -webkit-linear-gradient(to right, #CD2033, #e44631);
      background: -o-linear-gradient(to right, #CD2033, #e44631);
      background: -ms-linear-gradient(to right, #CD2033, #e44631);
      cursor: pointer;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      transition: opacity 0.3s;
      -webkit-transition: opacity 0.3s;
      -moz-transition: opacity 0.3s; }
      .Wrapper .Head .Title .DownloadBtn:hover {
        opacity: .9; }
  .Wrapper .Head .BackBtn {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    color: #1A6FA3;
    cursor: pointer; }
    .Wrapper .Head .BackBtn span {
      margin-left: 12px; }
  .Wrapper .Head .BackBtn:hover {
    opacity: .8; }
