/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  display: flex;
  font-size: 1.2rem;
  background: #FAFAFA;
  overflow: hidden;
  cursor: pointer;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500; }
  .Wrapper .Btn {
    border-top-left-radius: 4px;
    -webkit-border-top-left-radius: 4px;
    -moz-border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-bottom-left-radius: 4px;
    border: 1px solid #E6E6E6;
    padding: 8px 20px;
    color: #c2c2c2;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s; }
  .Wrapper .Btn:last-child {
    border-top-left-radius: 0px;
    -webkit-border-top-left-radius: 0px;
    -moz-border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    -moz-border-bottom-left-radius: 0px;
    border-top-right-radius: 4px;
    -webkit-border-top-right-radius: 4px;
    -moz-border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    -webkit-border-bottom-right-radius: 4px;
    -moz-border-bottom-right-radius: 4px; }
  .Wrapper .Active {
    color: #FFFFFF;
    background: #CD2033;
    border-color: #CD2033; }
