/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Item {
  border-bottom: thin solid #f5f5f5;
  padding: 24px; }
  .Item .Label {
    display: flex;
    align-items: center; }
    .Item .Label label {
      font-size: 1.4rem;
      color: #202124;
      margin-left: 10px;
      margin-top: 5px;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
  .Item .Content {
    border: thin solid #f5f5f5;
    padding: 10px 11px;
    border-radius: 4px;
    margin-top: 16px;
    font-size: 1.4rem;
    color: #202124; }
    .Item .Content .Default {
      color: #818589; }
    .Item .Content .List {
      padding: 0px; }
      .Item .Content .List .Item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        padding: 0;
        border: none; }
        .Item .Content .List .Item .RoomType {
          font-size: 1.4rem;
          color: #202124;
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500; }
        .Item .Content .List .Item .Desc {
          font-size: 1rem;
          color: #818589;
          margin-bottom: 5px; }
        .Item .Content .List .Item .Action {
          display: flex;
          justify-content: space-between;
          align-items: center; }
          .Item .Content .List .Item .Action .Value {
            width: 20px;
            text-align: center;
            font-size: 2rem; }
  .Item .Price {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .Item .Price label {
      font-size: 1.6rem;
      color: #202124;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
    .Item .Price .PriceNumber {
      font-size: 2.4rem;
      color: #CD2033;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
  .Item .ItemBtn {
    margin-top: 24px; }
