/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  width: 304px; }
  .Wrapper .Icon {
    top: 0;
    position: absolute;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: linear-gradient(270deg, #E44631 0%, #CD2033 100%);
    display: flex;
    justify-content: center;
    align-items: center; }
  .Wrapper .Container {
    border: 1px solid #E6E6E6;
    min-height: 390px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px; }
    .Wrapper .Container .Description {
      padding: 60px 16px 16px; }
      .Wrapper .Container .Description .Title {
        line-height: 1.8em;
        margin-bottom: 8px;
        text-align: center;
        color: #202124;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500; }
      .Wrapper .Container .Description .Text {
        text-align: center;
        line-height: 1.8em;
        font-size: 1.4rem;
        color: #6A6E73; }
