/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #000000;
  opacity: 0.7;
  z-index: 660; }

.Open {
  animation: openDetail 0.3s;
  -webkit-animation: openDetail 0.3s;
  -moz-animation: openDetail 0.3s;
  -ms-transform: openDetail 0.3s;
  -o-transform: openDetail 0.3s; }

.Close {
  animation: closeDetail 0.3s;
  -webkit-animation: closeDetail 0.3s;
  -moz-animation: closeDetail 0.3s;
  -ms-transform: closeDetail 0.3s;
  -o-transform: closeDetail 0.3s; }

@keyframes openDetail {
  0% {
    opacity: 0; }
  100% {
    opacity: .7; } }

@keyframes closeDetail {
  0% {
    opacity: .7; }
  100% {
    opacity: 0; } }
