/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  bottom: -4px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .Wrapper .Image {
    height: 270px;
    overflow: hidden;
    position: relative; }
    .Wrapper .Image img {
      position: absolute;
      left: 50%;
      top: 50%;
      height: auto;
      min-height: 100%;
      width: 100%;
      transition: width 0.3s;
      -webkit-transition: width 0.3s;
      -moz-transition: width 0.3s;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%); }
  .Wrapper .Description {
    position: absolute;
    bottom: 0px;
    z-index: 1;
    padding: 16px 24px;
    width: 100%;
    background: rgba(32, 33, 36, 0.8);
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box; }
    .Wrapper .Description .Title {
      font-size: 1.4rem;
      color: #FFFFFF;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }

.Wrapper:hover .Image img {
  width: 105%; }
