/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  background: #202124;
  width: 100%;
  color: #FFFFFF;
  min-width: 1080px; }
  .Wrapper .Container {
    position: relative;
    display: block;
    padding-top: 80px;
    width: 960px;
    margin: 0 auto;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box; }
    .Wrapper .Container .NewsLetter {
      position: absolute;
      height: 80px;
      top: -40px;
      width: 100%;
      margin-bottom: 100px; }
  .Wrapper .Link {
    display: flex;
    margin-bottom: 30px; }
    .Wrapper .Link .LinkTitle {
      color: #FFFFFF;
      margin-bottom: 16px;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
    .Wrapper .Link .Label {
      font-size: 1.4rem;
      margin-bottom: 8px;
      color: #c2c2c2; }
    .Wrapper .Link .Logo {
      flex: .23; }
      .Wrapper .Link .Logo img {
        max-width: 180px;
        display: block; }
    .Wrapper .Link .Contact {
      flex: .4; }
      .Wrapper .Link .Contact .Phone {
        font-size: 2.4rem;
        color: #FFFFFF;
        margin-bottom: 16px;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500; }
    .Wrapper .Link .Item {
      flex: .18; }
      .Wrapper .Link .Item .List {
        margin: 0;
        padding: 0;
        font-size: 1.4rem;
        margin-top: 16px; }
        .Wrapper .Link .Item .List li {
          list-style: none;
          min-height: 24px;
          display: flex;
          align-items: center;
          margin-bottom: 8px; }
          .Wrapper .Link .Item .List li a {
            color: #c2c2c2;
            text-decoration: none;
            display: flex;
            align-items: center; }
            .Wrapper .Link .Item .List li a .Icon {
              margin-right: 8px; }
          .Wrapper .Link .Item .List li a:hover {
            color: #FFFFFF; }
  .Wrapper .CopyRight {
    border-top: 1px solid #6A6E73;
    color: #c2c2c2;
    text-align: center;
    padding: 30px 0;
    font-size: 1.2rem;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box; }
