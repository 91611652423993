/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  position: relative; }
  .Wrapper .Icon {
    position: absolute;
    right: 12px;
    top: 8px;
    cursor: pointer; }
  .Wrapper .TextField {
    width: 100%;
    border: 1px solid #E6E6E6;
    color: #6A6E73;
    padding: 12px 16px;
    font-size: 1.4rem;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    transition: border .3s, color .3s;
    -webkit-transition: border .3s, color .3s;
    -moz-transition: border .3s, color .3s;
    font-family: "Geomanist-Regular", sans-serif;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
    .Wrapper .TextField.HasError {
      border: 1px solid #e44631 !important;
      color: #e44631; }
    .Wrapper .TextField.HasError::placeholder {
      color: #e44631; }
    .Wrapper .TextField.TfSmall {
      padding: 9px 16px;
      font-size: 1.3rem; }
  .Wrapper .TextField:focus {
    outline: none;
    border: 1px solid #6A6E73; }
  .Wrapper .TextField::placeholder {
    color: #c2c2c2; }
  .Wrapper .ErrorMsg {
    color: #CD2033;
    font-size: 1.2rem;
    position: absolute;
    left: 0;
    line-height: 1.2em;
    height: 24px;
    bottom: -28px; }
  .Wrapper .TextField[type=number]::-webkit-inner-spin-button,
  .Wrapper .TextField[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .Wrapper .NoBorder {
    border: none !important; }

@keyframes open {
  0% {
    opacity: 0;
    transform: translateY(-100%); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes close {
  0% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(-100%); } }

.Open {
  animation: open 0.3s;
  -webkit-animation: open 0.3s;
  -moz-animation: open 0.3s;
  -ms-transform: open 0.3s;
  -o-transform: open 0.3s; }

.Close {
  animation: close 0.3s;
  -webkit-animation: close 0.3s;
  -moz-animation: close 0.3s;
  -ms-transform: close 0.3s;
  -o-transform: close 0.3s; }
