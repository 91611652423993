/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Background {
  background: #FAFAFA; }
  .Background .Head {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 500;
    text-align: center; }
    .Background .Head .Logo {
      margin-right: 44px; }
      .Background .Head .Logo img {
        width: 140px; }

.Content {
  padding: 80px 20px 20px;
  color: #202124; }
  .Content .Panel {
    margin-bottom: 24px; }
    .Content .Panel .Card {
      padding: 20px; }
  .Content .Title {
    font-size: 2rem;
    line-height: 1.2em;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    margin: 0px 0px; }
  .Content .SubTitle {
    color: #6A6E73; }
  .Content .Input {
    margin-bottom: 24px; }
    .Content .Input .Label {
      display: block;
      font-size: 1.4rem;
      margin-bottom: 8px;
      color: #202124; }
    .Content .Input .Link {
      font-size: 1.3rem;
      cursor: pointer;
      color: #CD2033; }
    .Content .Input .Inline {
      display: flex; }
      .Content .Input .Inline .DialCode {
        width: 110px;
        margin-right: 8px; }
      .Content .Input .Inline .Phone {
        flex: 1; }
    .Content .Input.PhoneWrapper {
      flex: 1.1;
      margin-bottom: 0px; }
  .Content .Sparator {
    margin: 32px 0;
    height: 1px;
    border-top: 1px solid #E6E6E6;
    display: flex;
    justify-content: center;
    align-items: center; }
    .Content .Sparator .Text {
      padding: 0 16px;
      position: relative;
      top: -1px;
      color: #6A6E73;
      font-size: 1.3rem;
      background: #FAFAFA; }
  .Content .SocmedLogin {
    display: flex;
    justify-content: space-between; }
    .Content .SocmedLogin .Socmed {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.3rem;
      padding: 10px 24px;
      overflow: hidden;
      cursor: pointer;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      flex: 1;
      -moz-flex: 1;
      -webkit-flex: 1;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px; }
      .Content .SocmedLogin .Socmed.FbBtn {
        color: #6A6E73;
        margin-right: 6px;
        border: 1px solid #c2c2c2; }
      .Content .SocmedLogin .Socmed.GoogleBtn {
        border: 1px solid #c2c2c2;
        color: #6A6E73;
        flex: 1;
        -moz-flex: 1;
        -webkit-flex: 1;
        margin-left: 6px; }
      .Content .SocmedLogin .Socmed .Icon {
        margin-right: 16px;
        width: 18px; }
  .Content .LabelHeader {
    display: flex;
    font-size: 1.3rem;
    color: #202124;
    margin-top: 32px;
    margin-bottom: 16px;
    justify-content: center; }
    .Content .LabelHeader .Link {
      margin-left: 4px;
      font-size: 1.3rem;
      cursor: pointer;
      color: #CD2033; }
  .Content .Bold {
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500; }
