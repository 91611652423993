/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Content {
  padding: 123px 20px 20px;
  position: relative;
  z-index: 1;
  background: #f5f5f5;
  display: flex;
  flex-direction: column; }
  .Content .Item {
    margin-bottom: 16px; }
  .Content .Place {
    display: flex;
    justify-content: center; }
    .Content .Place .Panel {
      display: flex;
      position: fixed;
      bottom: 20px;
      background: #FFFFFF;
      padding: 8px;
      width: fit-content;
      box-shadow: 0 4px 4px 1px rgba(0, 0, 0, 0.075);
      -webkit-box-shadow: 0 4px 4px 1px rgba(0, 0, 0, 0.075);
      -moz-box-shadow: 0 4px 4px 1px rgba(0, 0, 0, 0.075);
      -ms-transform: 0 4px 4px 1px rgba(0, 0, 0, 0.075);
      -o-transform: 0 4px 4px 1px rgba(0, 0, 0, 0.075);
      border-radius: 30px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px; }
      .Content .Place .Panel .PanelText {
        color: #202124;
        font-size: 1.3rem;
        margin-left: 8px;
        margin-top: 6px;
        margin-right: 6px; }
      .Content .Place .Panel .Arrow {
        transition: transform 0.3s;
        -webkit-transition: transform 0.3s;
        -moz-transition: transform 0.3s; }
        .Content .Place .Panel .Arrow.Active {
          transform: rotate(-180deg);
          -webkit-transform: rotate(-180deg);
          -moz-transform: rotate(-180deg);
          -ms-transform: rotate(-180deg);
          -o-transform: rotate(-180deg); }

.Hotel {
  background: #f5f5f5;
  padding: 10px;
  display: flex;
  margin-bottom: 16px;
  margin-top: 8px; }
  .Hotel .Image {
    width: 30%;
    height: fit-content;
    margin-right: 10px; }
    .Hotel .Image .Img {
      width: 100%;
      height: 55px;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px; }
  .Hotel .HotelDetail {
    color: #202124; }
    .Hotel .HotelDetail .HotelName {
      font-size: 1.4rem;
      line-height: 1.5em; }
    .Hotel .HotelDetail .StarSize {
      font-size: 1.9rem;
      height: 20px; }

.ImageSlider {
  width: 220px;
  height: 140px;
  margin: 0px 12px;
  border-radius: 4px; }
