/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  position: relative; }
  .Wrapper .Container {
    width: 100%;
    min-height: 300px;
    padding: 60px 0;
    width: 764px;
    margin: 0 auto;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px; }
    .Wrapper .Container .Title {
      font-size: 24px;
      color: #202124;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
    .Wrapper .Container .Description {
      font-family: "Geomanist-Regular", sans-serif;
      line-height: 26px; }
    .Wrapper .Container ul {
      margin: 0;
      padding: 0; }
      .Wrapper .Container ul li {
        list-style: none;
        box-sizing: border-box;
        display: flex; }
        .Wrapper .Container ul li.ItemLeft {
          justify-content: flex-start; }
        .Wrapper .Container ul li.ItemRight {
          justify-content: flex-end; }
        .Wrapper .Container ul li .Item {
          box-sizing: border-box;
          width: 50%;
          position: relative;
          padding-bottom: 32px;
          display: flex; }
          .Wrapper .Container ul li .Item.Left {
            border-right: 1px solid #c2c2c2; }
          .Wrapper .Container ul li .Item.Right {
            justify-content: flex-end;
            border-left: 1px solid #c2c2c2; }
          .Wrapper .Container ul li .Item .DotWrapper {
            position: absolute;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: rgba(205, 32, 51, 0.15);
            display: flex;
            justify-content: center;
            align-items: center; }
            .Wrapper .Container ul li .Item .DotWrapper.Left {
              top: -12px;
              right: -12px; }
            .Wrapper .Container ul li .Item .DotWrapper.Right {
              top: -12px;
              left: -12px; }
            .Wrapper .Container ul li .Item .DotWrapper .Dot {
              width: 16px;
              height: 16px;
              background: #CD2033;
              border-radius: 50%; }
          .Wrapper .Container ul li .Item .Contetn {
            width: 90%;
            border-radius: 8px;
            box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.08);
            padding: 24px;
            box-sizing: border-box; }
            .Wrapper .Container ul li .Item .Contetn.Right {
              text-align: right; }
            .Wrapper .Container ul li .Item .Contetn.Left {
              text-align: left; }
            .Wrapper .Container ul li .Item .Contetn .Year {
              font-size: 2rem;
              line-height: 2em;
              color: #CD2033;
              font-family: "Geomanist-Medium", sans-serif;
              font-weight: 500; }
            .Wrapper .Container ul li .Item .Contetn .Title {
              font-size: 1.6rem;
              line-height: 1.6em;
              font-family: "Geomanist-Medium", sans-serif;
              font-weight: 500; }
            .Wrapper .Container ul li .Item .Contetn .Journey {
              font-size: 1.4rem;
              line-height: 1.8em; }
      .Wrapper .Container ul li:last-child .Item.Left {
        border-right: none; }
      .Wrapper .Container ul li:last-child .Item.Right {
        border-left: none; }
