/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  position: relative;
  display: flex;
  align-items: center; }
  .Wrapper .Select {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%; }
    .Wrapper .Select .Input {
      flex: 1; }
      .Wrapper .Select .Input .Select {
        width: 100%;
        height: 41px;
        border: 1px solid #E6E6E6;
        color: #6A6E73;
        background: #FFFFFF;
        padding: 12px 24px 12px 16px;
        font-size: 1.4rem;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        transition: border .3s, color .3s;
        -webkit-transition: border .3s, color .3s;
        -moz-transition: border .3s, color .3s;
        font-family: "Geomanist-Regular", sans-serif; }
        .Wrapper .Select .Input .Select .Placeholder {
          color: #c2c2c2; }
        .Wrapper .Select .Input .Select.HasError {
          border: 1px solid #e44631 !important;
          color: #e44631; }
        .Wrapper .Select .Input .Select.HasError::placeholder {
          color: #e44631; }
        .Wrapper .Select .Input .Select.Focus {
          outline: none;
          border: 1px solid #6A6E73; }
        .Wrapper .Select .Input .Select .Value {
          display: flex;
          align-items: center; }
          .Wrapper .Select .Input .Select .Value .Label {
            margin-top: 2px;
            margin-left: 8px; }
      .Wrapper .Select .Input .Select::placeholder {
        color: #c2c2c2; }
    .Wrapper .Select .ErrorMsg {
      color: #CD2033;
      font-size: 1.2rem;
      position: absolute;
      left: 0;
      bottom: -16px; }
    .Wrapper .Select .Icon {
      position: absolute;
      right: 6px;
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out; }
      .Wrapper .Select .Icon.IsActive {
        transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        -o-transform: rotate(-180deg); }
  .Wrapper .ListWrapper {
    width: 100%;
    min-width: 240px;
    position: absolute;
    background: #FFFFFF;
    box-sizing: border-box;
    top: 108%;
    font-size: 1.3rem;
    color: #202124;
    z-index: 500;
    overflow: hidden;
    border: 1px solid rgba(230, 230, 230, 0.5);
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.05);
    -ms-transform: 0 1px 4px 1px rgba(0, 0, 0, 0.05);
    -o-transform: 0 1px 4px 1px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px; }
    .Wrapper .ListWrapper .Filter {
      padding: 16px;
      border-bottom: 1px solid #f0f0f0; }
