.img {
    width: 64px;
    height: 64px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    @include border-radius(border-radius, 8px);
    &.img-circle {
        @include border-radius(border-radius, 50%);
    }
    &.img-80 {
        width: 80px !important;
        height: 80px !important;
    }
    &.img-100 {
        width: 100px !important;
        height: 100px !important;
    }
    &.img-120 {
        width: 120px !important;
        height: 120px !important;
    }
    
    .bg {
        position: absolute;
        left: 50%;
        top: 50%;
        height: 100%;
        width: auto;
        @include transition(height .2s);
        @include transform(translate(-50%,-50%));
    }
}