/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 655;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: #FAFAFA; }
  .Wrapper .Header {
    width: 100%;
    background: #FFFFFF;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -webkit-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -moz-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -ms-transform: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -o-transform: 0px 1px 4px rgba(32, 33, 36, 0.05); }
  .Wrapper .Content {
    position: relative;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; }
  .Wrapper .Footer {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    position: sticky;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(32, 33, 36, 0.1);
    -webkit-box-shadow: 0 1px 8px rgba(32, 33, 36, 0.1);
    -moz-box-shadow: 0 1px 8px rgba(32, 33, 36, 0.1);
    -ms-transform: 0 1px 8px rgba(32, 33, 36, 0.1);
    -o-transform: 0 1px 8px rgba(32, 33, 36, 0.1); }

.Open {
  animation: openPopup 0.3s; }

.Closed {
  animation: closePopup 0.4s; }

@keyframes openPopup {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(0); } }

@keyframes closePopup {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(100%); } }
