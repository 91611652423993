/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  padding: 32px 24px;
  background: #FAFAFA;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  font-size: 1.4rem; }
  .Wrapper .Title {
    color: #202124;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500; }
  .Wrapper .List {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap; }
    .Wrapper .List .Item {
      width: 33.3%;
      margin-top: 16px;
      list-style: none; }
      .Wrapper .List .Item .Label {
        margin-bottom: 10px;
        color: #818589; }
      .Wrapper .List .Item .value {
        min-height: 24px;
        color: #202124;
        display: flex;
        align-items: center; }
        .Wrapper .List .Item .value.Bold {
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500; }
        .Wrapper .List .Item .value svg {
          margin-right: 6px; }
