/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  min-height: 100vh;
  background: #FAFAFA;
  min-width: 1080px; }
  .Wrapper .Container {
    padding: 40px 0 100px;
    width: 688px;
    margin: 0 auto; }
    .Wrapper .Container .Content {
      padding: 32px 24px;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box; }
      .Wrapper .Container .Content .Head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px; }
        .Wrapper .Container .Content .Head .Title {
          font-size: 2rem;
          color: #202124;
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500; }
        .Wrapper .Container .Content .Head .DownloadBtn {
          display: inline-block;
          padding: 0 1.4em;
          font-size: 1.3rem;
          text-align: center;
          border: none;
          color: #FFFFFF;
          height: 34px;
          display: flex;
          align-items: center;
          text-decoration: none;
          background: linear-gradient(to right, #CD2033, #e44631);
          background: -moz-linear-gradient(to right, #CD2033, #e44631);
          background: -webkit-linear-gradient(to right, #CD2033, #e44631);
          background: -o-linear-gradient(to right, #CD2033, #e44631);
          background: -ms-linear-gradient(to right, #CD2033, #e44631);
          cursor: pointer;
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          transition: opacity 0.3s;
          -webkit-transition: opacity 0.3s;
          -moz-transition: opacity 0.3s; }
          .Wrapper .Container .Content .Head .DownloadBtn:hover {
            opacity: .9; }
