/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .HeadTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.Wrapper .Nav {
  top: 0;
  left: 0;
  width: 100%;
  position: fixed;
  z-index: 2;
  background: linear-gradient(rgba(32, 33, 36, 0.5), rgba(32, 33, 36, 0));
  background: -moz-linear-gradient(rgba(32, 33, 36, 0.5), rgba(32, 33, 36, 0));
  background: -webkit-linear-gradient(rgba(32, 33, 36, 0.5), rgba(32, 33, 36, 0));
  background: -o-linear-gradient(rgba(32, 33, 36, 0.5), rgba(32, 33, 36, 0));
  background: -ms-linear-gradient(rgba(32, 33, 36, 0.5), rgba(32, 33, 36, 0));
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .Wrapper .Nav.SecondNav {
    opacity: 0;
    background: #FFFFFF;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s; }
  .Wrapper .Nav.Show {
    opacity: 1; }

.Wrapper .Content {
  position: relative;
  padding-bottom: 100px;
  background: #FFFFFF;
  z-index: 1;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .Wrapper .Content .Title {
    font-size: 2rem;
    color: #202124;
    line-height: 1.2em;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    margin-bottom: 12px; }
  .Wrapper .Content .ItineraryTerms {
    padding: 0;
    margin: 0; }
    .Wrapper .Content .ItineraryTerms .Item {
      list-style: none;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #E6E6E6;
      padding: 16px 0;
      color: #202124;
      font-size: 1.6rem;
      cursor: pointer;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
  .Wrapper .Content .Includes {
    display: flex; }
    .Wrapper .Content .Includes .Item {
      margin-right: 8px; }

.Wrapper .FooterWrapper {
  padding: 16px;
  width: 100%;
  background: #FFFFFF;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  z-index: 2;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(32, 33, 36, 0.1);
  -webkit-box-shadow: 0 1px 8px rgba(32, 33, 36, 0.1);
  -moz-box-shadow: 0 1px 8px rgba(32, 33, 36, 0.1);
  -ms-transform: 0 1px 8px rgba(32, 33, 36, 0.1);
  -o-transform: 0 1px 8px rgba(32, 33, 36, 0.1); }
  .Wrapper .FooterWrapper .PriceWrapper .Label {
    font-size: 1.2rem; }
  .Wrapper .FooterWrapper .PriceWrapper .Price {
    margin-top: 6px;
    font-size: 1.6rem;
    color: #CD2033;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500; }
    .Wrapper .FooterWrapper .PriceWrapper .Price .Perpax {
      color: #6A6E73;
      font-size: 1.2rem;
      font-family: "Geomanist-Regular", sans-serif; }
