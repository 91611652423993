/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  margin: 0;
  padding: 0; }
  .Wrapper .Item {
    list-style: none;
    margin-bottom: 12px;
    padding-left: 16px;
    border-left: 3px solid #CD2033; }
    .Wrapper .Item .Name {
      color: #202124;
      font-size: 1.4rem;
      margin-bottom: 2px; }
    .Wrapper .Item .Date {
      color: #6A6E73;
      font-size: 1.2rem; }
