/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  background: #FFFFFF; }
  .Wrapper .Item {
    list-style: none;
    display: flex;
    align-items: center; }
    .Wrapper .Item .Number {
      font-size: 1.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFFFFF;
      background: #6A6E73;
      width: 20px;
      height: 20px;
      padding-top: 2px;
      margin-right: 8px;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%; }
      .Wrapper .Item .Number.Active {
        background: #1A6FA3; }
      .Wrapper .Item .Number.Done {
        background: #FFFFFF; }
    .Wrapper .Item .Text {
      margin-top: 2px; }
  .Wrapper .Item:not(:last-child)::after {
    background-image: url("../../../assets/images/ico-arrow-right.png");
    background-repeat: no-repeat;
    background-size: 16px 14px;
    width: 16px;
    height: 14px;
    margin: 0 24px;
    display: inline-flex;
    content: "";
    opacity: .7; }
  .Wrapper .ItemMobile {
    list-style: none;
    display: flex;
    padding: 10px 5px;
    align-items: center; }
    .Wrapper .ItemMobile .Icon {
      display: flex; }
    .Wrapper .ItemMobile .Text {
      margin-left: 6px;
      margin-top: 2px;
      font-size: 1.2rem;
      color: #202124;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
    .Wrapper .ItemMobile.Inactive::before {
      content: "";
      width: 16px;
      height: 2px;
      margin-right: 10px;
      background: #c2c2c2; }
    .Wrapper .ItemMobile.Inactive .Text {
      color: #c2c2c2; }
    .Wrapper .ItemMobile::before {
      content: "";
      width: 16px;
      height: 2px;
      margin-right: 10px;
      background: #202124; }
      .Wrapper .ItemMobile::before.Inactive {
        background: #c2c2c2; }
    .Wrapper .ItemMobile:first-child::before {
      content: "";
      display: table;
      width: 0;
      height: 0; }
