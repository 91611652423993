/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Tx {
  text-align: left;
  font-size: 1.6rem;
  font-weight: normal;
  color: #202124;
  line-height: 1.4em; }

.TxLeft {
  text-align: left !important; }

.TxCenter {
  text-align: center !important; }

.TxRight {
  text-align: right !important; }

.TxJustify {
  text-align: justify !important; }

.Tx10 {
  font-size: 1rem !important;
  line-height: 1.3em !important; }

.Tx11 {
  font-size: 1.1rem !important;
  line-height: 1.4em !important; }

.Tx12 {
  font-size: 1.2rem !important;
  line-height: 1.5em !important; }

.Tx13 {
  font-size: 1.3rem !important;
  line-height: 1.6em !important; }

.Tx14 {
  font-size: 1.4rem !important;
  line-height: 1.7em !important; }

.Tx15 {
  font-size: 1.5rem !important;
  line-height: 1.8em !important; }

.Tx16 {
  font-size: 1.6rem !important;
  line-height: 1.9em !important; }

.Tx17 {
  font-size: 1.7rem !important;
  line-height: 2em !important; }

.Tx18 {
  font-size: 1.8rem !important;
  line-height: 2.1em !important; }

.Tx19 {
  font-size: 1.9rem !important;
  line-height: 2.2em !important; }

.Tx20 {
  font-size: 2rem !important;
  line-height: 2.3em !important; }

.Tx21 {
  font-size: 2.1rem !important;
  line-height: 2.4em !important; }

.Tx22 {
  font-size: 2.2rem !important;
  line-height: 2.5em !important; }

.Tx23 {
  font-size: 2.3rem !important;
  line-height: 2.6em !important; }

.Tx24 {
  font-size: 2.4rem !important;
  line-height: 2.7em !important; }

.Tx25 {
  font-size: 2.5rem !important;
  line-height: 2.8em !important; }

.Tx26 {
  font-size: 2.6rem !important;
  line-height: 2.9em !important; }

.Tx27 {
  font-size: 2.7rem !important;
  line-height: 3em !important; }

.Tx28 {
  font-size: 2.8rem !important;
  line-height: 3.1em !important; }

.Tx29 {
  font-size: 2.9rem !important;
  line-height: 3.2em !important; }

.Tx30 {
  font-size: 3rem !important;
  line-height: 3.3em !important; }

.Tx32 {
  font-size: 3.2rem !important;
  line-height: 3.5em !important; }

.Tx36 {
  font-size: 3.6rem !important;
  line-height: 3.9em !important; }

.Tx40 {
  font-size: 4rem !important;
  line-height: 4.3em !important; }

.Tx50 {
  font-size: 5rem !important;
  line-height: 5.3em !important; }

.TxReguler {
  font-weight: normal !important;
  font-family: Geomanist !important; }

.TxBold {
  font-weight: 500 !important;
  font-family: Geomanist-Medium !important; }

.TxBlack {
  color: #202124 !important; }

.TxGrey {
  color: #6A6E73 !important; }

.TxGreyLight {
  color: #c2c2c2 !important; }

.TxWhite {
  color: #FFFFFF !important; }

.TxRed {
  color: #CD2033 !important; }

.TxGreen {
  color: #73C700 !important; }
