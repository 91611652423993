/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  padding: 12px 18px; }
  .Wrapper .ItemWrap {
    padding-right: 10px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box; }
    .Wrapper .ItemWrap:focus {
      outline: none; }
    .Wrapper .ItemWrap .Item {
      border: 1px solid #E6E6E6;
      list-style: none;
      padding: 12px 20px;
      font-size: 1.4rem;
      color: #202124;
      cursor: pointer;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box; }
      .Wrapper .ItemWrap .Item.Active, .Wrapper .ItemWrap .Item:hover {
        color: #FFFFFF;
        border: 1px solid rgba(228, 70, 49, 0.8);
        background: linear-gradient(to right, #CD2033, #e44631);
        background: -moz-linear-gradient(to right, #CD2033, #e44631);
        background: -webkit-linear-gradient(to right, #CD2033, #e44631);
        background: -o-linear-gradient(to right, #CD2033, #e44631);
        background: -ms-linear-gradient(to right, #CD2033, #e44631); }

.DotWrapper {
  position: absolute;
  z-index: 300; }

.Arrow {
  z-index: 1;
  position: absolute;
  top: 0;
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%; }
  .Arrow.PrevArrow {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg); }
    .Arrow.PrevArrow svg g path {
      fill: #6A6E73; }
  .Arrow.NextArrow {
    right: 0px;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg); }
    .Arrow.NextArrow svg g path {
      fill: #6A6E73; }
  .Arrow:hover {
    background: #f5f5f5; }
    .Arrow:hover svg g path {
      fill: #202124; }
