/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .Container {
  width: 960px;
  margin: 0 auto;
  width: 984px; }
  .Wrapper .Container .Title {
    font-size: 3.2rem;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    text-align: center;
    margin-bottom: 16px;
    color: #202124; }
  .Wrapper .Container .SubTitle {
    text-align: center;
    margin-bottom: 48px; }
  .Wrapper .Container .List {
    width: 100%; }
    .Wrapper .Container .List .Clear {
      clear: both; }
    .Wrapper .Container .List .ItemWrapper {
      position: relative;
      width: 100%;
      display: block; }
      .Wrapper .Container .List .ItemWrapper .Item {
        position: relative;
        width: 304px !important;
        margin: 12px; }
        .Wrapper .Container .List .ItemWrapper .Item .ItemBox {
          width: 100%;
          box-sizing: border-box;
          padding: 10px;
          position: relative; }
          .Wrapper .Container .List .ItemWrapper .Item .ItemBox:hover .Image img {
            height: 120%; }
          .Wrapper .Container .List .ItemWrapper .Item .ItemBox .Image {
            width: 285px;
            height: 208px;
            border-radius: 12px;
            margin: 0 auto;
            overflow: hidden;
            position: relative; }
            .Wrapper .Container .List .ItemWrapper .Item .ItemBox .Image img {
              overflow: hidden;
              position: absolute;
              top: 50%;
              left: 50%;
              min-width: auto;
              height: 110%;
              transition: height 0.2s;
              -webkit-transition: height 0.2s;
              -moz-transition: height 0.2s;
              transform: translate(-50%, -50%);
              -webkit-transform: translate(-50%, -50%);
              -moz-transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
              -o-transform: translate(-50%, -50%); }
          .Wrapper .Container .List .ItemWrapper .Item .ItemBox .Body {
            padding: 8px; }
            .Wrapper .Container .List .ItemWrapper .Item .ItemBox .Body .Title {
              font-size: 1.6rem;
              color: #202124;
              text-align: left;
              margin-top: 16px; }
            .Wrapper .Container .List .ItemWrapper .Item .ItemBox .Body .Location {
              font-size: 1.2rem;
              color: #202124;
              display: flex;
              align-items: center;
              flex-wrap: wrap; }
              .Wrapper .Container .List .ItemWrapper .Item .ItemBox .Body .Location .Icon {
                margin-top: -3px;
                padding-right: 4px; }
          .Wrapper .Container .List .ItemWrapper .Item .ItemBox .Footer {
            padding: 10px;
            display: flex;
            align-items: flex-end; }
            .Wrapper .Container .List .ItemWrapper .Item .ItemBox .Footer .ItemPrice {
              flex: 1; }
              .Wrapper .Container .List .ItemWrapper .Item .ItemBox .Footer .ItemPrice .Label {
                font-size: 1.2rem;
                color: #6A6E73;
                margin-bottom: 5px; }
              .Wrapper .Container .List .ItemWrapper .Item .ItemBox .Footer .ItemPrice .Price {
                font-size: 2rem;
                color: #CD2033;
                font-family: "Geomanist-Medium", sans-serif;
                font-weight: 500; }
            .Wrapper .Container .List .ItemWrapper .Item .ItemBox .Footer .ItemBtn {
              flex: 1; }

.DotWrapper {
  position: absolute;
  bottom: -32px;
  z-index: 300; }

.Arrow {
  z-index: 1;
  position: absolute;
  top: 50%;
  cursor: pointer; }
  .Arrow.PrevArrow {
    left: -62px;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg); }
    .Arrow.PrevArrow svg {
      margin-top: -75%; }
  .Arrow.NextArrow {
    right: -62px;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg); }
    .Arrow.NextArrow svg {
      margin-top: -75%; }
