/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Left {
  width: 338px;
  padding: 100px 24px;
  color: #FFFFFF;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 8px;
  -webkit-border-top-left-radius: 8px;
  -moz-border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  -webkit-border-bottom-left-radius: 8px;
  -moz-border-bottom-left-radius: 8px;
  background: linear-gradient(135deg, rgba(26, 111, 163, 0.9) 0%, #1A6FA3 100%);
  background: -moz-linear-gradient(135deg, rgba(26, 111, 163, 0.9) 0%, #1A6FA3 100%);
  background: -webkit-linear-gradient(135deg, rgba(26, 111, 163, 0.9) 0%, #1A6FA3 100%);
  background: -o-linear-gradient(135deg, rgba(26, 111, 163, 0.9) 0%, #1A6FA3 100%);
  background: -ms-linear-gradient(135deg, rgba(26, 111, 163, 0.9) 0%, #1A6FA3 100%);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .Left .Benefit {
    position: relative;
    z-index: 2; }
    .Left .Benefit .BenefitTitle {
      font-size: 1.6rem;
      margin-bottom: 12px;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
    .Left .Benefit .BenefitList {
      padding: 0;
      margin: 0; }
      .Left .Benefit .BenefitList .Item {
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        padding: 20px 0; }
        .Left .Benefit .BenefitList .Item .Icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          background: #FFFFFF;
          margin-right: 24px;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%; }
        .Left .Benefit .BenefitList .Item .Text {
          line-height: 1.8em;
          flex: 1;
          -moz-flex: 1;
          -webkit-flex: 1; }
  .Left .BgLogin {
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; }
