/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  font-size: 1.4rem;
  line-height: 24px; }
  .Wrapper .Logo {
    width: 150px;
    margin-bottom: 60px; }
    .Wrapper .Logo img {
      display: block;
      width: 100%; }
  .Wrapper .JobTitle {
    font-size: 2.2rem;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    margin-bottom: 12px; }
  .Wrapper .JobLocation {
    margin-bottom: 60px; }
  .Wrapper .SubTitle {
    font-size: 1.6rem;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500; }
