/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .Header {
  position: fixed;
  top: 0;
  left: 0;
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  color: #202124;
  z-index: 2;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .Wrapper .Header .Icon {
    cursor: pointer;
    margin-right: 8px; }
  .Wrapper .Header .Title {
    margin-top: 4px; }
  .Wrapper .Header.SecondNav {
    opacity: 0;
    background: #FFFFFF;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -webkit-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -moz-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -ms-transform: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -o-transform: 0px 1px 4px rgba(32, 33, 36, 0.05); }
  .Wrapper .Header.Show {
    opacity: 1; }

.Wrapper .Content {
  position: relative;
  z-index: 1;
  background: #FFFFFF; }
  .Wrapper .Content .Title {
    text-align: center;
    font-size: 2rem;
    color: #202124;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500; }
  .Wrapper .Content .SubTitle {
    font-size: 1.6rem;
    line-height: 1.6em;
    color: #202124;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500; }
  .Wrapper .Content .TextContent {
    z-index: 2;
    background: #FFFFFF;
    position: relative;
    padding: 16px;
    margin: -50px 16px 0;
    border-top-left-radius: 16px;
    -webkit-border-top-left-radius: 16px;
    -moz-border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    -webkit-border-top-right-radius: 16px;
    -moz-border-top-right-radius: 16px; }
