/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  background: #FFFFFF;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-bottom-left-radius: 8px;
  -webkit-border-bottom-left-radius: 8px;
  -moz-border-bottom-left-radius: 8px;
  border-top-right-radius: 8px;
  -webkit-border-top-right-radius: 8px;
  -moz-border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  -webkit-border-bottom-right-radius: 8px;
  -moz-border-bottom-right-radius: 8px;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.08);
  -ms-transform: 0 5px 25px 0 rgba(0, 0, 0, 0.08);
  -o-transform: 0 5px 25px 0 rgba(0, 0, 0, 0.08); }
  .Wrapper.TopLeftRadius {
    border-top-left-radius: 8px;
    -webkit-border-top-left-radius: 8px;
    -moz-border-top-left-radius: 8px; }
  .Wrapper .Form {
    padding: 20px 0;
    display: flex;
    flex: 1;
    -moz-flex: 1;
    -webkit-flex: 1; }
    .Wrapper .Form.Small {
      padding: 12px 0 !important; }
    .Wrapper .Form .InputWrapper {
      border-right: 1px solid #E6E6E6;
      flex: 1;
      -moz-flex: 1;
      -webkit-flex: 1; }
      .Wrapper .Form .InputWrapper .Label {
        text-transform: uppercase;
        font-size: 1.2rem;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        color: #202124;
        margin-bottom: 8px; }
      .Wrapper .Form .InputWrapper .Input {
        display: flex;
        justify-content: space-between; }
        .Wrapper .Form .InputWrapper .Input input {
          width: 100%;
          border: none;
          font-size: 1.4rem;
          outline: none;
          font-family: "Geomanist-Regular", sans-serif; }
        .Wrapper .Form .InputWrapper .Input input:focus {
          border-bottom: 1px solid #CD2033; }
        .Wrapper .Form .InputWrapper .Input .Icon {
          transition: transform 0.3s;
          -webkit-transition: transform 0.3s;
          -moz-transition: transform 0.3s; }
          .Wrapper .Form .InputWrapper .Input .Icon .Active {
            transform: rotate(180deg); }
    .Wrapper .Form .InputWrapper:last-child {
      border-right: none;
      margin-right: 0px;
      padding-right: 0px; }
  .Wrapper .Action {
    width: 205px;
    background: red;
    border-top-right-radius: 8px;
    -webkit-border-top-right-radius: 8px;
    -moz-border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    -webkit-border-bottom-right-radius: 8px;
    -moz-border-bottom-right-radius: 8px;
    overflow: hidden;
    display: flex; }
    .Wrapper .Action .Button {
      flex: 1;
      align-items: stretch;
      border: none;
      font-size: 1.8rem;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      color: #FFFFFF;
      outline: none !important;
      cursor: pointer;
      background: linear-gradient(to right, #CD2033, #e44631);
      background: -moz-linear-gradient(to right, #CD2033, #e44631);
      background: -webkit-linear-gradient(to right, #CD2033, #e44631);
      background: -o-linear-gradient(to right, #CD2033, #e44631);
      background: -ms-linear-gradient(to right, #CD2033, #e44631); }
    .Wrapper .Action .Button:active {
      outline: none; }
