/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .Wrapper .Header {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between; }
    .Wrapper .Header .Title {
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      color: #202124; }
    .Wrapper .Header .Download {
      cursor: pointer;
      border: 1px solid #CD2033;
      color: #CD2033;
      text-decoration: none;
      font-size: 1.1rem;
      padding: 6px 12px 4px;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      transition: background 0.3s;
      -webkit-transition: background 0.3s;
      -moz-transition: background 0.3s;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px; }
    .Wrapper .Header .Download:hover {
      background: #CD2033;
      color: #FFFFFF; }
  .Wrapper .List {
    padding: 0;
    margin: 0; }
    .Wrapper .List li {
      list-style: none;
      display: flex; }
      .Wrapper .List li .Left {
        position: relative;
        flex: .18;
        border-right: 1px solid #f0f0f0;
        margin-right: 16px; }
        .Wrapper .List li .Left .Day {
          padding: 6px 16px;
          background: #1A6FA3;
          color: #FFFFFF;
          font-size: 1.2rem;
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px; }
        .Wrapper .List li .Left .Dot {
          position: absolute;
          right: -5px;
          top: 0;
          width: 9px;
          height: 9px;
          background: #1A6FA3;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%; }
      .Wrapper .List li .Right {
        min-height: 100px;
        flex: 1; }
