/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  display: flex;
  padding: 16px;
  border-bottom: 1px solid #FAFAFA; }
  .Wrapper .ImageWrapper {
    flex-shrink: 0;
    flex-basis: 69px;
    width: 69px;
    height: 69px;
    overflow: hidden;
    position: relative;
    margin-right: 16px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px; }
    .Wrapper .ImageWrapper .Img {
      position: absolute;
      left: 50%;
      top: 50%;
      height: 100%;
      width: auto;
      transition: height 0.2s;
      -webkit-transition: height 0.2s;
      -moz-transition: height 0.2s;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%); }
  .Wrapper .Description {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .Wrapper .Description .Title {
      font-size: 1.4rem;
      line-height: 1.4em;
      color: #202124; }
    .Wrapper .Description .PriceWrapper {
      display: flex;
      align-items: center;
      margin-bottom: 8px; }
      .Wrapper .Description .PriceWrapper .StartFrom {
        font-size: 1.1rem;
        margin-right: 8px;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500; }
      .Wrapper .Description .PriceWrapper .Price {
        font-size: 1.5rem;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        color: #CD2033; }
      .Wrapper .Description .PriceWrapper .Perpax {
        color: #CD2033;
        font-size: 1.1rem; }
