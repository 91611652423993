/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  color: #202124;
  font-size: 1.6rem;
  min-height: 60px;
  padding: 0 20px;
  text-align: left;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500; }
  .Wrapper.Shadow {
    box-shadow: 0px 2px 6px rgba(32, 33, 36, 0.1);
    -webkit-box-shadow: 0px 2px 6px rgba(32, 33, 36, 0.1);
    -moz-box-shadow: 0px 2px 6px rgba(32, 33, 36, 0.1);
    -ms-transform: 0px 2px 6px rgba(32, 33, 36, 0.1);
    -o-transform: 0px 2px 6px rgba(32, 33, 36, 0.1); }
  .Wrapper .Left {
    margin-right: 20px; }
  .Wrapper .Content {
    flex: 1; }
  .Wrapper .Right {
    margin-left: 20px; }

.BgWhite {
  background: #FFFFFF !important; }

.BgTransparent {
  background: transparent !important; }

.BgRed {
  background: #CD2033 !important; }

.ClWhite {
  color: #FFFFFF !important; }

.ClBlack {
  color: #202124 !important; }

.TxLeft {
  text-align: left !important; }

.TxCenter {
  text-align: center !important; }

.TxRight {
  text-align: right !important; }
