/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/

$c-black        : #202124;
$c-black-light  : #818589;

$c-grey         : #6A6E73;
$c-grey-light   : #c2c2c2;

$c-white        : #FFFFFF;
$c-white-dark   : #FAFAFA;
$c-white-dark-1 : #f5f5f5;
$c-white-dark-2 : #E6E6E6;
$c-white-dark-3 : #f0f0f0;

$c-red          : #CD2033;
$c-red-light    : #e44631;

$c-yellow       : #F99E00;
$c-yellow-light : #fdeed3;

$c-green        : #73C700;
$c-green-light  : #25d366;

$c-blue         : #1A6FA3;
$c-blue-dark    : #4267B2;

$c-cyan         : #2CADB5;
$c-cyan-dark    : #1E888E;