/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .Header {
  background: #1A6FA3;
  padding: 24px;
  color: #FFFFFF;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500;
  text-align: center; }

.Wrapper .SectionList {
  margin: 0;
  padding: 0; }
  .Wrapper .SectionList .Item {
    padding: 16px 24px;
    list-style: none;
    border-bottom: 1px solid #f0f0f0; }
    .Wrapper .SectionList .Item .Wrapper {
      display: flex;
      justify-content: space-between; }
      .Wrapper .SectionList .Item .Wrapper .Info {
        flex: 1;
        margin-right: 16px; }
        .Wrapper .SectionList .Item .Wrapper .Info .Title {
          color: #202124;
          margin-top: 24px;
          line-height: 1.6em;
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500; }
        .Wrapper .SectionList .Item .Wrapper .Info .Desc {
          font-size: 1.4rem; }
    .Wrapper .SectionList .Item .Body .Date {
      margin-bottom: 16px;
      font-size: 1.4rem; }
      .Wrapper .SectionList .Item .Body .Date .Label {
        margin: 8px 0px;
        color: #6A6E73; }
      .Wrapper .SectionList .Item .Body .Date .Detail {
        color: #202124;
        display: flex;
        align-items: center;
        flex-wrap: wrap; }
        .Wrapper .SectionList .Item .Body .Date .Detail .Icon {
          margin-top: -3px;
          padding-right: 4px; }
    .Wrapper .SectionList .Item .PriceWrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0px;
      font-size: 1.6rem;
      color: #202124;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
      .Wrapper .SectionList .Item .PriceWrap .Price {
        color: #CD2033;
        font-size: 2.4rem; }
      .Wrapper .SectionList .Item .PriceWrap .Pax {
        font-size: 1.4rem;
        color: #6A6E73;
        font-family: "Geomanist-Regular", sans-serif; }
  .Wrapper .SectionList .Item:last-child {
    border-bottom: none; }
