/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  width: 220px;
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  box-shadow: 0px 2px 8px 2px rgba(106, 110, 115, 0.1);
  -webkit-box-shadow: 0px 2px 8px 2px rgba(106, 110, 115, 0.1);
  -moz-box-shadow: 0px 2px 8px 2px rgba(106, 110, 115, 0.1);
  -ms-transform: 0px 2px 8px 2px rgba(106, 110, 115, 0.1);
  -o-transform: 0px 2px 8px 2px rgba(106, 110, 115, 0.1); }
  .Wrapper .Panel {
    padding: 6px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    position: relative; }
    .Wrapper .Panel .Ribbon {
      width: 100%;
      position: absolute;
      top: 16px;
      left: -4px;
      z-index: 1; }
    .Wrapper .Panel .Image {
      width: 208px;
      height: 208px;
      overflow: hidden;
      position: relative;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px; }
      .Wrapper .Panel .Image img {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: auto;
        height: 120%;
        transition: width 0.2s;
        -webkit-transition: width 0.2s;
        -moz-transition: width 0.2s;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%); }
    .Wrapper .Panel .PriceWrap {
      padding: 16px;
      font-size: 1.2rem;
      color: #6A6E73;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box; }
      .Wrapper .Panel .PriceWrap .Label {
        margin-bottom: 8px; }
      .Wrapper .Panel .PriceWrap .Price {
        font-size: 1.6rem;
        color: #CD2033;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500; }
