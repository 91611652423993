/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  min-height: 50px;
  position: relative; }
  .Wrapper .Loading {
    z-index: 1;
    position: absolute;
    background: rgba(255, 255, 255, 0.3);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center; }
    .Wrapper .Loading .Loader {
      margin: 0 auto;
      border: 4px solid #E6E6E6;
      border-radius: 50%;
      border-top: 4px solid #CD2033;
      width: 18px;
      height: 18px;
      -webkit-animation: spin 1s linear infinite;
      /* Safari */
      animation: spin 1s linear infinite; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
  .Wrapper .Header {
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    height: 49px;
    font-size: 1.4rem;
    color: #202124;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500; }
    .Wrapper .Header .Close {
      cursor: pointer;
      transition: opacity 0.3s;
      -webkit-transition: opacity 0.3s;
      -moz-transition: opacity 0.3s; }
    .Wrapper .Header .Close:hover {
      opacity: .8; }
    .Wrapper .Header .Delete {
      color: #CD2033;
      font-size: 1.2rem;
      cursor: pointer; }
  .Wrapper .List {
    margin: 0;
    padding: 16px; }
    .Wrapper .List .Item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px; }
      .Wrapper .List .Item .RoomType {
        font-size: 1.4rem;
        margin-bottom: 4px; }
      .Wrapper .List .Item .Desc {
        font-size: 1rem;
        color: #c2c2c2; }
      .Wrapper .List .Item .Action {
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .Wrapper .List .Item .Action .Value {
          width: 20px;
          text-align: center;
          font-size: 2rem; }
    .Wrapper .List .Item:last-child {
      margin-bottom: 0; }
