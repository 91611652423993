/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 655;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: #FAFAFA; }
  .Wrapper .Header {
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 2; }
  .Wrapper .Content {
    position: relative;
    z-index: 1;
    min-height: 100%;
    margin-top: 60px;
    padding: 20px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 110px; }
    .Wrapper .Content .BoxItem {
      margin-bottom: 16px; }
    .Wrapper .Content .Action .AddAct {
      height: 32px;
      width: 100%;
      font-size: 1.2rem;
      color: #CD2033;
      border: 1px solid #CD2033;
      background: #FFFFFF;
      cursor: pointer;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      transition: background 0.3s;
      -webkit-transition: background 0.3s;
      -moz-transition: background 0.3s; }
    .Wrapper .Content .Action .AddAct:focus {
      outline: none; }
    .Wrapper .Content .Action .AddAct:hover {
      color: #e44631;
      border: 1px solid #e44631; }
  .Wrapper .Footer {
    position: fixed;
    bottom: 0;
    z-index: 2;
    padding: 16px;
    width: 100%;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(32, 33, 36, 0.1);
    -webkit-box-shadow: 0 1px 8px rgba(32, 33, 36, 0.1);
    -moz-box-shadow: 0 1px 8px rgba(32, 33, 36, 0.1);
    -ms-transform: 0 1px 8px rgba(32, 33, 36, 0.1);
    -o-transform: 0 1px 8px rgba(32, 33, 36, 0.1); }
    .Wrapper .Footer .PriceWrapper {
      min-width: 165px; }
      .Wrapper .Footer .PriceWrapper .Icon {
        height: 100%;
        float: left;
        display: flex;
        align-items: center;
        margin-right: 5px; }
        .Wrapper .Footer .PriceWrapper .Icon .Icons {
          display: table !important;
          background: #FAFAFA !important;
          border-radius: 15px; }
      .Wrapper .Footer .PriceWrapper .Label {
        font-size: 1.2rem; }
      .Wrapper .Footer .PriceWrapper .Price {
        margin-top: 6px;
        font-size: 1.6rem;
        color: #CD2033;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500; }
        .Wrapper .Footer .PriceWrapper .Price .Perpax {
          color: #6A6E73;
          font-size: 1.2rem;
          font-family: "Geomanist-Regular", sans-serif; }

.Open {
  animation: openPopup 0.3s; }

.Closed {
  animation: closePopup 0.4s; }

@keyframes openPopup {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(0); } }

@keyframes closePopup {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(100%); } }
