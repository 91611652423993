/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  width: 100%; }
  .Wrapper .Content {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    .Wrapper .Content .BtnUpload {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f5f5f5;
      border: thin solid #E6E6E6;
      width: 65px;
      padding: 8px 10px;
      border-radius: 5px;
      cursor: pointer; }
      .Wrapper .Content .BtnUpload .Label {
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        color: #6A6E73;
        font-size: 1.3rem; }
    .Wrapper .Content .Rule {
      display: flex;
      font-size: 1.2rem;
      margin-left: 10px; }
