/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 58px;
  background: #CD2033;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  font-size: 1.8rem;
  padding: 0 15px;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500; }
  .Header.Shadow {
    box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.2);
    -webkit-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.2);
    -moz-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.2);
    -ms-transform: 0px 1px 4px rgba(32, 33, 36, 0.2);
    -o-transform: 0px 1px 4px rgba(32, 33, 36, 0.2); }
  .Header .Action {
    margin-right: 16px; }

.ContentWrapper {
  margin: 58px 0 0;
  position: relative;
  background: #f5f5f5; }
  .ContentWrapper .Content {
    padding: 20px;
    position: relative; }
    .ContentWrapper .Content .Section {
      margin-bottom: 30px; }
      .ContentWrapper .Content .Section .Title {
        font-size: 1.5rem;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        color: #202124;
        margin-bottom: 15px; }
      .ContentWrapper .Content .Section .AccountItem {
        padding: 20px 15px;
        border-bottom: 1px solid #f5f5f5; }
        .ContentWrapper .Content .Section .AccountItem .Label {
          font-size: 1.4rem;
          margin-bottom: 4px; }
        .ContentWrapper .Content .Section .AccountItem .Action {
          display: flex;
          align-items: flex-end;
          justify-content: space-between; }
          .ContentWrapper .Content .Section .AccountItem .Action .AtionEdit {
            color: #CD2033;
            font-size: 1.4rem;
            font-family: "Geomanist-Medium", sans-serif;
            font-weight: 500; }
        .ContentWrapper .Content .Section .AccountItem .Value {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #202124;
          margin-bottom: 8px;
          font-size: 1.5rem; }
          .ContentWrapper .Content .Section .AccountItem .Value .Status {
            display: flex;
            align-items: center;
            font-size: 1.4rem;
            color: #6A6E73; }
            .ContentWrapper .Content .Section .AccountItem .Value .Status .Icon {
              margin-right: 4px; }
