/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .Label {
  font-size: 1.6rem;
  color: #202124;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500;
  margin-bottom: 16px; }

.Wrapper .Content {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  position: relative; }
  .Wrapper .Content .Detail {
    overflow: hidden;
    font-size: 1.4rem;
    line-height: 2.0rem;
    color: #6A6E73; }
    .Wrapper .Content .Detail.Less {
      max-height: 100px !important; }
    .Wrapper .Content .Detail.More {
      padding-bottom: 40px;
      max-height: 1000px !important; }
  .Wrapper .Content .WrapperBtn {
    width: 100%;
    position: relative;
    height: 30px; }
    .Wrapper .Content .WrapperBtn .CollapseBtn {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      color: #1A6FA3;
      text-align: center;
      padding: 24px 0 16px;
      font-size: 1.4rem;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
      .Wrapper .Content .WrapperBtn .CollapseBtn .Overlay {
        height: 60px;
        background: linear-gradient(rgba(255, 255, 255, 0.4) 5%, white 50%);
        background: -moz-linear-gradient(rgba(255, 255, 255, 0.4) 5%, white 50%);
        background: -webkit-linear-gradient(rgba(255, 255, 255, 0.4) 5%, white 50%);
        background: -o-linear-gradient(rgba(255, 255, 255, 0.4) 5%, white 50%);
        background: -ms-linear-gradient(rgba(255, 255, 255, 0.4) 5%, white 50%); }
      .Wrapper .Content .WrapperBtn .CollapseBtn .Button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #E6E6E6;
        background: #FFFFFF;
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 6px 0;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px; }
      .Wrapper .Content .WrapperBtn .CollapseBtn .ArrowUp {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg); }
