/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .Header {
  display: flex;
  align-items: center;
  margin-bottom: 12px; }
  .Wrapper .Header .Title {
    font-size: 1.4rem;
    color: #202124;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    margin-left: 8px; }

.Wrapper .Body .List {
  margin: 0;
  padding: 0; }
  .Wrapper .Body .List .Item {
    margin-bottom: 12px;
    list-style: none; }

.Wrapper .Body .Action .AddAct {
  height: 32px;
  width: 100%;
  font-size: 1.2rem;
  color: #CD2033;
  border: 1px solid #CD2033;
  background: #FFFFFF;
  cursor: pointer;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  transition: background 0.3s;
  -webkit-transition: background 0.3s;
  -moz-transition: background 0.3s; }

.Wrapper .Body .Action .AddAct:focus {
  outline: none; }

.Wrapper .Body .Action .AddAct:hover {
  color: #e44631;
  border: 1px solid #e44631; }
