/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  min-height: 100vh;
  background: #FAFAFA; }
  .Wrapper .HeaderWrapper {
    height: 60px;
    overflow: hidden;
    background: #FFFFFF;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-shadow: 0px 2px 6px rgba(32, 33, 36, 0.1);
    -webkit-box-shadow: 0px 2px 6px rgba(32, 33, 36, 0.1);
    -moz-box-shadow: 0px 2px 6px rgba(32, 33, 36, 0.1);
    -ms-transform: 0px 2px 6px rgba(32, 33, 36, 0.1);
    -o-transform: 0px 2px 6px rgba(32, 33, 36, 0.1); }
  .Wrapper .Content {
    padding: 80px 20px 56px;
    position: relative;
    z-index: 1; }
    .Wrapper .Content .Item {
      width: 100%;
      margin-bottom: 16px;
      position: relative;
      box-sizing: border-box; }
      .Wrapper .Content .Item::before {
        content: '';
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background: #FAFAFA;
        position: absolute;
        top: 65px;
        left: -10px;
        z-index: 1;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        box-shadow: inset -12px 0px 4px -10px rgba(106, 110, 115, 0.1);
        -webkit-box-shadow: inset -12px 0px 4px -10px rgba(106, 110, 115, 0.1);
        -moz-box-shadow: inset -12px 0px 4px -10px rgba(106, 110, 115, 0.1);
        -ms-transform: inset -12px 0px 4px -10px rgba(106, 110, 115, 0.1);
        -o-transform: inset -12px 0px 4px -10px rgba(106, 110, 115, 0.1); }
      .Wrapper .Content .Item::after {
        content: '';
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background: #FAFAFA;
        position: absolute;
        top: 65px;
        right: -10px;
        z-index: 1;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        box-shadow: inset 12px 0px 4px -10px rgba(106, 110, 115, 0.1);
        -webkit-box-shadow: inset 12px 0px 4px -10px rgba(106, 110, 115, 0.1);
        -moz-box-shadow: inset 12px 0px 4px -10px rgba(106, 110, 115, 0.1);
        -ms-transform: inset 12px 0px 4px -10px rgba(106, 110, 115, 0.1);
        -o-transform: inset 12px 0px 4px -10px rgba(106, 110, 115, 0.1); }
      .Wrapper .Content .Item .Image {
        width: 100%;
        height: 162px;
        position: relative;
        margin: 0 auto;
        position: relative; }
        .Wrapper .Content .Item .Image img {
          position: absolute;
          top: 50%;
          left: 50%;
          min-width: auto;
          height: 100%;
          transition: height 0.2s;
          -webkit-transition: height 0.2s;
          -moz-transition: height 0.2s;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%); }
      .Wrapper .Content .Item .Body {
        padding: 16px;
        min-height: 107px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box; }
        .Wrapper .Content .Item .Body .Benefit {
          font-size: 1.4rem;
          color: #E6E6E6;
          display: flex;
          margin-bottom: 8px; }
          .Wrapper .Content .Item .Body .Benefit.Active {
            color: #202124; }
          .Wrapper .Content .Item .Body .Benefit .Text {
            margin-left: 8px;
            margin-top: 2px; }
      .Wrapper .Content .Item .Footer {
        border-top: 1px dashed #E6E6E6;
        padding: 16px;
        display: flex;
        align-items: flex-end; }
        .Wrapper .Content .Item .Footer .ItemDate {
          flex: 1; }
          .Wrapper .Content .Item .Footer .ItemDate .Label {
            font-size: 1.2rem;
            color: #6A6E73;
            margin-bottom: 5px; }
