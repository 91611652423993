/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  position: fixed;
  width: 100%;
  z-index: 700;
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; }
  .Wrapper .Header {
    background: #FFFFFF;
    padding: 16px;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -webkit-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -moz-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -ms-transform: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -o-transform: 0px 1px 4px rgba(32, 33, 36, 0.05); }
    .Wrapper .Header .BackBtn {
      cursor: pointer;
      margin-right: 16px; }
    .Wrapper .Header .Title {
      font-size: 1.6rem;
      margin-top: 6px;
      color: #202124;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
  .Wrapper .Content {
    background: #FAFAFA;
    padding: 62px 0 90px;
    position: relative;
    z-index: 1;
    min-height: 100vh;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box; }
  .Wrapper .ActionWrapper {
    display: flex;
    padding: 8px;
    width: 100%;
    background: #FFFFFF;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.03);
    -webkit-box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.03);
    -moz-box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.03);
    -ms-transform: 0px -2px 4px rgba(0, 0, 0, 0.03);
    -o-transform: 0px -2px 4px rgba(0, 0, 0, 0.03); }
    .Wrapper .ActionWrapper .Button {
      flex: 1;
      -moz-flex: 1;
      -webkit-flex: 1;
      padding: 8px; }

.Open {
  animation: openPopup 0.3s; }

.Close {
  animation: closePopup 0.4s; }

@keyframes openPopup {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translateY(0); } }

@keyframes closePopup {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(100%); } }
