/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .Title {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500;
  color: #202124;
  padding: 24px;
  border-bottom: 1px solid #f0f0f0; }

.Wrapper .Action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
  padding: 12px 24px;
  border-top: 1px solid #f0f0f0;
  background: #FAFAFA;
  border-bottom-left-radius: 8px;
  -webkit-border-bottom-left-radius: 8px;
  -moz-border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  -webkit-border-bottom-right-radius: 8px;
  -moz-border-bottom-right-radius: 8px;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500; }

.Wrapper .Image {
  overflow: hidden;
  width: 100%;
  border-radius: 20px; }

.Wrapper .ZoomNote {
  margin: 6px;
  font-size: 1.2rem;
  color: #c2c2c2; }
