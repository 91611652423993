/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.OuterWrap {
  position: relative; }
  .OuterWrap .Wrapper {
    background: #FFFFFF;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-top-left-radius: 8px;
    -webkit-border-top-left-radius: 8px;
    -moz-border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    -webkit-border-bottom-left-radius: 8px;
    -moz-border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
    -webkit-border-top-right-radius: 8px;
    -moz-border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    -webkit-border-bottom-right-radius: 8px;
    -moz-border-bottom-right-radius: 8px;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.08);
    -ms-transform: 0 5px 25px 0 rgba(0, 0, 0, 0.08);
    -o-transform: 0 5px 25px 0 rgba(0, 0, 0, 0.08); }
    .OuterWrap .Wrapper.TopLeftRadius {
      border-top-left-radius: 8px;
      -webkit-border-top-left-radius: 8px;
      -moz-border-top-left-radius: 8px; }
    .OuterWrap .Wrapper .Form {
      padding: 15px 0;
      display: flex;
      flex: 1;
      -moz-flex: 1;
      -webkit-flex: 1; }
      .OuterWrap .Wrapper .Form.Small {
        padding: 12px 0 !important; }
      .OuterWrap .Wrapper .Form .InputWrapper {
        border-right: 1px solid #E6E6E6;
        flex: 1;
        -moz-flex: 1;
        -webkit-flex: 1; }
        .OuterWrap .Wrapper .Form .InputWrapper .Input {
          display: flex;
          justify-content: space-between; }
          .OuterWrap .Wrapper .Form .InputWrapper .Input input {
            width: 100%;
            border: none;
            font-size: 1.4rem;
            outline: none;
            font-family: "Geomanist-Regular", sans-serif; }
          .OuterWrap .Wrapper .Form .InputWrapper .Input input:focus {
            border-bottom: 1px solid #CD2033; }
          .OuterWrap .Wrapper .Form .InputWrapper .Input .Icon {
            transition: transform 0.3s;
            -webkit-transition: transform 0.3s;
            -moz-transition: transform 0.3s; }
            .OuterWrap .Wrapper .Form .InputWrapper .Input .Icon .Active {
              transform: rotate(180deg); }
        .OuterWrap .Wrapper .Form .InputWrapper .Search {
          padding: 2px 16px;
          display: flex;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          align-items: center; }
          .OuterWrap .Wrapper .Form .InputWrapper .Search .Label {
            font-size: 14px;
            color: #6A6E73;
            padding-left: 16px;
            margin-top: 3px; }
          .OuterWrap .Wrapper .Form .InputWrapper .Search .InputSpace {
            flex: 1; }
      .OuterWrap .Wrapper .Form .InputWrapper:last-child {
        border-right: none;
        margin-right: 0px;
        padding-right: 0px; }
    .OuterWrap .Wrapper .Action {
      width: 205px;
      background: red;
      border-top-right-radius: 8px;
      -webkit-border-top-right-radius: 8px;
      -moz-border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      -webkit-border-bottom-right-radius: 8px;
      -moz-border-bottom-right-radius: 8px;
      overflow: hidden;
      display: flex; }
      .OuterWrap .Wrapper .Action .Button {
        flex: 1;
        align-items: stretch;
        border: none;
        font-size: 1.8rem;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        color: #FFFFFF;
        outline: none !important;
        cursor: pointer;
        background: linear-gradient(to right, #CD2033, #e44631);
        background: -moz-linear-gradient(to right, #CD2033, #e44631);
        background: -webkit-linear-gradient(to right, #CD2033, #e44631);
        background: -o-linear-gradient(to right, #CD2033, #e44631);
        background: -ms-linear-gradient(to right, #CD2033, #e44631); }
      .OuterWrap .Wrapper .Action .Button:active {
        outline: none; }
  .OuterWrap .SubProduct {
    position: absolute;
    width: 100%;
    display: flex; }
    .OuterWrap .SubProduct .Show {
      flex: 1;
      -moz-flex: 1;
      -webkit-flex: 1;
      background: #FFFFFF;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      display: flex;
      padding: 24px; }
      .OuterWrap .SubProduct .Show .Item {
        font-size: 13px;
        padding: 8px 16px;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        background: #f5f5f5;
        border-radius: 3px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        margin-right: 10px;
        cursor: pointer; }
        .OuterWrap .SubProduct .Show .Item:hover {
          background: #E6E6E6; }
        .OuterWrap .SubProduct .Show .Item.Active {
          background: #CD2033;
          color: #FFFFFF;
          cursor: default; }
    .OuterWrap .SubProduct .Hide {
      width: 205px;
      visibility: hidden; }
  .OuterWrap .SearchAvailibility {
    position: absolute;
    width: 100%;
    display: flex;
    margin-top: 70px; }
    .OuterWrap .SearchAvailibility .ShowSearch {
      flex: 1;
      -moz-flex: 1;
      -webkit-flex: 1;
      background: #FFFFFF;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-bottom-left-radius: 8px;
      -webkit-border-bottom-left-radius: 8px;
      -moz-border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      -webkit-border-bottom-right-radius: 8px;
      -moz-border-bottom-right-radius: 8px;
      padding: 20px 10px;
      cursor: pointer; }
      .OuterWrap .SearchAvailibility .ShowSearch .Package {
        width: 100% !important;
        display: flex;
        padding: 10px 16px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box; }
        .OuterWrap .SearchAvailibility .ShowSearch .Package .ImageWrapper {
          flex-shrink: 0;
          flex-basis: 69px;
          width: 69px;
          height: 69px;
          overflow: hidden;
          position: relative;
          margin-right: 16px;
          border-radius: 8px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          border: thin solid #c2c2c2; }
          .OuterWrap .SearchAvailibility .ShowSearch .Package .ImageWrapper .Img {
            position: absolute;
            left: 50%;
            top: 50%;
            height: 100%;
            width: auto;
            transition: height 0.2s;
            -webkit-transition: height 0.2s;
            -moz-transition: height 0.2s;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%); }
        .OuterWrap .SearchAvailibility .ShowSearch .Package .Description {
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
          .OuterWrap .SearchAvailibility .ShowSearch .Package .Description .Title {
            font-size: 1.4rem;
            line-height: 1.4em;
            color: #202124;
            margin-top: 10px; }
          .OuterWrap .SearchAvailibility .ShowSearch .Package .Description .PriceWrapper {
            display: flex;
            align-items: center;
            margin-bottom: 15px; }
            .OuterWrap .SearchAvailibility .ShowSearch .Package .Description .PriceWrapper .StartFrom {
              font-size: 1.3rem;
              margin-right: 8px;
              font-family: "Geomanist-Medium", sans-serif;
              font-weight: 500; }
            .OuterWrap .SearchAvailibility .ShowSearch .Package .Description .PriceWrapper .Price {
              font-size: 1.5rem;
              font-family: "Geomanist-Medium", sans-serif;
              font-weight: 500;
              color: #CD2033; }
            .OuterWrap .SearchAvailibility .ShowSearch .Package .Description .PriceWrapper .Perpax {
              color: #CD2033;
              font-size: 1.1rem; }
        .OuterWrap .SearchAvailibility .ShowSearch .Package:hover {
          background: #f5f5f5; }
    .OuterWrap .SearchAvailibility .Hide {
      width: 205px;
      visibility: hidden; }

@keyframes openDropdown {
  0% {
    opacity: 0;
    transform: translateY(-30%);
    -webkit-transform: translateY(-30%);
    -moz-transform: translateY(-30%);
    -ms-transform: translateY(-30%);
    -o-transform: translateY(-30%); }
  30% {
    opacity: .3;
    transform: translateY(-5%);
    -webkit-transform: translateY(-5%);
    -moz-transform: translateY(-5%);
    -ms-transform: translateY(-5%);
    -o-transform: translateY(-5%); }
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0); } }

@keyframes closeDropdown {
  0% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0); }
  50% {
    opacity: 0;
    transform: translateY(-10%);
    -webkit-transform: translateY(-10%);
    -moz-transform: translateY(-10%);
    -ms-transform: translateY(-10%);
    -o-transform: translateY(-10%); }
  100% {
    opacity: 0;
    transform: translateY(-10%);
    -webkit-transform: translateY(-10%);
    -moz-transform: translateY(-10%);
    -ms-transform: translateY(-10%);
    -o-transform: translateY(-10%); } }

.Open {
  animation: openDropdown 0.3s ease-out;
  -webkit-animation: openDropdown 0.3s ease-out;
  -moz-animation: openDropdown 0.3s ease-out;
  -ms-transform: openDropdown 0.3s ease-out;
  -o-transform: openDropdown 0.3s ease-out; }

.Close {
  animation: closeDropdown 0.3s ease-out;
  -webkit-animation: closeDropdown 0.3s ease-out;
  -moz-animation: closeDropdown 0.3s ease-out;
  -ms-transform: closeDropdown 0.3s ease-out;
  -o-transform: closeDropdown 0.3s ease-out; }
