/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  position: relative;
  font-family: "Geomanist-Regular", sans-serif; }
  .Wrapper .UserNav {
    position: relative;
    display: flex;
    align-items: center;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500; }
    .Wrapper .UserNav .Avatar {
      background: #CD2033;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%; }
    .Wrapper .UserNav .Icon {
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out; }
      .Wrapper .UserNav .Icon.IsActive {
        transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        -o-transform: rotate(-180deg); }
  .Wrapper .List {
    width: 100%;
    min-width: 190px;
    padding: 0px;
    margin: 8px 0 0;
    position: absolute;
    background: #FFFFFF;
    box-sizing: border-box;
    right: 0;
    font-size: 1.3rem;
    color: #202124;
    z-index: 500;
    max-height: 400px;
    overflow: auto;
    font-weight: normal;
    border: 1px solid rgba(230, 230, 230, 0.5);
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.05);
    -ms-transform: 0 1px 4px 1px rgba(0, 0, 0, 0.05);
    -o-transform: 0 1px 4px 1px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px; }
    .Wrapper .List .Item {
      font-family: "Geomanist-Regular", sans-serif;
      padding: 12px 16px;
      list-style: none;
      display: flex;
      align-content: center;
      cursor: pointer; }
    .Wrapper .List .Item:hover {
      background: #FAFAFA; }
    .Wrapper .List .Item:first-child {
      margin-top: 8px; }
    .Wrapper .List .Item:last-child {
      margin-bottom: 8px; }

@keyframes openDropdown {
  0% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px); }
  30% {
    opacity: .3;
    transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px); }
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0); } }

@keyframes closeDropdown {
  0% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0); }
  50% {
    opacity: 0;
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px); }
  100% {
    opacity: 0;
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px); } }

.Open {
  animation: openDropdown 0.3s ease-out;
  -webkit-animation: openDropdown 0.3s ease-out;
  -moz-animation: openDropdown 0.3s ease-out;
  -ms-transform: openDropdown 0.3s ease-out;
  -o-transform: openDropdown 0.3s ease-out; }

.Close {
  animation: closeDropdown 0.3s ease-out;
  -webkit-animation: closeDropdown 0.3s ease-out;
  -moz-animation: closeDropdown 0.3s ease-out;
  -ms-transform: closeDropdown 0.3s ease-out;
  -o-transform: closeDropdown 0.3s ease-out; }
