/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .Content {
  padding: 16px 016px 0px;
  margin-bottom: -16px; }
  .Wrapper .Content .Header {
    width: 100%;
    display: flex;
    border-bottom: thin solid #E6E6E6;
    padding-bottom: 16px;
    margin-bottom: 16px; }
    .Wrapper .Content .Header .Title {
      font-size: 1.6rem;
      color: #202124;
      line-height: 24px;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
  .Wrapper .Content .Body {
    padding-bottom: 16px; }
    .Wrapper .Content .Body .Item {
      margin-bottom: 16px; }
      .Wrapper .Content .Body .Item .Label {
        font-size: 1.2rem;
        margin: 8px 0px;
        color: #818589; }
      .Wrapper .Content .Body .Item .Detail {
        font-size: 1.4rem;
        color: #202124;
        display: flex;
        align-items: center;
        flex-wrap: wrap; }
        .Wrapper .Content .Body .Item .Detail .Icon {
          margin-top: -4px;
          padding-right: 11px; }

.Wrapper .Footer {
  margin-top: 0px;
  padding: 16px;
  background: #FAFAFA;
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500; }
  .Wrapper .Footer .Label {
    color: #818589; }
  .Wrapper .Footer .Price {
    color: #CD2033; }
