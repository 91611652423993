/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  min-height: 100vh;
  background: #f5f5f5;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .Wrapper .LabelList {
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(32, 33, 36, 0.05);
    -webkit-box-shadow: 0px 2px 2px rgba(32, 33, 36, 0.05);
    -moz-box-shadow: 0px 2px 2px rgba(32, 33, 36, 0.05);
    -ms-transform: 0px 2px 2px rgba(32, 33, 36, 0.05);
    -o-transform: 0px 2px 2px rgba(32, 33, 36, 0.05);
    position: sticky;
    top: 0;
    margin: 0;
    padding: 12px 0 12px 20px;
    width: 100%;
    z-index: 1;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box; }
    .Wrapper .LabelList .ItemWrapper {
      margin-right: 20px;
      display: flex;
      justify-content: center; }
      .Wrapper .LabelList .ItemWrapper .Item {
        margin-right: 8px;
        text-align: center;
        font-size: 1.2rem;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        padding: 8px 10px;
        background: #f5f5f5;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px; }
        .Wrapper .LabelList .ItemWrapper .Item.Active {
          background: #1A6FA3 !important;
          color: #FFFFFF !important; }
    .Wrapper .LabelList .ItemWrapper:focus {
      outline: none; }
    .Wrapper .LabelList .ItemWrapperLoader {
      width: 100% !important;
      height: 20px !important; }
  .Wrapper .Content {
    padding: 20px 20px 60px; }
