/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  min-height: 100vh;
  background: #FAFAFA;
  min-width: 960px; }
  .Wrapper .Container {
    padding: 40px 0 100px;
    display: flex;
    width: 960px;
    margin: 0 auto; }
    .Wrapper .Container .LeftNav {
      width: 250px;
      margin-right: 24px; }
      .Wrapper .Container .LeftNav .MenuList {
        padding: 48px 0;
        margin: 0; }
        .Wrapper .Container .LeftNav .MenuList .Item {
          display: flex;
          align-items: center;
          padding: 12px 12px 12px 48px;
          border-left: 3px solid #FFFFFF;
          font-size: 1.4rem;
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500;
          transition: all 0.2s;
          -webkit-transition: all 0.2s;
          -moz-transition: all 0.2s;
          cursor: pointer; }
          .Wrapper .Container .LeftNav .MenuList .Item .Label {
            margin-left: 18px; }
          .Wrapper .Container .LeftNav .MenuList .Item.Active {
            color: #CD2033;
            border-left: 3px solid #CD2033; }
            .Wrapper .Container .LeftNav .MenuList .Item.Active svg g path {
              fill: #CD2033; }
        .Wrapper .Container .LeftNav .MenuList .Item:hover {
          color: #CD2033;
          border-left: 3px solid #CD2033; }
          .Wrapper .Container .LeftNav .MenuList .Item:hover svg g path {
            fill: #CD2033; }
    .Wrapper .Container .RightContent {
      flex: 1;
      -moz-flex: 1;
      -webkit-flex: 1; }
      .Wrapper .Container .RightContent .Content {
        padding: 40px 24px; }
