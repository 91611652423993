/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  padding: 32px 24px;
  background: #FAFAFA;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  font-size: 1.4rem; }
  .Wrapper .Head {
    display: flex;
    margin-top: 24px; }
    .Wrapper .Head .TitleWrap {
      margin-left: 14px; }
      .Wrapper .Head .TitleWrap .Duration {
        font-size: 1.2rem;
        margin-top: 8px; }
  .Wrapper .Title {
    line-height: 1.4em;
    color: #202124;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500; }
  .Wrapper .List {
    padding: 0;
    margin: 8px 0 0;
    display: flex;
    flex-wrap: wrap; }
    .Wrapper .List .Item {
      width: 50%;
      margin-top: 16px;
      list-style: none;
      color: #202124;
      display: flex;
      align-items: center;
      font-size: 1.4rem; }
      .Wrapper .List .Item .Label {
        margin-top: 4px;
        margin-left: 8px; }
