/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  margin-top: 32px; }
  .Wrapper .Title {
    font-size: 1.6rem;
    color: #202124;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    margin-bottom: 16px; }
  .Wrapper .Content {
    padding: 16px; }
    .Wrapper .Content .Item {
      display: flex;
      align-items: center;
      border-bottom: thin solid #f5f5f5;
      padding-bottom: 16px;
      padding-top: 16px; }
      .Wrapper .Content .Item .Left {
        flex: 1; }
        .Wrapper .Content .Item .Left label {
          color: #6A6E73;
          font-size: 1.0rem; }
        .Wrapper .Content .Item .Left .Price {
          color: #202124;
          font-size: 1.4rem;
          margin-top: 5px; }
      .Wrapper .Content .Item .Right {
        width: 115px;
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .Wrapper .Content .Item .Right .Count {
          color: #202124;
          font-size: 1.8rem;
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500; }
      .Wrapper .Content .Item:first-child {
        padding-top: 0px; }
      .Wrapper .Content .Item:last-child {
        border-bottom: none;
        padding-bottom: 0px; }
