/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  padding: 24px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .Wrapper .Tabmenu {
    display: flex;
    padding: 0;
    margin: 0;
    margin-bottom: 24px;
    border-bottom: 1px solid #f5f5f5; }
    .Wrapper .Tabmenu li {
      list-style: none;
      border-bottom: 3px solid #FFFFFF;
      padding: 0 24px 24px;
      transition: border .3s, color .3s;
      -webkit-transition: border .3s, color .3s;
      -moz-transition: border .3s, color .3s;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      cursor: pointer; }
      .Wrapper .Tabmenu li.Active {
        color: #202124;
        border-bottom: 3px solid #1A6FA3; }
    .Wrapper .Tabmenu li:hover {
      color: #202124;
      border-bottom: 3px solid #1A6FA3; }
