.List {
    margin: 24px 0 0;
    padding: 0;
    .Item {
        list-style: none;
        margin-bottom: 16px;
    }
    .Item:last-child {
        margin-bottom: 0;
    }
}