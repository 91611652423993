/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Backdrop {
  position: relative;
  z-index: 750; }

.Wrapper {
  position: fixed;
  color: #202124;
  z-index: 750;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .Wrapper .Alert {
    width: 100%;
    max-width: 450px;
    background: #FFFFFF;
    padding: 48px 32px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    position: relative; }
    .Wrapper .Alert .CloseBtn {
      position: absolute;
      right: 24px;
      top: 16px;
      cursor: pointer; }
    .Wrapper .Alert .CloseBtn:hover svg g path {
      fill: #CD2033; }
    .Wrapper .Alert .Title {
      font-size: 2.2rem;
      text-align: center;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
    .Wrapper .Alert .Content {
      margin-top: 32px; }

@keyframes openAlert {
  0% {
    opacity: 0;
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7); }
  100% {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1); } }

@keyframes closeAlert {
  0% {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7); } }

.Open {
  animation: openAlert 0.2s;
  -webkit-animation: openAlert 0.2s;
  -moz-animation: openAlert 0.2s;
  -ms-transform: openAlert 0.2s;
  -o-transform: openAlert 0.2s; }

.Close {
  animation: closeAlert 0.2s;
  -webkit-animation: closeAlert 0.2s;
  -moz-animation: closeAlert 0.2s;
  -ms-transform: closeAlert 0.2s;
  -o-transform: closeAlert 0.2s; }
