/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  height: 100vh;
  background: #FAFAFA; }
  .Wrapper .Header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2; }
    .Wrapper .Header .SearchForm {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center; }
      .Wrapper .Header .SearchForm .FirstIcon {
        left: 12px;
        position: absolute; }
      .Wrapper .Header .SearchForm .Input {
        width: 100%;
        border: 1px solid #FFFFFF;
        color: #6A6E73;
        padding: 11px 16px 11px 36px;
        font-size: 1.3rem;
        background: #f5f5f5;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        transition: border .3s, color .3s;
        -webkit-transition: border .3s, color .3s;
        -moz-transition: border .3s, color .3s;
        font-family: "Geomanist-Regular", sans-serif;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; }
      .Wrapper .Header .SearchForm .Input:focus {
        outline: none; }
  .Wrapper .Content {
    z-index: 1;
    padding-top: 72px;
    position: relative; }
