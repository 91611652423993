/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .List {
  display: flex;
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500; }
  .Wrapper .List .Item {
    list-style: none;
    margin-right: 5px;
    border-top-left-radius: 8px;
    -webkit-border-top-left-radius: 8px;
    -moz-border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    -webkit-border-top-right-radius: 8px;
    -moz-border-top-right-radius: 8px;
    display: block;
    color: #6A6E73;
    text-decoration: none;
    padding: 11px 32px;
    background: #E6E6E6;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    position: relative; }
    .Wrapper .List .Item.Active {
      background: #FFFFFF;
      color: #202124; }
    .Wrapper .List .Item .Badges {
      font-size: 1.2rem;
      background: #73C700;
      color: #FFFFFF;
      position: absolute;
      top: -10px;
      right: 10px;
      padding: 4px 8px 2px;
      border-radius: 9px; }
  .Wrapper .List .Item:hover {
    color: #202124; }
