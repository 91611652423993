/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  min-width: 1080px;
  position: relative;
  display: flex;
  justify-content: center;
  height: 334px; }
  .Wrapper .Background {
    height: 334px;
    top: 0;
    left: 0;
    position: absolute;
    overflow: hidden;
    width: 100%; }
    .Wrapper .Background .Overlay {
      width: 100%;
      position: absolute;
      height: 334px;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8));
      background: -moz-linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8));
      background: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8));
      background: -o-linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8));
      background: -ms-linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8)); }
    .Wrapper .Background img {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      height: auto;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%); }
  .Wrapper .Container {
    position: relative;
    width: 960px;
    margin: 0 auto;
    padding: 40px 0; }
    .Wrapper .Container .Duration {
      padding: 6px 24px;
      background: #FFFFFF;
      font-size: 1.2rem;
      color: #CD2033;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      border-radius: 15px;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px; }
    .Wrapper .Container .TitleWrapper {
      position: absolute;
      bottom: 40px;
      left: 0;
      color: #FFFFFF;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
      .Wrapper .Container .TitleWrapper .Title {
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 16px; }
      .Wrapper .Container .TitleWrapper .CityList {
        margin: 0;
        padding: 0;
        display: flex;
        width: 80%;
        flex-wrap: wrap; }
        .Wrapper .Container .TitleWrapper .CityList li {
          margin-bottom: 12px;
          margin-right: 8px;
          list-style: none; }
        .Wrapper .Container .TitleWrapper .CityList li:not(:last-child)::after {
          content: '\2022';
          margin-left: 8px; }
      .Wrapper .Container .TitleWrapper .Includes {
        display: flex; }
        .Wrapper .Container .TitleWrapper .Includes .Item {
          margin-right: 8px; }
    .Wrapper .Container .ViewPhoto {
      position: absolute;
      bottom: 40px;
      right: 0;
      display: inline-flex;
      padding: 6px 12px;
      background: #FFFFFF;
      font-size: 1.2rem;
      color: #6A6E73;
      align-items: center;
      cursor: pointer;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px; }
      .Wrapper .Container .ViewPhoto .Label {
        margin-left: 8px; }
    .Wrapper .Container .ViewPhoto:hover {
      color: #202124; }
