/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  margin-bottom: 200px; }
  .Wrapper .Container {
    width: 960px;
    margin: 0 auto; }
    .Wrapper .Container .Content {
      width: 100%; }
      .Wrapper .Container .Content .Clear {
        clear: both; }
      .Wrapper .Container .Content .ItemWrapper {
        position: relative;
        width: 984px;
        min-height: 100px;
        margin-left: -24px;
        display: block; }
        .Wrapper .Container .Content .ItemWrapper .Item {
          position: relative;
          width: 304px;
          margin-left: 24px;
          margin-bottom: 24px;
          float: left; }
          .Wrapper .Container .Content .ItemWrapper .Item .ItemBox {
            position: relative;
            width: 100%;
            box-sizing: border-box; }
            .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Image {
              width: 100%;
              height: 127px;
              border-radius: 12px;
              margin: 0 auto;
              position: relative; }
              .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Image img {
                position: absolute;
                top: 50%;
                left: 50%;
                min-width: auto;
                height: 100%;
                transition: height 0.2s;
                -webkit-transition: height 0.2s;
                -moz-transition: height 0.2s;
                transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%); }
              .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Image::before {
                content: '';
                width: 20px;
                height: 20px;
                background: #FAFAFA;
                position: absolute;
                z-index: 1;
                top: 50%;
                left: -10px;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                box-shadow: inset -12px 0px 4px -10px rgba(106, 110, 115, 0.1);
                -webkit-box-shadow: inset -12px 0px 4px -10px rgba(106, 110, 115, 0.1);
                -moz-box-shadow: inset -12px 0px 4px -10px rgba(106, 110, 115, 0.1);
                -ms-transform: inset -12px 0px 4px -10px rgba(106, 110, 115, 0.1);
                -o-transform: inset -12px 0px 4px -10px rgba(106, 110, 115, 0.1); }
              .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Image::after {
                content: '';
                width: 20px;
                height: 20px;
                background: #FAFAFA;
                position: absolute;
                z-index: 1;
                top: 50%;
                right: -10px;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                box-shadow: inset 12px 0px 4px -10px rgba(106, 110, 115, 0.1);
                -webkit-box-shadow: inset 12px 0px 4px -10px rgba(106, 110, 115, 0.1);
                -moz-box-shadow: inset 12px 0px 4px -10px rgba(106, 110, 115, 0.1);
                -ms-transform: inset 12px 0px 4px -10px rgba(106, 110, 115, 0.1);
                -o-transform: inset 12px 0px 4px -10px rgba(106, 110, 115, 0.1); }
            .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Body {
              padding: 16px;
              min-height: 125px;
              box-sizing: border-box;
              -moz-box-sizing: border-box;
              -webkit-box-sizing: border-box; }
              .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Body .Benefit {
                font-size: 1.4rem;
                color: #E6E6E6;
                display: flex;
                margin-bottom: 8px; }
                .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Body .Benefit .Icon {
                  min-width: 16px; }
                .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Body .Benefit.Active {
                  color: #202124; }
                .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Body .Benefit .Text {
                  margin-left: 8px;
                  margin-top: 2px; }
            .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Footer {
              border-top: 1px dashed #E6E6E6;
              padding: 16px;
              display: flex;
              align-items: flex-end; }
              .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Footer .ItemDate {
                flex: 1; }
                .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Footer .ItemDate .Label {
                  font-size: 1.2rem;
                  color: #6A6E73;
                  margin-bottom: 5px; }
                .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Footer .ItemDate .Date {
                  font-size: 1.4rem;
                  color: #202124;
                  display: flex;
                  align-items: center; }
                  .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Footer .ItemDate .Date .Text {
                    margin-left: 8px;
                    margin-top: 2px; }
              .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Footer .ItemBtn {
                flex: 1; }
