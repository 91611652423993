/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 655;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: #FAFAFA; }
  .Wrapper .Header {
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 2; }
    .Wrapper .Header .HeadTitle {
      display: flex;
      justify-content: space-between; }
      .Wrapper .Header .HeadTitle .Download {
        cursor: pointer;
        border: 1px solid #CD2033;
        color: #CD2033;
        text-decoration: none;
        font-size: 1.1rem;
        padding: 6px 12px 4px;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        transition: background 0.3s;
        -webkit-transition: background 0.3s;
        -moz-transition: background 0.3s;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px; }
      .Wrapper .Header .HeadTitle .Download:hover {
        background: #CD2033;
        color: #FFFFFF; }
  .Wrapper .List {
    position: relative;
    z-index: 1;
    min-height: 100%;
    margin-top: 60px;
    padding: 20px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; }
    .Wrapper .List .Item {
      margin-bottom: 16px; }

.Open {
  animation: openPopup 0.3s; }

.Close {
  animation: closePopup 0.3s; }

@keyframes openPopup {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translateY(0); } }

@keyframes closePopup {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(100%); } }
