/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .WrapperItem {
  padding: 24px;
  box-sizing: border-box; }
  .Wrapper .WrapperItem .Title {
    font-size: 2.4rem;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    line-height: 1.4rem;
    color: #202124; }
  .Wrapper .WrapperItem .Location {
    display: flex;
    margin-bottom: 24px;
    font-size: 1.2rem; }
    .Wrapper .WrapperItem .Location .Detail {
      margin-top: 3px;
      margin-left: 5px; }
  .Wrapper .WrapperItem img {
    width: 100%;
    border-radius: 6px; }

.Wrapper .Title {
  font-size: 1.6rem;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500;
  margin-bottom: 16px;
  color: #202124; }

.Wrapper .Summary {
  font-size: 1.4rem;
  line-height: 2.3rem;
  color: #818589; }

.Wrapper .ValidUntil {
  display: flex;
  align-items: center;
  padding-top: 16px;
  margin-top: 16px;
  border-top: thin solid #f5f5f5;
  font-size: 1.3rem;
  color: #202124; }
