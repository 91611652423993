/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Head {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 500;
  text-align: center; }
  .Head .Title {
    font-size: 1.8rem;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    color: #FFFFFF; }

.ContentWrapper {
  min-height: 100vh;
  padding-top: 58px;
  position: relative;
  background: #f5f5f5;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .ContentWrapper .BgRed {
    z-index: 0;
    left: 0;
    top: 58px;
    width: 100%;
    height: 70px;
    background: #CD2033;
    position: absolute;
    border-bottom-left-radius: 40px;
    -webkit-border-bottom-left-radius: 40px;
    -moz-border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    -webkit-border-bottom-right-radius: 40px;
    -moz-border-bottom-right-radius: 40px; }
  .ContentWrapper .Content {
    padding: 20px;
    position: relative; }
    .ContentWrapper .Content .Item {
      margin-bottom: 20px; }
      .ContentWrapper .Content .Item .Profile {
        padding: 15px; }
        .ContentWrapper .Content .Item .Profile .Title {
          font-size: 1.8rem;
          color: #202124;
          margin-bottom: 4px;
          display: flex;
          justify-content: space-between;
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500; }
        .ContentWrapper .Content .Item .Profile .Status {
          display: flex;
          font-size: 1.4rem;
          align-items: center; }
      .ContentWrapper .Content .Item .AccountItem {
        padding: 15px; }
        .ContentWrapper .Content .Item .AccountItem .Label {
          font-size: 1.4rem;
          margin-bottom: 8px; }
        .ContentWrapper .Content .Item .AccountItem .AccountValue {
          display: flex;
          justify-content: space-between;
          color: #202124; }
          .ContentWrapper .Content .Item .AccountItem .AccountValue .Action {
            font-size: 1.4rem;
            color: #CD2033;
            font-family: "Geomanist-Medium", sans-serif;
            font-weight: 500; }
      .ContentWrapper .Content .Item .MenuItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 15px;
        border-bottom: 1px solid #f5f5f5; }
        .ContentWrapper .Content .Item .MenuItemLabel {
          display: flex;
          align-items: center;
          color: #202124;
          font-size: 1.4rem;
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500; }
          .ContentWrapper .Content .Item .MenuItemLabelIcon {
            margin-right: 12px; }
      .ContentWrapper .Content .Item .MenuItem:last-child {
        border-bottom: none; }
