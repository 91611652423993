/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 655;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: #FAFAFA; }
  .Wrapper .Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100vh; }
    .Wrapper .Content .Image {
      width: 100%;
      height: 200px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
      .Wrapper .Content .Image img {
        width: 65%; }
    .Wrapper .Content .Desc {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center; }
      .Wrapper .Content .Desc p {
        padding: 24px;
        text-align: center; }

.Open {
  animation: openPopup 0.3s; }

.Close {
  animation: closePopup 0.3s; }

@keyframes openPopup {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translateY(0); } }

@keyframes closePopup {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(100%); } }
