/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Header {
  width: 100%;
  height: 58px;
  background: #CD2033;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  font-size: 1.8rem;
  padding: 0 15px;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500; }
  .Header .HeaderAction {
    margin-right: 16px; }

.Content {
  padding: 78px 20px 20px; }
  .Content .Section {
    margin-bottom: 20px; }
    .Content .Section .Form {
      padding: 20px 15px;
      width: 100%;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box; }
      .Content .Section .Form .InputWrapper {
        margin-bottom: 20px; }
        .Content .Section .Form .InputWrapper:last-child {
          margin-bottom: 0; }
        .Content .Section .Form .InputWrapper .Label {
          display: flex;
          font-size: 1.4rem;
          line-height: 1.6em;
          color: #202124;
          margin-bottom: 6px; }
        .Content .Section .Form .InputWrapper .Input {
          display: flex;
          align-items: center;
          justify-content: center; }
          .Content .Section .Form .InputWrapper .Input .Link {
            text-align: center;
            font-size: 1.2rem;
            color: #CD2033;
            cursor: pointer; }
