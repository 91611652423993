/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.WarningWrapper .Wrapper {
  padding: 16px; }
  .WarningWrapper .Wrapper .Header {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .WarningWrapper .Wrapper .Header .Title {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      color: #202124;
      font-size: 1.4rem; }
    .WarningWrapper .Wrapper .Header .Arrow.Edit {
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      color: #CD2033;
      font-size: 1.4rem; }
  .WarningWrapper .Wrapper .DivWrapper {
    margin: 16px 0px; }
    .WarningWrapper .Wrapper .DivWrapper .Divider {
      border-top: 1px solid rgba(194, 194, 194, 0.4); }
  .WarningWrapper .Wrapper .RoomInfo {
    margin: 16px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .WarningWrapper .Wrapper .RoomInfo .Type {
      color: rgba(106, 110, 115, 0.8);
      font-size: 1.4rem; }
    .WarningWrapper .Wrapper .RoomInfo .Name {
      color: #202124;
      font-size: 1.4rem; }
    .WarningWrapper .Wrapper .RoomInfo .EmptyName {
      color: #CD2033;
      font-size: 1.4rem; }
    .WarningWrapper .Wrapper .RoomInfo:last-child {
      margin: 0px; }

.WarningWrapper.OutlineRed {
  border: thin solid red;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px; }

.Note {
  padding: 16px 16px;
  background: #FAFAFA;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
  border-radius: 0px 0px 8px 8px;
  -webkit-border-radius: 0px 0px 8px 8px;
  -moz-border-radius: 0px 0px 8px 8px; }
