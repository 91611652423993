/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.InpuDate {
  display: flex;
  justify-content: space-between;
  border: 1px solid #E6E6E6;
  color: #6A6E73;
  padding: 8px 16px;
  font-size: 1.4rem;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  transition: border .3s, color .3s;
  -webkit-transition: border .3s, color .3s;
  -moz-transition: border .3s, color .3s;
  font-family: "Geomanist-Regular", sans-serif;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  .InpuDate .Label {
    display: flex;
    align-items: center; }
  .InpuDate .Icon {
    margin-right: -3px !important; }

.WrapperContent {
  position: relative; }
  .WrapperContent .Content {
    display: flex;
    padding: 0px 16px;
    height: 240px; }

.Open {
  animation: openSort 0.2s ease-out; }

.Closed {
  animation: closeSort 0.25s ease-out; }

@keyframes openSort {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translateY(0); } }

@keyframes closeSort {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(100%); } }
