/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  position: fixed;
  color: #202124;
  z-index: 700;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: auto; }
  .Wrapper .InquiryForm {
    margin: 16px;
    width: 600px;
    background: #FFFFFF;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px; }
    .Wrapper .InquiryForm .Header {
      position: relative;
      padding: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #E6E6E6; }
      .Wrapper .InquiryForm .Header .Title {
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500; }
      .Wrapper .InquiryForm .Header .Icon {
        cursor: pointer; }
    .Wrapper .InquiryForm .Body {
      padding: 24px; }
      .Wrapper .InquiryForm .Body .MultiColumn {
        display: flex; }
      .Wrapper .InquiryForm .Body .Input {
        position: relative;
        margin-bottom: 24px;
        flex: 1;
        margin-right: 24px; }
        .Wrapper .InquiryForm .Body .Input label {
          display: block;
          font-size: 1.4rem;
          margin-bottom: 8px; }
        .Wrapper .InquiryForm .Body .Input .Inline {
          display: flex; }
          .Wrapper .InquiryForm .Body .Input .Inline .DialCode {
            width: 110px;
            margin-right: 8px; }
          .Wrapper .InquiryForm .Body .Input .Inline .Phone {
            flex: 1; }
      .Wrapper .InquiryForm .Body .Input:last-child {
        margin-right: 0; }
    .Wrapper .InquiryForm .Footer {
      border-top: 1px solid #E6E6E6;
      display: flex;
      justify-content: center;
      padding: 24px; }
      .Wrapper .InquiryForm .Footer .Action {
        width: 200px; }

@keyframes openForm {
  0% {
    opacity: 0;
    transform: translateY(-20%);
    -webkit-transform: translateY(-20%);
    -moz-transform: translateY(-20%);
    -ms-transform: translateY(-20%);
    -o-transform: translateY(-20%); }
  30% {
    opacity: .3;
    transform: translateY(-5%);
    -webkit-transform: translateY(-5%);
    -moz-transform: translateY(-5%);
    -ms-transform: translateY(-5%);
    -o-transform: translateY(-5%); }
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0); } }

@keyframes closeForm {
  0% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(-20%);
    -webkit-transform: translateY(-20%);
    -moz-transform: translateY(-20%);
    -ms-transform: translateY(-20%);
    -o-transform: translateY(-20%); } }

.Open {
  animation: openForm 0.3s;
  -webkit-animation: openForm 0.3s;
  -moz-animation: openForm 0.3s;
  -ms-transform: openForm 0.3s;
  -o-transform: openForm 0.3s; }

.Close {
  animation: closeForm 0.3s;
  -webkit-animation: closeForm 0.3s;
  -moz-animation: closeForm 0.3s;
  -ms-transform: closeForm 0.3s;
  -o-transform: closeForm 0.3s; }
