/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  position: fixed;
  color: #202124;
  z-index: 1000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center; }
  .Wrapper .Alert {
    width: 590px;
    background: #FFFFFF;
    padding: 72px 32px;
    text-align: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px; }
    .Wrapper .Alert .Icon {
      width: 150px;
      margin: 0 auto 18px; }
      .Wrapper .Alert .Icon img {
        width: 100%;
        display: block; }
    .Wrapper .Alert .Title {
      font-size: 2.4rem;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      margin-bottom: 12px; }
    .Wrapper .Alert .Description {
      margin-bottom: 16px;
      font-size: 1.4rem;
      line-height: 20px;
      color: #c2c2c2; }
    .Wrapper .Alert .Action .SingleBtn {
      width: 210px;
      margin: 0 auto; }

@media (max-width: 768px) {
  .Wrapper .Alert .Icon {
    width: 100px !important; }
  .Wrapper .Alert .Title {
    font-size: 2rem; }
  .Wrapper .Alert .Description {
    font-size: 1.3rem; } }
