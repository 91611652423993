/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  width: 100%;
  position: relative;
  background: #FAFAFA;
  min-height: 100vh; }
  .Wrapper .Header {
    left: 0;
    top: 0;
    width: 100%; }
  .Wrapper .Step {
    position: sticky;
    z-index: 2;
    top: 0;
    box-shadow: 0px 2px 2px rgba(106, 110, 115, 0.1);
    -webkit-box-shadow: 0px 2px 2px rgba(106, 110, 115, 0.1);
    -moz-box-shadow: 0px 2px 2px rgba(106, 110, 115, 0.1);
    -ms-transform: 0px 2px 2px rgba(106, 110, 115, 0.1);
    -o-transform: 0px 2px 2px rgba(106, 110, 115, 0.1); }
  .Wrapper .Content {
    position: relative;
    padding: 20px 20px 40px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box; }
    .Wrapper .Content .SubTitle {
      font-size: 1.5rem;
      margin-top: 4px;
      color: #202124;
      margin-bottom: 12px;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
    .Wrapper .Content .Card {
      font-size: 1.4rem;
      display: flex;
      padding: 20px 16px;
      align-items: center;
      justify-content: space-between;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      color: #202124; }
  .Wrapper .Loading {
    background: #FAFAFA;
    min-height: 100vh !important;
    margin-top: -100px;
    display: flex;
    align-items: center; }
  .Wrapper .Footer {
    padding: 16px;
    width: 100%;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(32, 33, 36, 0.1);
    -webkit-box-shadow: 0 1px 8px rgba(32, 33, 36, 0.1);
    -moz-box-shadow: 0 1px 8px rgba(32, 33, 36, 0.1);
    -ms-transform: 0 1px 8px rgba(32, 33, 36, 0.1);
    -o-transform: 0 1px 8px rgba(32, 33, 36, 0.1); }
    .Wrapper .Footer .PriceWrapper .Label {
      font-size: 1.2rem; }
    .Wrapper .Footer .PriceWrapper .Price {
      margin-top: 6px;
      font-size: 1.6rem;
      color: #CD2033;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
      .Wrapper .Footer .PriceWrapper .Price .Perpax {
        color: #6A6E73;
        font-size: 1.2rem;
        font-family: "Geomanist-Regular", sans-serif; }

.Open {
  animation: openPopup 0.2s ease-out; }

.Closed {
  animation: closePopup 0.2s ease-out; }

@keyframes openPopup {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(0); } }

@keyframes closePopup {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(100%); } }
