table {
    font-size: 1.4rem;
    line-height: 1.6em;
    width: 100%;
    margin-block-start: .5em;
    margin-block-end: .5em;
    border-collapse: collapse;
    tfoot {
        border-top: 1px solid $c-grey-light;
        @include font-weight-300();
        color: $c-black;
    }

    tr {
        td {
            padding: 6px;
        }
    }
}