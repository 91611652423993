/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .Container {
  width: 960px;
  margin: 0 auto;
  width: 984px; }
  .Wrapper .Container .Title {
    font-size: 3.2rem;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    text-align: center;
    margin-bottom: 16px;
    color: #202124; }
  .Wrapper .Container .SubTitle {
    text-align: center;
    margin-bottom: 48px; }
  .Wrapper .Container .List {
    width: 100%; }
    .Wrapper .Container .List .Item {
      margin: 12px;
      min-height: 450px;
      outline: 0;
      position: relative; }
      .Wrapper .Container .List .Item .RibbonWrapper {
        position: absolute;
        width: 100%;
        top: 32px;
        z-index: 1;
        margin-left: -10px; }

.DotWrapper {
  position: absolute;
  bottom: -32px;
  z-index: 300; }

.Arrow {
  z-index: 1;
  position: absolute;
  top: 50%;
  cursor: pointer; }
  .Arrow.PrevArrow {
    left: -62px;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg); }
    .Arrow.PrevArrow svg {
      margin-top: -75%; }
  .Arrow.NextArrow {
    right: -62px;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg); }
    .Arrow.NextArrow svg {
      margin-top: -75%; }
