/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 655;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: #FAFAFA; }
  .Wrapper .Header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2; }
  .Wrapper .Content {
    padding: 84px 16px 24px; }
    .Wrapper .Content .GuestWrapper {
      margin-bottom: 24px; }
      .Wrapper .Content .GuestWrapper .SubTitle {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        font-size: 1.5rem;
        color: #202124;
        margin-bottom: 16px; }
      .Wrapper .Content .GuestWrapper .Panel .Form {
        padding: 16px;
        margin: 0;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box; }
        .Wrapper .Content .GuestWrapper .Panel .Form .Label {
          display: block;
          font-size: 1.3rem;
          margin-bottom: 8px;
          color: #202124; }
          .Wrapper .Content .GuestWrapper .Panel .Form .Label.Visa {
            margin-bottom: 0px; }
        .Wrapper .Content .GuestWrapper .Panel .Form .Row {
          width: 100%;
          list-style: none;
          margin-bottom: 24px; }
          .Wrapper .Content .GuestWrapper .Panel .Form .Row .Inline {
            margin-top: 24px;
            margin-bottom: -24px !important;
            display: flex; }
          .Wrapper .Content .GuestWrapper .Panel .Form .Row .Input.SmallWidth {
            width: 85px; }
          .Wrapper .Content .GuestWrapper .Panel .Form .Row .Input.MediumWidth {
            width: 150px; }
          .Wrapper .Content .GuestWrapper .Panel .Form .Row .Input.LargeWidth {
            flex-grow: 1;
            margin-bottom: 24px; }
          .Wrapper .Content .GuestWrapper .Panel .Form .Row.MultiColumn {
            display: flex;
            align-items: flex-end; }
        .Wrapper .Content .GuestWrapper .Panel .Form hr.passportLineBreak {
          border-top: 1px solid #FAFAFA; }
        .Wrapper .Content .GuestWrapper .Panel .Form .Row:last-child {
          margin-bottom: 0; }

.Open {
  animation: openPopup 0.3s; }

.Closed {
  animation: closePopup 0.4s; }

@keyframes openPopup {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(0); } }

@keyframes closePopup {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(100%); } }
