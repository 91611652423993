/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* The switch - the box around the slider */
.Switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  cursor: pointer; }
  .Switch .Input {
    opacity: 0;
    width: 0;
    height: 0; }
  .Switch .Input:checked + .Slider {
    background-color: #CD2033;
    border: 1px solid #e44631; }
  .Switch .Input:checked + .Slider:before {
    transform: translateX(18px);
    -webkit-transform: translateX(18px);
    -moz-transform: translateX(18px);
    -ms-transform: translateX(18px);
    -o-transform: translateX(18px); }

/* The slider */
.Slider {
  position: absolute;
  top: 2px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c2c2c2;
  border: 1px solid #c2c2c2;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s; }

.Slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  background-color: white;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s; }

/* Rounded sliders */
.Round {
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px; }

.Round:before {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%; }
