/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  padding: 60px 0;
  margin-bottom: 40px;
  min-width: 1080px; }
  .Wrapper .Container {
    width: 960px;
    margin: 0 auto;
    width: 984px; }
    .Wrapper .Container .List {
      width: 100%; }
      .Wrapper .Container .List .Item {
        margin: 12px;
        flex: 1;
        -moz-flex: 1;
        -webkit-flex: 1; }
      .Wrapper .Container .List .Item:focus {
        outline: 0; }
    .Wrapper .Container .Title {
      font-size: 2.4rem;
      color: #202124;
      text-align: center;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }

.DotWrapper {
  position: absolute;
  bottom: -32px;
  z-index: 300; }
  .DotWrapper li {
    width: 12px;
    height: 12px;
    margin: 2px 4px; }
    .DotWrapper li div {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: #E6E6E6; }

.Arrow {
  z-index: 1;
  position: absolute;
  top: 50%; }
  .Arrow.PrevArrow {
    cursor: pointer;
    left: -62px;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg); }
    .Arrow.PrevArrow svg {
      margin-top: -75%; }
  .Arrow.NextArrow {
    right: -62px;
    cursor: pointer;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg); }
    .Arrow.NextArrow svg {
      margin-top: -75%; }
