/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Panel {
  margin-bottom: 16px; }
  .Panel .Card {
    padding: 16px 16px 0px 16px; }
    .Panel .Card .Head {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .Panel .Card .Head .Text {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #6A6E73;
        font-size: 1.3rem; }
        .Panel .Card .Head .Text .Id {
          margin-top: 2px;
          margin-bottom: 0;
          color: #202124;
          margin-left: 6px;
          font-size: 1.3rem; }
      .Panel .Card .Head .Click {
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        color: #CD2033;
        font-size: 1.3rem; }
    .Panel .Card .Body {
      margin-top: 14px;
      display: flex;
      align-items: flex-start; }
      .Panel .Card .Body .Image {
        width: 60px;
        height: 60px;
        margin-right: 16px;
        position: relative;
        overflow: hidden; }
      .Panel .Card .Body .Info {
        flex: 1;
        margin-bottom: 12px; }
        .Panel .Card .Body .Info .Title {
          color: #202124;
          font-size: 1.5rem;
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500;
          margin-bottom: 12px; }
        .Panel .Card .Body .Info .Desc {
          color: #6A6E73;
          font-size: 1.3rem;
          margin-bottom: 12px; }
        .Panel .Card .Body .Info .Date {
          color: #202124;
          font-size: 1.3rem; }
    .Panel .Card.Overlay {
      background: #FFFFFF;
      opacity: 0.5; }
  .Panel .Foot {
    padding: 10px 16px;
    background: #FAFAFA;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: #73C700;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500; }
    .Panel .Foot .TextSuccess {
      margin-top: 2px;
      margin-left: 12px; }
      .Panel .Foot .TextSuccess.TextWaiting {
        display: flex;
        color: #F99E00; }
      .Panel .Foot .TextSuccess.TextFailed {
        color: #CD2033; }
    .Panel .Foot.Overlay {
      width: 100%;
      height: 100%;
      background: #FFFFFF;
      opacity: 0.5; }
