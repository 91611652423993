/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  padding: 16px; }
  .Wrapper .Content {
    border-bottom: thin solid #f5f5f5;
    padding: 16px; }
    .Wrapper .Content .Item .Label {
      font-weight: bold;
      color: #202124;
      padding-bottom: 10px;
      font-size: 1.3rem; }
    .Wrapper .Content .Item .Desc {
      font-size: 1.2rem;
      line-height: 1.5em; }
