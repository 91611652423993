/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Card {
  padding: 24px 16px; }
  .Card .Detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px; }
    .Card .Detail:last-child {
      margin-bottom: 0; }
    .Card .Detail .Title {
      color: rgba(106, 110, 115, 0.7);
      font-size: 1.4rem; }
      .Card .Detail .Title.Total {
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        color: #202124; }
    .Card .Detail .Value {
      color: #202124;
      font-size: 1.4rem; }
      .Card .Detail .Value.Center {
        margin-top: 2px; }
    .Card .Detail .Icon {
      margin-right: 12px; }
    .Card .Detail.Tour {
      margin: 0px; }
  .Card .CardContent {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px; }
    .Card .CardContent:last-child {
      margin-bottom: 0; }
    .Card .CardContent .Image {
      margin-right: 12px; }
    .Card .CardContent .Info {
      flex: 1; }
      .Card .CardContent .Info .Title {
        color: #202124;
        font-size: 1.4rem;
        margin-bottom: 8px;
        line-height: 1.4em;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500; }
      .Card .CardContent .Info .Desc {
        color: #6A6E73;
        font-size: 1.3rem; }
  .Card .Divider {
    margin-bottom: 12px;
    border-top: 1px solid #f5f5f5; }
