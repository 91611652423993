/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  position: fixed;
  color: #202124;
  z-index: 700;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .Wrapper .Alert {
    position: relative;
    z-index: 700;
    background: #FFFFFF;
    width: 100%;
    padding: 40px 24px;
    text-align: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px; }
    .Wrapper .Alert .Icon {
      width: 100px;
      margin: 0 auto 24px; }
      .Wrapper .Alert .Icon img {
        width: 100%;
        display: block; }
    .Wrapper .Alert .Title {
      font-size: 2rem;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      margin-bottom: 8px; }
    .Wrapper .Alert .Description {
      margin-bottom: 16px;
      font-size: 1.3rem;
      line-height: 1.4em;
      color: #6A6E73; }
    .Wrapper .Alert .Action .SingleBtn {
      width: 150px;
      margin: 0 auto; }

@keyframes openMsg {
  0% {
    opacity: 0;
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7); }
  100% {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1); } }

@keyframes closeMsg {
  0% {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7); } }

.Open {
  animation: openMsg 0.18s ease-out;
  -webkit-animation: openMsg 0.18s ease-out;
  -moz-animation: openMsg 0.18s ease-out;
  -ms-transform: openMsg 0.18s ease-out;
  -o-transform: openMsg 0.18s ease-out; }

.Close {
  animation: closeMsg 0.18s ease-out;
  -webkit-animation: closeMsg 0.18s ease-out;
  -moz-animation: closeMsg 0.18s ease-out;
  -ms-transform: closeMsg 0.18s ease-out;
  -o-transform: closeMsg 0.18s ease-out; }
