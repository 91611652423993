/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/

@mixin fadeInRightToLeft ($suffix:"") {
    @keyframes open#{$suffix} {
        0% {
            opacity: 1;
            transform: translateX(100%);
        }
        40% {
            opacity: 1;
            transform: translateX(-5%);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
    
    @keyframes close#{$suffix} {
        0% {
            opacity: 1;
            transform: translateX(0);
        }
        60% {
            opacity: 1;
            transform: translateX(-5%);
        }
        100% {
            opacity: 1;
            transform: translateX(100%);
        }
    }
}

@mixin collapse ($suffix:"") {
    @keyframes open#{$suffix} {
        0% {
            opacity: 0;
            @include transform(translateY(-30%));
        }
        30% {
            opacity: .3;
            @include transform(translateY(-5%));
        }
        100% {
            opacity: 1;
            @include transform(translateY(0));
        }
    }
    
    @keyframes close#{$suffix} {
        0% {
            opacity: 1;
            @include transform(translateY(0));
        }
        
        50% {
            opacity: 0;
            @include transform(translateY(-10%));
        }
        100% {
            opacity: 0;
            @include transform(translateY(-10%));
        }
        
    }
}

@mixin modal ($suffix:"") {
    @keyframes open#{$suffix} {
        0% {
            opacity: 0;
            @include transform(translateY(-20%));
        }
        30% {
            opacity: .3;
            @include transform(translateY(-5%));
        }
        100% {
            opacity: 1;
            @include transform(translateY(0));
        }
    }
    
    @keyframes close#{$suffix} {
        0% {
            opacity: 1;
            @include transform(translateY(0));
        }
        
        100% {
            opacity: 0;
            @include transform(translateY(-20%));
        }
        
    }
}

@mixin alert($suffix:"") {
    @keyframes open#{$suffix} {
        0% {
            opacity: 0;
            @include transform(scale(.7));
        }
        100% {
            opacity: 1;
            @include transform(scale(1));
        }
    }
    
    @keyframes close#{$suffix} {
        0% {
            opacity: 1;
            @include transform(scale(1));
        }
        100% {
            opacity: 0;
            @include transform(scale(.7));
        }
        
    }
}

@mixin errorMsg($suffix:"") {
    @keyframes open#{$suffix} {
        0% {
            opacity: 0;
            @include transform(translateY(-30%));
        }
        100% {
            opacity: 1;
            @include transform(translateY(0));
        }
    }
    
    @keyframes close#{$suffix} {
        0% {
            opacity: 1;
            @include transform(translateY(0));
        }
        100% {
            opacity: 0;
            @include transform(translateY(-30%));
        }
        
    }
}

@mixin popup ($suffix:"") {
    @keyframes open#{$suffix} {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(0);
        }
    }
    
    @keyframes close#{$suffix} {
        0% {
            transform: translateY(0);
        }
        100% {
            transform: translateY(100%);
        }
    }
}

@mixin page ($suffix:"") {
    @keyframes open#{$suffix} {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(0);
        }
    }
    
    @keyframes close#{$suffix} {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(100%);
        }
    }
}




// ============== new animation lib

@mixin zoomIn($suffix:"") {
    @keyframes open#{$suffix} {
        0% {
            opacity: 0;
            @include transform(scale(.7));
        }
        100% {
            opacity: 1;
            @include transform(scale(1));
        }
    }
    
    @keyframes close#{$suffix} {
        0% {
            opacity: 1;
            @include transform(scale(1));
        }
        100% {
            opacity: 0;
            @include transform(scale(.7));
        }
        
    }
}
@mixin zoomOut($suffix:"") {
    @keyframes open#{$suffix} {
        0% {
            opacity: 0;
            @include transform(scale(1.3));
        }
        100% {
            opacity: 1;
            @include transform(scale(1));
        }
    }
    
    @keyframes close#{$suffix} {
        0% {
            opacity: 1;
            @include transform(scale(1));
        }
        100% {
            opacity: 0;
            @include transform(scale(1.3));
        }
        
    }
}

@mixin slideInDown($suffix:"") {
    @keyframes open#{$suffix} {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(0);
        }
    }
    
    @keyframes close#{$suffix} {
        0% {
            transform: translateY(0);
        }
        100% {
            transform: translateY(100%);
        }
    }
}

@mixin slideInUp($suffix:"") {
    @keyframes open#{$suffix} {
        0% {
            transform: translateY(-100%);
        }
        100% {
            transform: translateY(0);
        }
    }
    
    @keyframes close#{$suffix} {
        0% {
            transform: translateY(0);
        }
        100% {
            transform: translateY(-100%);
        }
    }
}

@mixin slideInRight($suffix:"") {
    @keyframes open#{$suffix} {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(0);
        }
    }
    
    @keyframes close#{$suffix} {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(100%);
        }
    }
}
@mixin slideInLeft($suffix:"") {
    @keyframes open#{$suffix} {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(0);
        }
    }
    
    @keyframes close#{$suffix} {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-100%);
        }
    }
}

@mixin fadeInDown($suffix:"") {
    @keyframes open#{$suffix} {
        0% {
            opacity: 0;
            transform: translateY(100%);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
    
    @keyframes close#{$suffix} {
        0% {
            opacity: 1;
            transform: translateY(0);
        }
        100% {
            opacity: 0;
            transform: translateY(100%);
        }
    }
}

@mixin fadeInUp($suffix:"") {
    @keyframes open#{$suffix} {
        0% {
            opacity: 0;
            transform: translateY(-100%);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
    
    @keyframes close#{$suffix} {
        0% {
            opacity: 1;
            transform: translateY(0);
        }
        100% {
            opacity: 0;
            transform: translateY(-100%);
        }
    }
}

@mixin fadeInRight($suffix:"") {
    @keyframes open#{$suffix} {
        0% {
            opacity: 0;
            transform: translateX(100%);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
    
    @keyframes close#{$suffix} {
        0% {
            opacity: 1;
            transform: translateX(0);
        }
        100% {
            opacity: 0;
            transform: translateX(100%);
        }
    }
}
@mixin fadeInLeft($suffix:"") {
    @keyframes open#{$suffix} {
        0% {
            opacity: 0;
            transform: translateX(-100%);
        }
        100% {
            opacity: 0;
            transform: translateX(0);
        }
    }
    
    @keyframes close#{$suffix} {
        0% {
            opacity: 1;
            transform: translateX(0);
        }
        100% {
            opacity: 0;
            transform: translateX(-100%);
        }
    }
}

@mixin select ($suffix:"") {
    @keyframes open#{$suffix} {
        0% {
            opacity: 0;
            @include transform(translateY(-40px));
        }
        30% {
            opacity: .3;
            @include transform(translateY(-5px));
        }
        100% {
            opacity: 1;
            @include transform(translateY(0));
        }
    }
    
    @keyframes close#{$suffix} {
        0% {
            opacity: 1;
            @include transform(translateY(0));
        }
        
        50% {
            opacity: 0;
            @include transform(translateY(-10px));
        }
        100% {
            opacity: 0;
            @include transform(translateY(-10px));
        }
        
    }
}