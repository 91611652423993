/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  background: #FAFAFA;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  overflow: hidden;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .Wrapper .Head {
    padding: 24px 24px 0;
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem; }
    .Wrapper .Head .Title {
      color: #818589; }
      .Wrapper .Head .Title span {
        color: #202124;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500; }
    .Wrapper .Head .Link {
      color: #CD2033;
      cursor: pointer;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
    .Wrapper .Head .Link:hover {
      color: rgba(205, 32, 51, 0.8); }
  .Wrapper .Body {
    padding: 24px;
    display: flex; }
    .Wrapper .Body .Info {
      color: #202124;
      font-size: 1.4rem;
      line-height: 1.4em;
      display: flex;
      padding-right: 16px;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      flex: 5;
      -moz-flex: 5;
      -webkit-flex: 5; }
      .Wrapper .Body .Info .Text {
        margin-left: 16px;
        flex: 1;
        -moz-flex: 1;
        -webkit-flex: 1; }
      .Wrapper .Body .Info .Duration {
        font-family: "Geomanist-Regular", sans-serif;
        color: #818589;
        font-size: 1.2rem;
        margin-top: 8px; }
    .Wrapper .Body .Detail {
      flex: 5;
      -moz-flex: 5;
      -webkit-flex: 5;
      border-left: 1px solid #E6E6E6;
      padding-left: 16px; }
      .Wrapper .Body .Detail .Box {
        margin-bottom: 14px;
        color: #202124;
        font-size: 1.4rem; }
        .Wrapper .Body .Detail .Box:last-child {
          margin-bottom: 0; }
        .Wrapper .Body .Detail .Box .Label {
          font-family: "Geomanist-Regular", sans-serif;
          color: #818589;
          font-size: 1.2rem;
          margin-bottom: 4px; }
        .Wrapper .Body .Detail .Box .Value {
          min-height: 24px;
          display: flex;
          align-items: center; }
          .Wrapper .Body .Detail .Box .Value span {
            margin-top: 4px;
            margin-left: 6px; }
  .Wrapper .Footer {
    padding: 16px 24px;
    background: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .Wrapper .Footer .Label {
      font-family: "Geomanist-Regular", sans-serif;
      color: #818589;
      font-size: 1.4rem; }
      .Wrapper .Footer .Label span {
        font-size: 1.5rem;
        color: #202124;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        margin-left: 8px; }
