/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 58px;
  background: #CD2033;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  font-size: 1.8rem;
  padding: 0 15px;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500; }
  .Header.Shadow {
    box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.2);
    -webkit-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.2);
    -moz-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.2);
    -ms-transform: 0px 1px 4px rgba(32, 33, 36, 0.2);
    -o-transform: 0px 1px 4px rgba(32, 33, 36, 0.2); }
  .Header .Action {
    margin-right: 16px; }

.ContentWrapper {
  min-height: 100vh;
  padding-top: 58px;
  position: relative;
  background: #f5f5f5;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .ContentWrapper .Content {
    padding: 20px;
    position: relative; }
    .ContentWrapper .Content .AddLabel {
      display: flex;
      align-items: center;
      justify-content: center; }
    .ContentWrapper .Content .SectionTitle {
      color: #202124;
      font-size: 1.4rem;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      padding: 15px 0; }
    .ContentWrapper .Content .Section {
      margin-bottom: 20px; }
      .ContentWrapper .Content .Section .Body {
        padding: 20px 15px;
        display: flex;
        justify-content: space-between; }
        .ContentWrapper .Content .Section .Body .Label {
          color: #202124;
          font-size: 1.3rem;
          display: flex;
          align-items: center; }
          .ContentWrapper .Content .Section .Body .Label.Bold {
            font-family: "Geomanist-Medium", sans-serif;
            font-weight: 500; }
      .ContentWrapper .Content .Section .Delete {
        padding: 8px;
        background: #CD2033;
        border-radius: 25px; }
    .ContentWrapper .Content .SwiperContent {
      margin-bottom: 20px;
      background: #f5f5f5; }
      .ContentWrapper .Content .SwiperContent .Body {
        padding: 20px 15px;
        display: flex;
        justify-content: space-between; }
        .ContentWrapper .Content .SwiperContent .Body .Label {
          color: #202124;
          font-size: 1.3rem;
          display: flex;
          align-items: center; }
          .ContentWrapper .Content .SwiperContent .Body .Label.Bold {
            font-family: "Geomanist-Medium", sans-serif;
            font-weight: 500; }
      .ContentWrapper .Content .SwiperContent .Delete {
        padding: 8px;
        background: #CD2033;
        border-radius: 25px; }
