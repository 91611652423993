/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  position: relative; }
  .Wrapper .Label {
    text-transform: uppercase;
    font-size: 1.2rem;
    margin: 0 16px 8px;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    color: #202124; }
  .Wrapper .InputWrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center; }
    .Wrapper .InputWrapper .Input {
      margin: 0 8px 0 16px;
      color: #6A6E73;
      width: 100%;
      border: none;
      font-size: 1.4rem;
      outline: none;
      border-bottom: 1px solid #FFFFFF;
      transition: border 0.3s;
      -webkit-transition: border 0.3s;
      -moz-transition: border 0.3s;
      font-family: "Geomanist-Regular", sans-serif; }
    .Wrapper .InputWrapper .Input:focus {
      border-bottom: 1px solid #CD2033; }
    .Wrapper .InputWrapper .Icon {
      margin-right: 12px;
      transition: transform 0.3s;
      -webkit-transition: transform 0.3s;
      -moz-transition: transform 0.3s; }
      .Wrapper .InputWrapper .Icon.Active {
        transform: rotate(-180deg); }
  .Wrapper .DropdownList {
    position: absolute;
    width: 100%;
    top: 70px;
    max-height: 400px;
    min-width: 257px;
    z-index: 500; }
