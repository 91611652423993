/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  width: 100%;
  padding: 16px 0px 8px 21px;
  margin-bottom: 16px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-wrap: wrap; }
  .Wrapper .ProductItem {
    position: relative;
    margin: 5px 8px;
    width: 74px;
    height: 74px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    -ms-transform: 0px 4px 10px rgba(0, 0, 0, 0.1);
    -o-transform: 0px 4px 10px rgba(0, 0, 0, 0.1); }
    .Wrapper .ProductItem .Image img {
      width: 100%; }
    .Wrapper .ProductItem .Badges {
      font-size: 1rem;
      background: #73C700;
      color: #FFFFFF;
      position: absolute;
      top: -8px;
      right: -6px;
      padding: 4px 8px 2px;
      border-radius: 9px; }
  .Wrapper .Label {
    margin-top: 12px;
    margin-bottom: 21px;
    text-align: center;
    color: #202124;
    font-size: 1.2rem;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500; }

.DotWrapper {
  position: absolute;
  top: -20px;
  right: 24px;
  text-align: right;
  z-index: 300; }
  .DotWrapper li {
    width: 6px;
    height: 6px;
    margin: 2px 4px; }
    .DotWrapper li div {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: #E6E6E6; }

.Arrow {
  z-index: 1;
  position: absolute;
  top: 50%;
  cursor: pointer; }
  .Arrow.PrevArrow {
    left: -62px;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg); }
    .Arrow.PrevArrow svg {
      margin-top: -75%; }
  .Arrow.NextArrow {
    right: -62px;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg); }
    .Arrow.NextArrow svg {
      margin-top: -75%; }

.LoadingWrap {
  width: 220px;
  height: 288px;
  overflow: hidden;
  position: relative;
  margin: 8px 8px 16px;
  cursor: pointer;
  background: #f5f5f5;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px; }
