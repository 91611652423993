/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .Container {
  width: 960px;
  margin: 0 auto; }
  .Wrapper .Container .WrapperForm {
    background: #FFFFFF;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.08);
    -ms-transform: 0 5px 25px 0 rgba(0, 0, 0, 0.08);
    -o-transform: 0 5px 25px 0 rgba(0, 0, 0, 0.08);
    width: 100%;
    display: flex;
    padding: 16px 0 !important; }
    .Wrapper .Container .WrapperForm .InputWrapper {
      flex: 1;
      -moz-flex: 1;
      -webkit-flex: 1;
      border-right: 1px solid #E6E6E6; }
      .Wrapper .Container .WrapperForm .InputWrapper .InputText {
        position: relative; }
        .Wrapper .Container .WrapperForm .InputWrapper .InputText .Label {
          text-transform: uppercase;
          font-size: 1.2rem;
          margin: 0 16px;
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500;
          color: #202124;
          margin-bottom: 8px !important; }
        .Wrapper .Container .WrapperForm .InputWrapper .InputText .Input {
          display: flex;
          justify-content: space-between;
          position: relative;
          align-items: center; }
          .Wrapper .Container .WrapperForm .InputWrapper .InputText .Input input {
            margin: 0 16px;
            width: 100%;
            border: none;
            font-size: 1.4rem;
            outline: none;
            border-bottom: 1px solid #FFFFFF;
            transition: border 0.3s;
            -webkit-transition: border 0.3s;
            -moz-transition: border 0.3s;
            font-family: "Geomanist-Regular", sans-serif; }
          .Wrapper .Container .WrapperForm .InputWrapper .InputText .Input input:focus {
            border-bottom: 1px solid #CD2033; }
    .Wrapper .Container .WrapperForm .InputWrapper:last-child {
      border-right: none; }
