/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  padding: 16px;
  border-bottom: 1px solid #f5f5f5; }
  .Wrapper .Title {
    font-size: 1.4rem;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    color: #202124;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .Wrapper .Title .Clear {
      font-family: "Geomanist-Regular", sans-serif;
      font-weight: normal;
      font-size: 1.2rem;
      color: #6A6E73;
      cursor: pointer; }
  .Wrapper .List {
    margin: 0;
    padding: 0; }
    .Wrapper .List li {
      font-size: 1.2rem;
      list-style: none;
      margin-bottom: 16px; }
    .Wrapper .List li:last-child {
      margin-bottom: 0px; }
