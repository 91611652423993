/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 300px;
  height: 100%;
  background: #FFFFFF;
  z-index: 700;
  overflow: scroll; }
  .Wrapper .Header {
    position: fixed;
    width: 300px;
    display: flex;
    justify-content: center;
    padding: 16px 8px;
    align-items: center;
    background: #FFFFFF;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box; }
    .Wrapper .Header .Logo {
      height: 36px; }
    .Wrapper .Header .BurgerMenu {
      cursor: pointer;
      position: absolute;
      left: 16px;
      top: 18px; }
  .Wrapper .Content {
    padding-top: 60px; }
    .Wrapper .Content .Menu {
      padding: 16px 20px; }
      .Wrapper .Content .Menu .Label {
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        color: #202124;
        font-size: 1.5rem;
        margin-bottom: 8px; }
      .Wrapper .Content .Menu .List {
        margin: 0;
        padding: 0;
        font-size: 1.4rem; }
        .Wrapper .Content .Menu .List .Item {
          list-style: none;
          padding: 8px 0; }
          .Wrapper .Content .Menu .List .Item a {
            color: #6A6E73;
            text-decoration: none; }

@keyframes open {
  0% {
    transform: translateX(-100%); }
  100% {
    transform: translateX(0); } }

@keyframes close {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(-100%); } }

.Open {
  animation: open 0.3s ease-out; }

.Closed {
  animation: close 0.3s ease-out; }
