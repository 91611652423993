/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .Wrapper .Item {
    position: relative;
    padding: 20px 16px;
    cursor: pointer; }
    .Wrapper .Item .RibbonWrapper {
      position: absolute;
      width: 100%;
      top: 15px;
      z-index: 1;
      margin-left: -6px; }
    .Wrapper .Item .Title {
      color: #202124;
      font-size: 1.4rem;
      margin-bottom: 14px;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
    .Wrapper .Item .Info {
      display: flex;
      justify-content: space-between; }
      .Wrapper .Item .Info .Image {
        width: 100px;
        margin-right: 12px; }
      .Wrapper .Item .Info .Description {
        flex: 1; }
        .Wrapper .Item .Info .Description .DateWrap {
          font-size: 1.3rem;
          color: #202124;
          margin-bottom: 12px; }
          .Wrapper .Item .Info .Description .DateWrap .Label {
            font-size: 1.2rem;
            margin-bottom: 4px;
            color: #818589; }
          .Wrapper .Item .Info .Description .DateWrap .Date {
            display: flex;
            align-items: center; }
            .Wrapper .Item .Info .Description .DateWrap .Date .Icon {
              margin-right: 6px; }
        .Wrapper .Item .Info .Description .PriceWrap {
          display: flex;
          justify-content: space-between;
          align-items: center; }
          .Wrapper .Item .Info .Description .PriceWrap .Label {
            font-size: 1.2rem;
            color: #818589; }
          .Wrapper .Item .Info .Description .PriceWrap .Price {
            margin-top: 6px;
            font-size: 1.6rem;
            color: #CD2033;
            font-family: "Geomanist-Medium", sans-serif;
            font-weight: 500; }
