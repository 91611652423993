/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #FAFAFA;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px; }
  .Wrapper .Title {
    color: #202124;
    margin-bottom: 12px;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500; }
  .Wrapper .Address {
    font-size: 1.4rem;
    line-height: 20px;
    margin-bottom: 16px; }
  .Wrapper .Contact {
    flex: 1;
    align-items: flex-end;
    font-size: 1.4rem;
    display: flex;
    align-items: flex-end; }
    .Wrapper .Contact ul {
      margin: 0;
      padding: 0; }
      .Wrapper .Contact ul a {
        text-decoration: none; }
      .Wrapper .Contact ul .Item {
        margin-bottom: 8px;
        list-style: none; }
        .Wrapper .Contact ul .Item.Whatsapp {
          display: inline-flex;
          align-items: center;
          border: 1px solid #25d366;
          color: #25d366;
          padding: 4px 8px;
          cursor: pointer;
          border-radius: 16px;
          -webkit-border-radius: 16px;
          -moz-border-radius: 16px;
          transition: all 0.3s;
          -webkit-transition: all 0.3s;
          -moz-transition: all 0.3s; }
          .Wrapper .Contact ul .Item.Whatsapp .Label {
            margin-left: 6px;
            margin-top: 2px; }
        .Wrapper .Contact ul .Item.Whatsapp:hover {
          background: #25d366;
          color: #FFFFFF; }
          .Wrapper .Contact ul .Item.Whatsapp:hover svg g path {
            fill: #FFFFFF !important; }
      .Wrapper .Contact ul .Item:last-child {
        margin-bottom: 0; }
    .Wrapper .Contact span {
      margin-right: 16px;
      color: #202124;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
