/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  position: relative;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition: max-height 0.5s ease-out;
  -webkit-transition: max-height 0.5s ease-out;
  -moz-transition: max-height 0.5s ease-out; }
  .Wrapper.Less {
    max-height: 250px; }
  .Wrapper.More {
    padding-bottom: 64px !important;
    max-height: 1000px; }
  .Wrapper .Title {
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    color: #202124;
    margin-bottom: 16px;
    padding: 24px;
    border-bottom: thin solid #f0f0f0; }
  .Wrapper .List {
    padding: 0px 24px 16px; }
    .Wrapper .List .CollapseBtn {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(rgba(255, 255, 255, 0.4) 5%, white 50%);
      background: -moz-linear-gradient(rgba(255, 255, 255, 0.4) 5%, white 50%);
      background: -webkit-linear-gradient(rgba(255, 255, 255, 0.4) 5%, white 50%);
      background: -o-linear-gradient(rgba(255, 255, 255, 0.4) 5%, white 50%);
      background: -ms-linear-gradient(rgba(255, 255, 255, 0.4) 5%, white 50%);
      color: #1A6FA3;
      text-align: center;
      padding: 24px 0 16px;
      font-size: 1.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
      .Wrapper .List .CollapseBtn .ArrowUp {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg); }
