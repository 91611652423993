/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .Content {
  box-sizing: border-box; }
  .Wrapper .Content .Header {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    border-bottom: thin solid #f5f5f5; }
    .Wrapper .Content .Header .Left {
      display: flex;
      align-items: center; }
      .Wrapper .Content .Header .Left .WrapperLeft .Name {
        font-size: 1.4rem;
        font-weight: bold;
        padding-bottom: 10px;
        color: #202124; }
      .Wrapper .Content .Header .Left .WrapperLeft .Label {
        font-size: 1.3rem; }
  .Wrapper .Content .Footer {
    padding: 16px; }
    .Wrapper .Content .Footer .Detail {
      display: flex;
      justify-content: space-between;
      line-height: 2.3em; }
      .Wrapper .Content .Footer .Detail .Left {
        font-size: 1.4rem; }
      .Wrapper .Content .Footer .Detail .Right {
        color: #202124;
        font-size: 1.4rem; }
