/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  margin: 12px;
  min-height: 450px;
  outline: 0; }
  .Wrapper .Item {
    width: 304px;
    height: 450px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    box-shadow: 0 3px 10px 2px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 3px 10px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 3px 10px 2px rgba(0, 0, 0, 0.1);
    -ms-transform: 0 3px 10px 2px rgba(0, 0, 0, 0.1);
    -o-transform: 0 3px 10px 2px rgba(0, 0, 0, 0.1);
    background: #FAFAFA; }
