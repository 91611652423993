/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  margin-bottom: 100px; }
  .Wrapper .Container {
    width: 960px;
    margin: 0 auto; }
    .Wrapper .Container .RegionFilter {
      width: 100%;
      margin-top: 18px;
      position: sticky;
      top: 18px;
      z-index: 2; }
    .Wrapper .Container .Content {
      display: flex; }
      .Wrapper .Container .Content .Sidebar {
        width: 265px;
        margin-right: 16px; }
      .Wrapper .Container .Content .Availability {
        flex: 1;
        -moz-flex: 1;
        -webkit-flex: 1; }
        .Wrapper .Container .Content .Availability .Header {
          display: flex;
          align-items: center;
          margin-bottom: 18px; }
          .Wrapper .Container .Content .Availability .Header .Title {
            font-size: 2.0rem;
            font-family: "Geomanist-Medium", sans-serif;
            font-weight: 500;
            color: #202124;
            flex: 1;
            -moz-flex: 1;
            -webkit-flex: 1; }
          .Wrapper .Container .Content .Availability .Header .Sort {
            display: flex;
            align-items: center; }
            .Wrapper .Container .Content .Availability .Header .Sort label {
              font-size: 1.4rem;
              margin-right: 16px; }
