/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .FormWrapper {
  margin-top: 24px; }
  .Wrapper .FormWrapper .Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px; }
    .Wrapper .FormWrapper .Header .Title {
      font-size: 2.2rem;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      color: #202124; }
    .Wrapper .FormWrapper .Header .LabelHeader {
      font-size: 1.3rem;
      color: #6A6E73; }
      .Wrapper .FormWrapper .Header .LabelHeader .Link {
        margin-left: 4px;
        font-size: 1.3rem;
        cursor: pointer;
        color: #CD2033; }
  .Wrapper .FormWrapper .Input {
    margin-bottom: 24px; }
    .Wrapper .FormWrapper .Input .Label {
      display: block;
      font-size: 1.4rem;
      margin-bottom: 8px;
      color: #202124; }
    .Wrapper .FormWrapper .Input .Link {
      font-size: 1.3rem;
      cursor: pointer;
      color: #CD2033; }
    .Wrapper .FormWrapper .Input.Remember {
      display: flex;
      justify-content: space-between;
      align-items: center; }
  .Wrapper .FormWrapper .Sparator {
    margin: 32px 0;
    height: 1px;
    border-top: 1px solid #E6E6E6;
    display: flex;
    justify-content: center;
    align-items: center; }
    .Wrapper .FormWrapper .Sparator .Text {
      padding: 0 8px;
      position: relative;
      top: -1px;
      color: #6A6E73;
      font-size: 1.3rem;
      background: #FFFFFF; }
  .Wrapper .FormWrapper .SocmedLogin {
    display: flex;
    justify-content: space-between; }
    .Wrapper .FormWrapper .SocmedLogin .Socmed {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.3rem;
      padding: 10px 24px;
      overflow: hidden;
      cursor: pointer;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      flex: 1;
      -moz-flex: 1;
      -webkit-flex: 1;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px; }
      .Wrapper .FormWrapper .SocmedLogin .Socmed.FbBtn {
        color: #FFFFFF;
        margin-right: 6px;
        background: #4267B2; }
      .Wrapper .FormWrapper .SocmedLogin .Socmed.GoogleBtn {
        border: 1px solid #c2c2c2;
        color: #6A6E73;
        flex: 1;
        -moz-flex: 1;
        -webkit-flex: 1;
        margin-left: 6px; }
      .Wrapper .FormWrapper .SocmedLogin .Socmed .Icon {
        margin-right: 16px;
        width: 18px; }
