/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  width: 100%;
  margin: 0;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 600; }
  .Wrapper .PanelBox {
    box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.2);
    -webkit-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.2);
    -moz-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.2);
    -ms-transform: 0px 1px 4px rgba(32, 33, 36, 0.2);
    -o-transform: 0px 1px 4px rgba(32, 33, 36, 0.2);
    background: #FFFFFF;
    padding: 8px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box; }
  .Wrapper .Item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .Wrapper .Item .Title {
      font-size: 1.1rem;
      margin: 0px;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
    .Wrapper .Item.Selected {
      color: #202124; }
      .Wrapper .Item.Selected svg g {
        fill: #CD2033; }
        .Wrapper .Item.Selected svg g path {
          fill: #CD2033; }
