/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.List {
  margin: 0;
  padding: 0;
  width: 100%; }
  .List .Item {
    display: flex;
    justify-content: space-between;
    color: #202124;
    font-size: 1.4rem;
    padding: 8px 0; }
