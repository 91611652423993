/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  padding: 0;
  margin: 0; }
  .Wrapper .Item {
    padding-left: 16px;
    list-style: none;
    margin-bottom: 12px;
    font-size: 1.4rem;
    line-height: 1.4em;
    color: #6A6E73; }
  .Wrapper .Item:last-child {
    margin-bottom: 0; }
  .Wrapper .Item::before {
    content: '';
    width: 8px;
    height: 8px;
    background: #6A6E73;
    position: relative;
    margin-right: 8px;
    margin-left: -16px;
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%; }
  .Wrapper .ListSmall::before {
    width: 5px !important;
    height: 5px !important; }
  .Wrapper .ListGreen::before {
    content: '';
    background: #73C700 !important;
    position: relative;
    margin-right: 8px;
    margin-left: -16px;
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%; }
  .Wrapper .ListRed::before {
    content: '';
    background: #CD2033 !important;
    position: relative;
    margin-right: 8px;
    margin-left: -16px;
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%; }
