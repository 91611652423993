/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  position: relative;
  display: flex;
  align-items: center; }
  .Wrapper .Select {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%; }
    .Wrapper .Select .Input {
      flex: 1; }
      .Wrapper .Select .Input .Select {
        width: 100%;
        border: 1px solid #E6E6E6;
        color: #6A6E73;
        padding: 12px 24px 12px 16px;
        font-size: 1.4rem;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        transition: border .3s, color .3s;
        -webkit-transition: border .3s, color .3s;
        -moz-transition: border .3s, color .3s;
        font-family: "Geomanist-Regular", sans-serif; }
        .Wrapper .Select .Input .Select.HasError {
          border: 1px solid #e44631 !important;
          color: #e44631; }
        .Wrapper .Select .Input .Select.HasError::placeholder {
          color: #e44631; }
      .Wrapper .Select .Input .Select:focus {
        outline: none;
        border: 1px solid #6A6E73; }
      .Wrapper .Select .Input .Select::placeholder {
        color: #c2c2c2; }
    .Wrapper .Select .ErrorMsg {
      color: #CD2033;
      font-size: 1.2rem;
      position: absolute;
      left: 0;
      bottom: -16px; }
    .Wrapper .Select .Icon {
      position: absolute;
      right: 6px;
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out; }
      .Wrapper .Select .Icon.IsActive {
        transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        -o-transform: rotate(-180deg); }
  .Wrapper .List {
    width: 100%;
    padding: 0px;
    margin: 0;
    position: absolute;
    background: #FFFFFF;
    box-sizing: border-box;
    top: 108%;
    font-size: 1.3rem;
    color: #202124;
    z-index: 500;
    max-height: 200px;
    overflow: auto;
    border: 1px solid rgba(230, 230, 230, 0.5);
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.05);
    -ms-transform: 0 1px 4px 1px rgba(0, 0, 0, 0.05);
    -o-transform: 0 1px 4px 1px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px; }
    .Wrapper .List .Item {
      padding: 12px 16px;
      list-style: none;
      display: flex;
      align-content: center;
      cursor: pointer; }
    .Wrapper .List .Item:hover {
      background: #FAFAFA; }
    .Wrapper .List .Item:first-child {
      margin-top: 8px; }
    .Wrapper .List .Item:last-child {
      margin-bottom: 8px; }

@keyframes openDropdown {
  0% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px); }
  30% {
    opacity: .3;
    transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px); }
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0); } }

@keyframes closeDropdown {
  0% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0); }
  50% {
    opacity: 0;
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px); }
  100% {
    opacity: 0;
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px); } }

.Open {
  animation: openDropdown 0.3s ease-out;
  -webkit-animation: openDropdown 0.3s ease-out;
  -moz-animation: openDropdown 0.3s ease-out;
  -ms-transform: openDropdown 0.3s ease-out;
  -o-transform: openDropdown 0.3s ease-out; }

.Close {
  animation: closeDropdown 0.3s ease-out;
  -webkit-animation: closeDropdown 0.3s ease-out;
  -moz-animation: closeDropdown 0.3s ease-out;
  -ms-transform: closeDropdown 0.3s ease-out;
  -o-transform: closeDropdown 0.3s ease-out; }
