/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Content {
  padding: 107px 20px 0px;
  position: relative;
  z-index: 1;
  background: #f5f5f5;
  display: flex;
  flex-direction: column; }
  .Content .Panel {
    margin-top: 16px;
    margin-bottom: 32px; }
    .Content .Panel .Card {
      padding: 0px 16px 0px 16px; }
      .Content .Panel .Card .CardContent {
        margin-top: 16px;
        margin-bottom: 16px; }
        .Content .Panel .Card .CardContent .Info .Title {
          color: #202124;
          font-size: 1.5rem;
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500;
          margin-bottom: 12px; }
        .Content .Panel .Card .CardContent .Info .Desc {
          color: #6A6E73;
          font-size: 1.3rem;
          line-height: 1.5em; }
        .Content .Panel .Card .CardContent .Info .Tip {
          background: #f5f5f5;
          padding: 8px;
          line-height: 1.5em;
          margin-top: 8px; }
          .Content .Panel .Card .CardContent .Info .Tip .ContentTip {
            display: flex;
            justify-content: space-between;
            color: #6A6E73;
            font-size: 1.3rem; }
            .Content .Panel .Card .CardContent .Info .Tip .ContentTip .Money {
              display: flex; }
              .Content .Panel .Card .CardContent .Info .Tip .ContentTip .Money .TotalTip {
                color: #202124;
                margin-left: 18px; }
            .Content .Panel .Card .CardContent .Info .Tip .ContentTip .Total {
              font-family: "Geomanist-Medium", sans-serif;
              font-weight: 500;
              color: #202124; }
        .Content .Panel .Card .CardContent .Info ul {
          padding-left: 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start; }
          .Content .Panel .Card .CardContent .Info ul li {
            color: #6A6E73;
            margin-bottom: 10px;
            line-height: 1.5em;
            font-size: 1.3rem; }
            .Content .Panel .Card .CardContent .Info ul li:last-child {
              margin-bottom: 0px; }
    .Content .Panel .Divider {
      border-top: 1px solid #E6E6E6; }
      .Content .Panel .Divider.DivTip {
        margin-top: 8px;
        margin-bottom: 8px; }
