/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  margin-bottom: 8px;
  overflow: hidden; }
  .Wrapper .Container {
    width: 100%; }
    .Wrapper .Container .Heading {
      padding: 8px 16px;
      display: flex;
      justify-content: space-between;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      font-size: 1.8rem;
      color: #202124;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box; }
      .Wrapper .Container .Heading .More {
        color: #CD2033;
        font-size: 1.4rem; }
    .Wrapper .Container .SubTitle {
      text-align: center;
      margin-bottom: 48px; }
    .Wrapper .Container .List {
      width: 100%;
      margin-left: 8px; }
      .Wrapper .Container .List .Item {
        margin: 8px 8px 16px;
        min-height: 300px;
        outline: 0;
        position: relative; }
        .Wrapper .Container .List .Item .Ribbon {
          width: 100%;
          position: absolute;
          top: 16px;
          left: -10px;
          z-index: 1; }

.DotWrapper {
  position: absolute;
  top: -20px;
  right: 24px;
  text-align: right;
  z-index: 300; }
  .DotWrapper li {
    width: 6px;
    height: 6px;
    margin: 2px 4px; }
    .DotWrapper li div {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: #E6E6E6; }

.Arrow {
  z-index: 1;
  position: absolute;
  top: 50%;
  cursor: pointer; }
  .Arrow.PrevArrow {
    left: -62px;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg); }
    .Arrow.PrevArrow svg {
      margin-top: -75%; }
  .Arrow.NextArrow {
    right: -62px;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg); }
    .Arrow.NextArrow svg {
      margin-top: -75%; }
