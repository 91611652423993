/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px; }
  .Content .Image {
    padding-top: 42px;
    max-width: 400px;
    display: block; }
    .Content .Image img {
      display: table;
      margin: 0 auto;
      width: 85%; }
  .Content .Heading {
    font-size: 1.8rem;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    color: #202124;
    margin-bottom: 15px;
    margin-top: 28px; }
    .Content .Heading span {
      color: #CD2033; }
  .Content .Desc {
    font-size: 1.4rem;
    margin-bottom: 10px;
    padding: 0 75px;
    line-height: 2.3rem;
    text-align: center; }
  .Content .BtnWrapper {
    width: 100%;
    margin-top: 25px; }
