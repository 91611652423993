/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Header {
  position: fixed;
  z-index: 1;
  width: 100%; }
  .Header .Title {
    color: #FFFFFF; }

.Content {
  position: relative;
  padding: 84px 20px 20px;
  color: #202124;
  background: #FAFAFA;
  min-height: 100vh;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .Content .Panel {
    margin-bottom: 24px; }
    .Content .Panel .Card {
      padding: 20px; }
  .Content .Title {
    font-size: 2rem;
    line-height: 1.2em;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    margin: 0px 0px; }
  .Content .SubTitle {
    color: #6A6E73; }
  .Content .Input {
    margin-bottom: 30px; }
    .Content .Input .Label {
      display: block;
      font-size: 1.4rem;
      margin-bottom: 8px; }
  .Content .Image {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box; }
    .Content .Image img {
      width: 100%; }

.WrapperPop {
  position: fixed;
  color: #202124;
  z-index: 700;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .WrapperPop .Alert {
    position: relative;
    z-index: 700;
    background: #FFFFFF;
    width: 100%;
    padding: 16px 16px;
    text-align: center;
    margin-bottom: 16px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px; }
    .WrapperPop .Alert .Icon {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 0 auto 0px; }
    .WrapperPop .Alert .Title {
      font-size: 2rem;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      margin-bottom: 8px; }
    .WrapperPop .Alert .Description {
      margin-bottom: 16px;
      font-size: 1.3rem;
      line-height: 1.4em;
      color: #818589; }
  .WrapperPop .Action {
    color: #f0f0f0;
    font-size: 1.3rem; }

@keyframes openMsg {
  0% {
    opacity: 0;
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7); }
  100% {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1); } }

@keyframes closeMsg {
  0% {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7); } }

.Open {
  animation: openMsg 0.18s ease-out;
  -webkit-animation: openMsg 0.18s ease-out;
  -moz-animation: openMsg 0.18s ease-out;
  -ms-transform: openMsg 0.18s ease-out;
  -o-transform: openMsg 0.18s ease-out; }

.Close {
  animation: closeMsg 0.18s ease-out;
  -webkit-animation: closeMsg 0.18s ease-out;
  -moz-animation: closeMsg 0.18s ease-out;
  -ms-transform: closeMsg 0.18s ease-out;
  -o-transform: closeMsg 0.18s ease-out; }
