/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  min-height: 100vh;
  background: #FAFAFA; }
  .Wrapper .HeaderWrapper {
    height: 92px;
    overflow: hidden;
    background: #FFFFFF;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-shadow: 0px 2px 6px rgba(32, 33, 36, 0.1);
    -webkit-box-shadow: 0px 2px 6px rgba(32, 33, 36, 0.1);
    -moz-box-shadow: 0px 2px 6px rgba(32, 33, 36, 0.1);
    -ms-transform: 0px 2px 6px rgba(32, 33, 36, 0.1);
    -o-transform: 0px 2px 6px rgba(32, 33, 36, 0.1); }
    .Wrapper .HeaderWrapper .LabelList {
      margin-left: 20px;
      width: 100%;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box; }
      .Wrapper .HeaderWrapper .LabelList .ItemWrapper {
        margin-right: 20px; }
        .Wrapper .HeaderWrapper .LabelList .ItemWrapper .Item {
          margin-right: 8px;
          text-align: center;
          font-size: 1.2rem;
          padding: 6px 10px;
          background: #f5f5f5;
          border-radius: 12px;
          -webkit-border-radius: 12px;
          -moz-border-radius: 12px; }
          .Wrapper .HeaderWrapper .LabelList .ItemWrapper .Item.Active {
            background: #1A6FA3 !important;
            color: #FFFFFF !important; }
      .Wrapper .HeaderWrapper .LabelList .ItemWrapper:focus {
        outline: none; }
      .Wrapper .HeaderWrapper .LabelList .ItemWrapperLoader {
        width: 100% !important;
        height: 20px !important; }
  .Wrapper .Content {
    padding: 112px 20px 56px;
    position: relative;
    z-index: 1; }
  .Wrapper .SortFilter {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    text-align: center;
    bottom: 0; }
