/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .Slider .Page {
  position: absolute;
  right: 10px;
  top: 15px;
  z-index: 500;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  background: rgba(32, 33, 36, 0.5);
  padding: 4px 8px;
  font-size: 1.2rem;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px; }
  .Wrapper .Slider .Page .PageNumber {
    margin-top: 2px;
    text-align: right;
    width: 25px; }

.ImgError {
  width: 100%;
  height: 270px;
  border-bottom: 1px solid #f0f0f0;
  overflow: hidden; }

.DotWrapper {
  position: absolute;
  bottom: 0;
  z-index: 300; }
  .DotWrapper li {
    width: 2px; }
    .DotWrapper li div {
      margin: 2px auto;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #c2c2c2; }
