/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  width: 163px;
  height: 163px;
  margin: 8px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px; }
  .Wrapper .Img {
    position: absolute;
    left: 50%;
    top: 50%;
    height: auto;
    width: 100%;
    transition: height 0.2s;
    -webkit-transition: height 0.2s;
    -moz-transition: height 0.2s;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%); }
  .Wrapper .Description {
    bottom: 0;
    padding: 42px 16px 16px;
    position: absolute;
    color: #FFFFFF;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background: linear-gradient(rgba(0, 0, 0, 0) 5%, black 90%);
    background: -moz-linear-gradient(rgba(0, 0, 0, 0) 5%, black 90%);
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0) 5%, black 90%);
    background: -o-linear-gradient(rgba(0, 0, 0, 0) 5%, black 90%);
    background: -ms-linear-gradient(rgba(0, 0, 0, 0) 5%, black 90%); }
    .Wrapper .Description .Title {
      font-size: 1.6rem;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      color: #FFFFFF;
      margin-bottom: 8px; }
    .Wrapper .Description .Subtitle {
      font-size: 1.2rem;
      color: #FFFFFF; }

@media (max-width: 360px) {
  .Wrapper {
    width: 156px;
    height: 156px; } }
