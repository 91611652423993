/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  position: relative;
  padding: 16px;
  display: flex;
  align-items: stretch;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .Wrapper .RibbonWrapper {
    width: 100%;
    position: absolute;
    top: 28px;
    left: 6px;
    z-index: 1; }
  .Wrapper .Image {
    width: 216px;
    height: 159px;
    overflow: hidden;
    position: relative;
    margin-right: 16px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
    -ms-transform: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
    -o-transform: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px; }
    .Wrapper .Image img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 110%;
      min-height: auto;
      transition: width 0.2s;
      -webkit-transition: width 0.2s;
      -moz-transition: width 0.2s;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%); }
  .Wrapper .Description {
    display: flex;
    flex-flow: wrap;
    flex: 1;
    -moz-flex: 1;
    -webkit-flex: 1; }
    .Wrapper .Description .Title {
      color: #202124;
      margin-bottom: 13px;
      width: 100%;
      text-decoration: none;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
      .Wrapper .Description .Title:hover {
        color: #CD2033; }
    .Wrapper .Description .SubTitle {
      width: 100%;
      color: #CD2033;
      font-size: 1.2rem;
      margin-bottom: 16px; }
    .Wrapper .Description .Label {
      font-size: 1.2rem;
      margin-bottom: 4px; }
    .Wrapper .Description .Includes {
      display: flex; }
      .Wrapper .Description .Includes .Item {
        margin-right: 8px; }
    .Wrapper .Description .PriceAction {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end; }
      .Wrapper .Description .PriceAction .Price {
        font-size: 2.4rem;
        color: #CD2033;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500; }

.Wrapper:hover .Image img {
  width: 130%; }
