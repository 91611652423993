/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  width: 312px !important;
  min-height: 228px;
  border-radius: 8px;
  position: relative;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-shadow: 0px 2px 8px 2px rgba(32, 33, 36, 0.1);
  -webkit-box-shadow: 0px 2px 8px 2px rgba(32, 33, 36, 0.1);
  -moz-box-shadow: 0px 2px 8px 2px rgba(32, 33, 36, 0.1);
  -ms-transform: 0px 2px 8px 2px rgba(32, 33, 36, 0.1);
  -o-transform: 0px 2px 8px 2px rgba(32, 33, 36, 0.1); }
  .Wrapper::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: #FAFAFA;
    top: 60px;
    left: -10px;
    z-index: 1;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    box-shadow: inset -12px 0px 4px -10px rgba(106, 110, 115, 0.1);
    -webkit-box-shadow: inset -12px 0px 4px -10px rgba(106, 110, 115, 0.1);
    -moz-box-shadow: inset -12px 0px 4px -10px rgba(106, 110, 115, 0.1);
    -ms-transform: inset -12px 0px 4px -10px rgba(106, 110, 115, 0.1);
    -o-transform: inset -12px 0px 4px -10px rgba(106, 110, 115, 0.1); }
  .Wrapper::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: #FAFAFA;
    top: 60px;
    right: -10px;
    z-index: 1;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    box-shadow: inset 12px 0px 4px -10px rgba(106, 110, 115, 0.1);
    -webkit-box-shadow: inset 12px 0px 4px -10px rgba(106, 110, 115, 0.1);
    -moz-box-shadow: inset 12px 0px 4px -10px rgba(106, 110, 115, 0.1);
    -ms-transform: inset 12px 0px 4px -10px rgba(106, 110, 115, 0.1);
    -o-transform: inset 12px 0px 4px -10px rgba(106, 110, 115, 0.1); }
  .Wrapper .Image {
    width: 100%;
    height: 130px;
    background: #FAFAFA;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    position: relative; }
    .Wrapper .Image img {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: auto;
      height: 100%;
      transition: height 0.2s;
      -webkit-transition: height 0.2s;
      -moz-transition: height 0.2s;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%); }
  .Wrapper .Body {
    padding: 16px;
    min-height: 107px;
    max-height: 107px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box; }
    .Wrapper .Body .Benefit {
      font-size: 1.4rem;
      color: #E6E6E6;
      display: flex;
      margin-bottom: 8px; }
      .Wrapper .Body .Benefit.Active {
        color: #202124; }
      .Wrapper .Body .Benefit .Text {
        margin-left: 8px;
        margin-top: 2px; }

.DotWrapper {
  position: absolute;
  top: -20px;
  right: 24px;
  text-align: right;
  z-index: 300; }
  .DotWrapper li {
    width: 6px;
    height: 6px;
    margin: 2px 4px; }
    .DotWrapper li div {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: #E6E6E6; }

.Arrow {
  z-index: 1;
  position: absolute;
  top: 50%;
  cursor: pointer; }
  .Arrow.PrevArrow {
    left: -62px;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg); }
    .Arrow.PrevArrow svg {
      margin-top: -75%; }
  .Arrow.NextArrow {
    right: -62px;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg); }
    .Arrow.NextArrow svg {
      margin-top: -75%; }

.LoadingWrap {
  width: 220px;
  height: 288px;
  overflow: hidden;
  position: relative;
  margin: 8px 8px 16px;
  cursor: pointer;
  background: #f5f5f5;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px; }
