/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .FormWrapper {
  margin-top: 24px; }
  .Wrapper .FormWrapper .Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px; }
    .Wrapper .FormWrapper .Header .Title {
      font-size: 2.2rem;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      color: #202124; }
    .Wrapper .FormWrapper .Header .LabelHeader {
      font-size: 1.3rem;
      color: #6A6E73; }
      .Wrapper .FormWrapper .Header .LabelHeader .Link {
        margin-left: 4px;
        font-size: 1.3rem;
        cursor: pointer;
        color: #CD2033; }
  .Wrapper .FormWrapper .Input {
    margin-bottom: 32px; }
    .Wrapper .FormWrapper .Input .Label {
      display: block;
      font-size: 1.4rem;
      margin-bottom: 8px;
      color: #202124;
      line-height: 2.5rem;
      margin-bottom: 10px; }
