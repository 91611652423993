/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  position: fixed;
  bottom: 32px;
  right: 32px;
  background: #25d366;
  z-index: 1;
  height: 48px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  font-size: 1.8rem;
  text-decoration: none;
  cursor: pointer;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  box-shadow: 0 3px 10px 2px rgba(32, 33, 36, 0.2);
  -webkit-box-shadow: 0 3px 10px 2px rgba(32, 33, 36, 0.2);
  -moz-box-shadow: 0 3px 10px 2px rgba(32, 33, 36, 0.2);
  -ms-transform: 0 3px 10px 2px rgba(32, 33, 36, 0.2);
  -o-transform: 0 3px 10px 2px rgba(32, 33, 36, 0.2); }
  .Wrapper .Label {
    margin-top: 4px;
    margin-left: 8px; }
