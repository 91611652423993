/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  margin-bottom: 200px; }
  .Wrapper .Container {
    width: 960px;
    margin: 0 auto; }
    .Wrapper .Container .Content {
      width: 100%; }
      .Wrapper .Container .Content .Clear {
        clear: both; }
      .Wrapper .Container .Content .ItemWrapper {
        position: relative;
        width: 984px;
        height: 100px;
        margin-left: -24px;
        display: block; }
        .Wrapper .Container .Content .ItemWrapper .Item {
          position: relative;
          width: 304px;
          margin-left: 24px;
          margin-bottom: 24px;
          float: left; }
          .Wrapper .Container .Content .ItemWrapper .Item .ItemBox {
            position: relative;
            width: 100%;
            box-sizing: border-box;
            padding-top: 10px; }
            .Wrapper .Container .Content .ItemWrapper .Item .ItemBox:hover .Image img {
              height: 120%; }
            .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .RibbonWrapper {
              width: 100%;
              position: absolute;
              top: 24px;
              left: 0;
              z-index: 1; }
            .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Image {
              width: 285px;
              height: 285px;
              border-radius: 12px;
              margin: 0 auto;
              overflow: hidden;
              position: relative; }
              .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Image img {
                overflow: hidden;
                position: absolute;
                top: 50%;
                left: 50%;
                min-width: auto;
                height: 110%;
                transition: height 0.2s;
                -webkit-transition: height 0.2s;
                -moz-transition: height 0.2s;
                transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%); }
            .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Body {
              padding: 16px; }
              .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Body .Date {
                margin-bottom: 16px; }
                .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Body .Date .Label {
                  font-size: 1.2rem;
                  margin: 8px 0px;
                  color: #6A6E73; }
                .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Body .Date .Detail {
                  font-size: 1.4rem;
                  color: #202124;
                  display: flex;
                  align-items: center;
                  flex-wrap: wrap; }
                  .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Body .Date .Detail .Icon {
                    margin-top: -3px;
                    padding-right: 4px; }
            .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Footer {
              padding: 10px;
              display: flex;
              align-items: flex-end; }
              .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Footer .ItemPrice {
                flex: 1; }
                .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Footer .ItemPrice .Label {
                  font-size: 1.2rem;
                  color: #6A6E73;
                  margin-bottom: 5px; }
                .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Footer .ItemPrice .Price {
                  font-size: 2rem;
                  color: #CD2033;
                  font-family: "Geomanist-Medium", sans-serif;
                  font-weight: 500; }
              .Wrapper .Container .Content .ItemWrapper .Item .ItemBox .Footer .ItemBtn {
                flex: 1; }
