.ui-tab {
    margin: 0;
    padding: 0;
    display: flex;
    border-bottom: 1px solid $c-white-dark-1;
    .tab-item {
        list-style: none;
        padding: 12px 16px;
        font-size: 1.3rem;
        border-bottom: 2px solid rgba($c-red, 0);
        @include bold();
        @include transition(all .3s);
        cursor: pointer;
        &.active {
            color: $c-red;
            border-bottom: 2px solid $c-red;
        }
    }
    .tab-item:hover {
        color: $c-red;
        border-bottom: 2px solid $c-red;
    }
}