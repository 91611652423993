/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  position: relative;
  background: linear-gradient(135deg, #38A8D0 0%, #1A6FA3 100%);
  margin-bottom: 60px; }
  .Wrapper .Container {
    width: 100%;
    min-height: 300px;
    width: 764px;
    margin: 0 auto;
    padding: 60px 40px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px; }
    .Wrapper .Container .Title {
      font-size: 24px;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
    .Wrapper .Container .List {
      margin: 0;
      padding: 0; }
      .Wrapper .Container .List li {
        list-style: none;
        display: flex;
        margin-bottom: 40px; }
        .Wrapper .Container .List li .Icon {
          width: 100px;
          height: 100px;
          background: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
          margin-right: 60px; }
        .Wrapper .Container .List li .Content {
          flex: 1;
          color: #FFFFFF;
          display: flex;
          align-items: center; }
          .Wrapper .Container .List li .Content .Title {
            font-size: 2rem;
            line-height: 2em;
            font-family: "Geomanist-Medium", sans-serif;
            font-weight: 500; }
          .Wrapper .Container .List li .Content .Description {
            font-size: 1.6rem;
            line-height: 1.6em; }
      .Wrapper .Container .List li:last-child {
        margin-bottom: 0; }
