/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.HeaderWrapper {
  height: 100px;
  overflow: hidden;
  background: #FFFFFF;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
  -webkit-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
  -moz-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
  -ms-transform: 0px 1px 4px rgba(32, 33, 36, 0.05);
  -o-transform: 0px 1px 4px rgba(32, 33, 36, 0.05); }
  .HeaderWrapper .Header {
    padding: 16px;
    display: flex;
    justify-content: space-between; }
    .HeaderWrapper .Header .ActionTitle {
      display: flex;
      align-items: center; }
      .HeaderWrapper .Header .ActionTitle .Title {
        margin-left: 20px;
        font-size: 1.8rem;
        color: #202124;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500; }
    .HeaderWrapper .Header .Download {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #FFFFFF;
      background: #CD2033;
      text-decoration: none;
      font-size: 1.1rem;
      padding: 6px 12px 4px;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      transition: background 0.3s;
      -webkit-transition: background 0.3s;
      -moz-transition: background 0.3s;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px; }
    .HeaderWrapper .Header .Download:hover {
      background: #CD2033;
      color: #FFFFFF; }
  .HeaderWrapper .LabelList {
    padding: 4px 0 12px 20px;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box; }
    .HeaderWrapper .LabelList .ItemWrapper {
      margin-right: 20px;
      display: flex;
      justify-content: center; }
      .HeaderWrapper .LabelList .ItemWrapper .Item {
        margin-right: 8px;
        text-align: center;
        font-size: 1.2rem;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        padding: 8px 10px;
        background: #f5f5f5;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px; }
        .HeaderWrapper .LabelList .ItemWrapper .Item.Active {
          background: #1A6FA3 !important;
          color: #FFFFFF !important; }
    .HeaderWrapper .LabelList .ItemWrapper:focus {
      outline: none; }
    .HeaderWrapper .LabelList .ItemWrapperLoader {
      width: 100% !important;
      height: 20px !important; }
