/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .SectionList {
  margin: 0;
  padding: 0; }
  .Wrapper .SectionList .Item {
    list-style: none;
    border-bottom: 1px solid #f0f0f0; }
    .Wrapper .SectionList .Item.Padding {
      padding: 16px 24px; }
    .Wrapper .SectionList .Item .Wrapper {
      display: flex;
      justify-content: space-between; }
      .Wrapper .SectionList .Item .Wrapper .Image {
        position: relative;
        width: 100%;
        height: 127px; }
        .Wrapper .SectionList .Item .Wrapper .Image .ImgWrap {
          width: 100%;
          height: 127px;
          margin: 0 auto;
          position: relative;
          overflow: hidden;
          border-top-left-radius: 8px;
          -webkit-border-top-left-radius: 8px;
          -moz-border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          -webkit-border-top-right-radius: 8px;
          -moz-border-top-right-radius: 8px; }
          .Wrapper .SectionList .Item .Wrapper .Image .ImgWrap img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transition: height 0.2s;
            -webkit-transition: height 0.2s;
            -moz-transition: height 0.2s;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%); }
        .Wrapper .SectionList .Item .Wrapper .Image::before {
          content: '';
          width: 20px;
          height: 20px;
          background: #FAFAFA;
          position: absolute;
          z-index: 1;
          top: 50%;
          left: -10px;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          box-shadow: inset -12px 0px 4px -10px rgba(106, 110, 115, 0.1);
          -webkit-box-shadow: inset -12px 0px 4px -10px rgba(106, 110, 115, 0.1);
          -moz-box-shadow: inset -12px 0px 4px -10px rgba(106, 110, 115, 0.1);
          -ms-transform: inset -12px 0px 4px -10px rgba(106, 110, 115, 0.1);
          -o-transform: inset -12px 0px 4px -10px rgba(106, 110, 115, 0.1); }
        .Wrapper .SectionList .Item .Wrapper .Image::after {
          content: '';
          width: 20px;
          height: 20px;
          background: #FAFAFA;
          position: absolute;
          z-index: 1;
          top: 50%;
          right: -10px;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          box-shadow: inset 12px 0px 4px -10px rgba(106, 110, 115, 0.1);
          -webkit-box-shadow: inset 12px 0px 4px -10px rgba(106, 110, 115, 0.1);
          -moz-box-shadow: inset 12px 0px 4px -10px rgba(106, 110, 115, 0.1);
          -ms-transform: inset 12px 0px 4px -10px rgba(106, 110, 115, 0.1);
          -o-transform: inset 12px 0px 4px -10px rgba(106, 110, 115, 0.1); }
      .Wrapper .SectionList .Item .Wrapper .Info {
        flex: 1;
        margin-right: 16px; }
        .Wrapper .SectionList .Item .Wrapper .Info .Title {
          color: #202124;
          margin-top: 24px;
          line-height: 1.6em;
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500; }
        .Wrapper .SectionList .Item .Wrapper .Info .Desc {
          font-size: 1.4rem; }
    .Wrapper .SectionList .Item .Body .Benefit {
      font-size: 1.4rem;
      color: #E6E6E6;
      display: flex;
      margin-bottom: 8px; }
      .Wrapper .SectionList .Item .Body .Benefit.Active {
        color: #202124; }
      .Wrapper .SectionList .Item .Body .Benefit .Text {
        margin-left: 8px;
        margin-top: 2px; }
    .Wrapper .SectionList .Item .Body .Date {
      font-size: 1.4rem; }
      .Wrapper .SectionList .Item .Body .Date .Label {
        font-size: 1.2rem;
        margin: 8px 0px;
        color: #6A6E73; }
      .Wrapper .SectionList .Item .Body .Date .Detail {
        color: #202124;
        display: flex;
        align-items: center;
        flex-wrap: wrap; }
        .Wrapper .SectionList .Item .Body .Date .Detail .Icon {
          margin-top: -3px;
          padding-right: 4px; }
    .Wrapper .SectionList .Item .PriceWrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0px;
      font-size: 1.6rem;
      color: #202124;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
      .Wrapper .SectionList .Item .PriceWrap .Price {
        color: #CD2033;
        font-size: 2.4rem; }
      .Wrapper .SectionList .Item .PriceWrap .Pax {
        font-size: 1.4rem;
        color: #6A6E73;
        font-family: "Geomanist-Regular", sans-serif; }
  .Wrapper .SectionList .Item:last-child {
    border-bottom: none; }
