/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .Title {
  padding: 8px 16px 4px;
  font-size: 1.6rem;
  color: #202124;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500; }

.Wrapper .LabelList {
  margin: 0;
  padding: 12px 0 12px 16px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .Wrapper .LabelList .ItemWrapper {
    margin-right: 20px; }
    .Wrapper .LabelList .ItemWrapper .Item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      text-align: center;
      font-size: 1.1rem;
      padding: 0 10px;
      height: 46px;
      background: #f5f5f5;
      color: #202124;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px; }
      .Wrapper .LabelList .ItemWrapper .Item.Active {
        background: #1A6FA3 !important;
        color: #FFFFFF !important; }
      .Wrapper .LabelList .ItemWrapper .Item .Top {
        font-size: 1.4rem;
        margin-bottom: 4px;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500; }
      .Wrapper .LabelList .ItemWrapper .Item .TextSmall {
        font-size: 1.1rem;
        font-family: "Geomanist-Regular", sans-serif; }
  .Wrapper .LabelList .ItemWrapper:focus {
    outline: none; }
