/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  display: flex;
  align-items: center; }
  .Wrapper .Text {
    margin-top: 2px;
    margin-left: 6px;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500; }
    .Wrapper .Text.Mobile {
      font-size: 1.2rem; }
    .Wrapper .Text.Warning {
      color: #F99E00; }
    .Wrapper .Text.Success {
      color: #73C700; }
    .Wrapper .Text.Danger {
      color: #CD2033; }
    .Wrapper .Text.Default {
      color: #c2c2c2; }
