/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  background: #FFFFFF;
  border-bottom: 1px solid #f0f0f0; }
  .Wrapper .Container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1080px;
    margin: 0 auto; }
    .Wrapper .Container .Logo {
      cursor: pointer; }
      .Wrapper .Container .Logo img {
        max-height: 40px;
        display: block; }
    .Wrapper .Container .NavWrapper {
      display: flex; }
      .Wrapper .Container .NavWrapper .Nav {
        display: flex;
        font-size: 1.4rem;
        margin: 0;
        padding: 0;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500; }
        .Wrapper .Container .NavWrapper .Nav .Item {
          display: flex;
          align-items: center;
          list-style: none;
          cursor: pointer;
          transition: color 0.3s;
          -webkit-transition: color 0.3s;
          -moz-transition: color 0.3s; }
          .Wrapper .Container .NavWrapper .Nav .Item a {
            text-decoration: none;
            color: #6A6E73;
            display: flex;
            align-items: center;
            padding: 0 20px;
            height: 70px;
            transition: color 0.3s;
            -webkit-transition: color 0.3s;
            -moz-transition: color 0.3s; }
          .Wrapper .Container .NavWrapper .Nav .Item a:hover {
            color: #202124; }
          .Wrapper .Container .NavWrapper .Nav .Item.Link {
            height: 70px;
            padding: 0 20px; }
          .Wrapper .Container .NavWrapper .Nav .Item.Link:hover {
            color: #202124; }
