/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  padding: 20px 0; }
  .Wrapper .BreadcrumbList {
    display: flex;
    padding: 0;
    margin: 0;
    font-size: 1.4rem; }
    .Wrapper .BreadcrumbList li {
      display: flex;
      align-items: center;
      list-style: none;
      color: #6A6E73; }
      .Wrapper .BreadcrumbList li a {
        text-decoration: none;
        color: #202124; }
      .Wrapper .BreadcrumbList li a:hover {
        color: #202124; }
    .Wrapper .BreadcrumbList li:not(:first-child)::before {
      background-image: url("../../../../assets/images/ico-arrow-right.png");
      background-repeat: no-repeat;
      background-size: 16px 14px;
      width: 16px;
      height: 14px;
      margin: 1px 10px;
      display: inline-flex;
      content: "";
      opacity: .7; }
