/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .Header {
  position: fixed;
  top: 0;
  left: 0;
  padding: 16px;
  width: 100%;
  z-index: 500;
  display: flex;
  align-items: center;
  color: #202124;
  z-index: 2;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .Wrapper .Header .Icon {
    cursor: pointer;
    margin-right: 8px; }
  .Wrapper .Header .Title {
    margin-top: 4px; }
  .Wrapper .Header.SecondNav {
    opacity: 0;
    background: #FFFFFF;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -webkit-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -moz-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -ms-transform: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -o-transform: 0px 1px 4px rgba(32, 33, 36, 0.05); }
  .Wrapper .Header.Show {
    opacity: 1; }

.Wrapper .Content {
  position: relative;
  z-index: 1;
  background: #FFFFFF; }
  .Wrapper .Content .Title {
    text-align: center;
    font-size: 2rem;
    color: #202124;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500; }
  .Wrapper .Content .SubTitle {
    font-size: 1.6rem;
    line-height: 1.6em;
    color: #202124;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500; }
  .Wrapper .Content .Description {
    font-size: 1.4rem;
    line-height: 1.8em; }
    .Wrapper .Content .Description ul {
      padding: 8px 0;
      margin: 0; }
      .Wrapper .Content .Description ul li {
        list-style: inside; }
  .Wrapper .Content .TextContent {
    z-index: 1;
    background: #FFFFFF;
    position: relative;
    padding: 16px;
    margin: -50px 16px 0;
    border-top-left-radius: 16px;
    -webkit-border-top-left-radius: 16px;
    -moz-border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    -webkit-border-top-right-radius: 16px;
    -moz-border-top-right-radius: 16px; }
  .Wrapper .Content .Section {
    padding: 32px 16px; }
    .Wrapper .Content .Section.BgBlue {
      background: linear-gradient(135deg, #38A8D0 0%, #1A6FA3 100%); }
    .Wrapper .Content .Section .Journey {
      width: 100%;
      min-height: 300px;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      margin: 0;
      padding: 32px 8px 0; }
      .Wrapper .Content .Section .Journey li {
        list-style: none;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end; }
        .Wrapper .Content .Section .Journey li .Item {
          box-sizing: border-box;
          width: 100%;
          position: relative;
          padding-bottom: 24px;
          border-left: 1px solid #c2c2c2; }
          .Wrapper .Content .Section .Journey li .Item .DotWrapper {
            position: absolute;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background: rgba(205, 32, 51, 0.15);
            display: flex;
            justify-content: center;
            align-items: center;
            top: -4px;
            left: -9px; }
            .Wrapper .Content .Section .Journey li .Item .DotWrapper .Dot {
              width: 12px;
              height: 12px;
              background: #CD2033;
              border-radius: 50%; }
          .Wrapper .Content .Section .Journey li .Item .JourneyContent {
            border-radius: 8px;
            box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.08);
            padding: 16px;
            box-sizing: border-box;
            text-align: left;
            font-size: 1.2rem;
            color: #202124;
            margin-left: 24px;
            font-family: "Geomanist-Medium", sans-serif;
            font-weight: 500;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box; }
            .Wrapper .Content .Section .Journey li .Item .JourneyContent .Year {
              font-size: 1.4rem;
              margin-bottom: 8px;
              color: #CD2033;
              font-family: "Geomanist-Medium", sans-serif;
              font-weight: 500; }
      .Wrapper .Content .Section .Journey li:last-child .Item {
        border-left: none; }
    .Wrapper .Content .Section .Vision {
      margin: 0;
      padding: 32px 0 0; }
      .Wrapper .Content .Section .Vision li {
        list-style: none;
        display: inline-flex;
        margin-bottom: 16px; }
        .Wrapper .Content .Section .Vision li .Icon {
          width: 60px;
          height: 60px;
          background: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
          margin-right: 24px; }
          .Wrapper .Content .Section .Vision li .Icon img {
            width: 42px; }
        .Wrapper .Content .Section .Vision li .VisionContent {
          flex: 1;
          color: #FFFFFF; }
      .Wrapper .Content .Section .Vision li:last-child {
        margin-bottom: 0; }
