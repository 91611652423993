/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  width: 80px;
  height: 80px;
  position: relative;
  overflow: hidden;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .Wrapper.Circle {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%; }
  .Wrapper.Square {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px; }
  .Wrapper.Small {
    width: 60px;
    height: 60px; }
  .Wrapper.Medium {
    width: 80px;
    height: 80px; }
  .Wrapper.Large {
    width: 100px;
    height: 100px; }
  .Wrapper .Img {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    width: auto;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%); }
