/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  background: #202124;
  padding: 20px;
  color: #f0f0f0; }
  .Wrapper .ContactUs {
    border-bottom: 1px solid #6A6E73; }
    .Wrapper .ContactUs .Title {
      font-size: 1.6rem;
      margin-bottom: 16px;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
    .Wrapper .ContactUs .List {
      padding: 0;
      margin: 0 0 0 0; }
      .Wrapper .ContactUs .List .Item {
        font-size: 1.5rem;
        display: flex;
        list-style: none;
        align-items: flex-start;
        margin-bottom: 12px; }
        .Wrapper .ContactUs .List .Item .Icon {
          margin-right: 8px; }
        .Wrapper .ContactUs .List .Item .Label {
          margin-top: 6px;
          line-height: 1.4em; }
          .Wrapper .ContactUs .List .Item .Label .Address {
            margin-bottom: 16px; }
    .Wrapper .ContactUs .SocmedLink {
      display: flex; }
      .Wrapper .ContactUs .SocmedLink li {
        list-style: none;
        padding: 0 16px; }
    .Wrapper .ContactUs .DownloadBadge {
      display: flex;
      margin-bottom: 30px;
      justify-content: space-between; }
      .Wrapper .ContactUs .DownloadBadge .PlayStore {
        max-width: 400px;
        display: flex; }
        .Wrapper .ContactUs .DownloadBadge .PlayStore img {
          width: 95%; }
      .Wrapper .ContactUs .DownloadBadge .AppStore {
        max-width: 400px;
        display: flex;
        justify-content: flex-end; }
        .Wrapper .ContactUs .DownloadBadge .AppStore img {
          width: 95%; }
  .Wrapper .Copyright {
    margin-top: 16px;
    font-size: 1.2rem;
    text-align: center; }
