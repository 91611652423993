/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  width: 100%; }
  .Wrapper .Header {
    padding: 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f0f0f0; }
    .Wrapper .Header .Day {
      padding: 5px 10px;
      background: #1A6FA3;
      color: #FFFFFF;
      font-size: 1.2rem;
      margin-right: 12px;
      cursor: pointer;
      transition: opacity 0.3s;
      -webkit-transition: opacity 0.3s;
      -moz-transition: opacity 0.3s;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px; }
    .Wrapper .Header .Title {
      flex: 1;
      -moz-flex: 1;
      -webkit-flex: 1;
      color: #202124;
      line-height: 1.3em;
      margin-top: 4px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      font-size: 1.3rem;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
      .Wrapper .Header .Title .Arrow {
        transition: transform 0.3s;
        -webkit-transition: transform 0.3s;
        -moz-transition: transform 0.3s; }
        .Wrapper .Header .Title .Arrow.Active {
          transform: rotate(-180deg);
          -webkit-transform: rotate(-180deg);
          -moz-transform: rotate(-180deg);
          -ms-transform: rotate(-180deg);
          -o-transform: rotate(-180deg); }
  .Wrapper .Body {
    padding: 16px; }
    .Wrapper .Body .Flight {
      border: 1px solid #f0f0f0;
      padding: 8px 16px;
      margin-bottom: 16px;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px; }
      .Wrapper .Body .Flight .FlightDesc {
        margin-left: 16px; }
        .Wrapper .Body .Flight .FlightDesc .FlightTitle {
          color: #202124;
          font-size: 1.3rem;
          line-height: 1.4em;
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500; }
        .Wrapper .Body .Flight .FlightDesc .FlightSubTitle {
          font-size: 1.2rem; }
    .Wrapper .Body .Description {
      font-size: 1.3rem;
      line-height: 1.6em;
      margin-bottom: 12px; }
    .Wrapper .Body .Facilities {
      padding: 0;
      margin: 0;
      display: flex;
      font-size: 1.3rem;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
      .Wrapper .Body .Facilities li {
        margin-right: 12px;
        display: flex;
        align-items: center; }

@keyframes openDropdown {
  0% {
    opacity: 0;
    transform: translateY(-30%);
    -webkit-transform: translateY(-30%);
    -moz-transform: translateY(-30%);
    -ms-transform: translateY(-30%);
    -o-transform: translateY(-30%); }
  30% {
    opacity: .3;
    transform: translateY(-5%);
    -webkit-transform: translateY(-5%);
    -moz-transform: translateY(-5%);
    -ms-transform: translateY(-5%);
    -o-transform: translateY(-5%); }
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0); } }

@keyframes closeDropdown {
  0% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0); }
  50% {
    opacity: 0;
    transform: translateY(-10%);
    -webkit-transform: translateY(-10%);
    -moz-transform: translateY(-10%);
    -ms-transform: translateY(-10%);
    -o-transform: translateY(-10%); }
  100% {
    opacity: 0;
    transform: translateY(-10%);
    -webkit-transform: translateY(-10%);
    -moz-transform: translateY(-10%);
    -ms-transform: translateY(-10%);
    -o-transform: translateY(-10%); } }

.Open {
  animation: openDropdown 0.3s ease-out;
  -webkit-animation: openDropdown 0.3s ease-out;
  -moz-animation: openDropdown 0.3s ease-out;
  -ms-transform: openDropdown 0.3s ease-out;
  -o-transform: openDropdown 0.3s ease-out; }

.Close {
  animation: closeDropdown 0.3s ease-out;
  -webkit-animation: closeDropdown 0.3s ease-out;
  -moz-animation: closeDropdown 0.3s ease-out;
  -ms-transform: closeDropdown 0.3s ease-out;
  -o-transform: closeDropdown 0.3s ease-out; }
