/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .Title {
  color: #202124;
  padding: 24px;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500; }

.Wrapper .Slider {
  position: relative; }
  .Wrapper .Slider .Page {
    position: absolute;
    right: 10px;
    top: 15px;
    z-index: 500;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    background: rgba(32, 33, 36, 0.5);
    padding: 4px 8px;
    font-size: 1.2rem;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px; }
    .Wrapper .Slider .Page .PageNumber {
      margin-top: 2px;
      text-align: right;
      width: 25px; }

.Arrow {
  z-index: 500;
  position: absolute;
  top: 40%;
  cursor: pointer;
  width: 24px;
  height: 50px;
  background: #FFFFFF; }
  .Arrow.PrevArrow {
    left: 0px;
    border-top-right-radius: 8px;
    -webkit-border-top-right-radius: 8px;
    -moz-border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    -webkit-border-bottom-right-radius: 8px;
    -moz-border-bottom-right-radius: 8px; }
    .Arrow.PrevArrow svg {
      margin-top: -50%; }
  .Arrow.NextArrow {
    right: 0;
    border-top-left-radius: 8px;
    -webkit-border-top-left-radius: 8px;
    -moz-border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    -webkit-border-bottom-left-radius: 8px;
    -moz-border-bottom-left-radius: 8px; }
    .Arrow.NextArrow svg {
      margin-top: 14px; }
  .Arrow .LeftIcon {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg); }
  .Arrow .RightIcon {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg); }
