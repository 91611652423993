/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  position: fixed;
  z-index: 700;
  background: #FFFFFF;
  width: 100%;
  left: 0;
  bottom: 0;
  overflow: hidden;
  border-top-left-radius: 16px;
  -webkit-border-top-left-radius: 16px;
  -moz-border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  -webkit-border-top-right-radius: 16px;
  -moz-border-top-right-radius: 16px; }
  .Wrapper .Header {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    border: 1px solid #f5f5f5; }
    .Wrapper .Header .Close {
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      cursor: pointer; }
    .Wrapper .Header .Title {
      color: #202124;
      font-size: 1.6rem;
      margin-left: 16px;
      margin-top: 4px;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
  .Wrapper .Content {
    padding: 20px; }

.Open {
  animation: openSort 0.2s ease-out; }

.Closed {
  animation: closeSort 0.25s ease-out; }

@keyframes openSort {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translateY(0); } }

@keyframes closeSort {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(100%); } }
