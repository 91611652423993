/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .Head {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500; }
  .Wrapper .Head .Title {
    font-size: 2rem;
    color: #202124; }
  .Wrapper .Head .BackBtn {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    color: #1A6FA3;
    cursor: pointer; }
    .Wrapper .Head .BackBtn span {
      margin-left: 12px; }
  .Wrapper .Head .BackBtn:hover {
    opacity: .8; }

.Wrapper .Section .Label {
  font-size: 1.4rem;
  color: #818589;
  margin-bottom: 12px; }

.Wrapper .Section .Box {
  background: #FAFAFA;
  color: #202124;
  margin-bottom: 24px;
  padding: 14px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px; }
  .Wrapper .Section .Box .Value {
    display: flex;
    align-items: center;
    min-height: 28px; }
    .Wrapper .Section .Box .Value .Placeholder {
      color: #c2c2c2; }
    .Wrapper .Section .Box .Value .Password::before {
      content: "\2022 \2022 \2022 \2022 \2022 \2022 \2022 \2022 \2022 \2022 \2022 \2022"; }
    .Wrapper .Section .Box .Value span {
      margin-left: 24px;
      display: flex;
      align-items: center;
      color: #202124;
      font-size: 1.3rem;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
      .Wrapper .Section .Box .Value span svg {
        margin-right: 4px; }
      .Wrapper .Section .Box .Value span.Unverified {
        color: #c2c2c2; }
        .Wrapper .Section .Box .Value span.Unverified svg g path {
          fill: #c2c2c2; }
        .Wrapper .Section .Box .Value span.Unverified svg g path:last-child {
          fill: #FFFFFF; }
  .Wrapper .Section .Box .Action {
    display: flex;
    align-items: center; }
    .Wrapper .Section .Box .Action .Link {
      margin-left: 24px;
      color: #CD2033;
      cursor: pointer;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
    .Wrapper .Section .Box .Action .Link:hover {
      opacity: .8; }

.InputWrapper {
  margin-bottom: 24px; }
  .InputWrapper .Label {
    display: flex;
    font-size: 1.4rem;
    line-height: 1.6em;
    color: #202124;
    margin-bottom: 8px; }
  .InputWrapper .Input {
    display: flex;
    align-items: center;
    justify-content: center; }
    .InputWrapper .Input .Link {
      text-align: center;
      font-size: 1.2rem;
      color: #CD2033;
      cursor: pointer; }

.InputWrapper:last-child {
  margin-bottom: 0; }
