/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  background: #FFFFFF;
  padding: 60px 0;
  margin-bottom: 40px; }
  .Wrapper .Container {
    width: 1080px;
    margin: 0 auto; }
    .Wrapper .Container .Title {
      font-size: 2.4rem;
      color: #202124;
      text-align: center;
      margin-bottom: 40px;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
    .Wrapper .Container .List {
      display: flex;
      justify-content: center; }
      .Wrapper .Container .List table {
        color: #202124;
        border-collapse: collapse;
        width: 90%;
        font-size: 1.6rem; }
        .Wrapper .Container .List table .Head .Logo {
          display: flex;
          align-items: flex-start; }
        .Wrapper .Container .List table .Head .Col1 {
          width: 25%; }
        .Wrapper .Container .List table .Head .Col2 {
          width: 25%; }
        .Wrapper .Container .List table .Head .Col3 {
          width: 25%; }
        .Wrapper .Container .List table .Head th {
          padding: 16px;
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500; }
        .Wrapper .Container .List table .Head:hover {
          background-color: #FFFFFF; }
        .Wrapper .Container .List table .Item .Item1 {
          font-family: 'Geomanist-Book'; }
        .Wrapper .Container .List table .Item td {
          padding: 16px; }
          .Wrapper .Container .List table .Item td .StarContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center; }
        .Wrapper .Container .List table .Item .Center {
          text-align: center; }
        .Wrapper .Container .List table .Item .WhatInclude {
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500;
          font-size: 2rem;
          padding-top: 26px; }
          .Wrapper .Container .List table .Item .WhatInclude:hover {
            background-color: #FFFFFF; }
        .Wrapper .Container .List table .Item:hover {
          background-color: #FAFAFA; }
        .Wrapper .Container .List table .ButtonItem td .Label {
          color: #818589;
          font-size: 1.2rem;
          letter-spacing: 0;
          line-height: 18px;
          text-align: center;
          margin-top: 16px; }
