/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Panel {
  margin-top: 16px;
  margin-bottom: 0px; }
  .Panel .Card {
    padding: 0px 16px 0px 16px; }
    .Panel .Card .Detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      margin-bottom: 16px; }
      .Panel .Card .Detail .Title {
        color: rgba(106, 110, 115, 0.7);
        font-size: 1.4rem; }
        .Panel .Card .Detail .Title.Total {
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500;
          color: #202124; }
      .Panel .Card .Detail .Value {
        color: #202124;
        font-size: 1.4rem; }
        .Panel .Card .Detail .Value.Center {
          margin-top: 2px; }
        .Panel .Card .Detail .Value.Price {
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500;
          color: #CD2033; }
  .Panel .Foot {
    padding: 10px 16px;
    background: #FAFAFA;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: #73C700;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500; }
    .Panel .Foot .TextSuccess {
      margin-top: 2px;
      margin-left: 12px; }
