/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .Wrapper .Header .Info {
    flex: 1; }
    .Wrapper .Header .Info .Title {
      overflow-wrap: break-word;
      color: #202124;
      margin-bottom: 8px;
      line-height: 1.5em;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      font-size: 1.4rem; }
    .Wrapper .Header .Info .Desc {
      font-size: 1.3rem; }
  .Wrapper .Header .Arrow {
    transition: transform 0.3s;
    -webkit-transition: transform 0.3s;
    -moz-transition: transform 0.3s; }
    .Wrapper .Header .Arrow.Active {
      transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);
      -moz-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      -o-transform: rotate(-180deg); }

.Wrapper .TotalPrice {
  padding: 12px 16px;
  background: #FAFAFA;
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500; }
  .Wrapper .TotalPrice .Label {
    color: #818589; }
  .Wrapper .TotalPrice .Price {
    color: #CD2033; }

@keyframes openDropdown {
  0% {
    opacity: 0;
    transform: translateY(-30%);
    -webkit-transform: translateY(-30%);
    -moz-transform: translateY(-30%);
    -ms-transform: translateY(-30%);
    -o-transform: translateY(-30%); }
  30% {
    opacity: .3;
    transform: translateY(-5%);
    -webkit-transform: translateY(-5%);
    -moz-transform: translateY(-5%);
    -ms-transform: translateY(-5%);
    -o-transform: translateY(-5%); }
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0); } }

@keyframes closeDropdown {
  0% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0); }
  50% {
    opacity: 0;
    transform: translateY(-10%);
    -webkit-transform: translateY(-10%);
    -moz-transform: translateY(-10%);
    -ms-transform: translateY(-10%);
    -o-transform: translateY(-10%); }
  100% {
    opacity: 0;
    transform: translateY(-10%);
    -webkit-transform: translateY(-10%);
    -moz-transform: translateY(-10%);
    -ms-transform: translateY(-10%);
    -o-transform: translateY(-10%); } }

.Open {
  animation: openDropdown 0.3s ease-out;
  -webkit-animation: openDropdown 0.3s ease-out;
  -moz-animation: openDropdown 0.3s ease-out;
  -ms-transform: openDropdown 0.3s ease-out;
  -o-transform: openDropdown 0.3s ease-out; }

.Close {
  animation: closeDropdown 0.3s ease-out;
  -webkit-animation: closeDropdown 0.3s ease-out;
  -moz-animation: closeDropdown 0.3s ease-out;
  -ms-transform: closeDropdown 0.3s ease-out;
  -o-transform: closeDropdown 0.3s ease-out; }
