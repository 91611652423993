/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  width: 100%; }
  .Wrapper .Header {
    display: flex;
    align-items: center;
    margin-bottom: 16px; }
    .Wrapper .Header .Icon {
      margin-right: 16px; }
    .Wrapper .Header .Title {
      color: #202124;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      font-size: 2rem; }
  .Wrapper .Description {
    font-size: 1.6rem;
    line-height: 24px;
    color: #202124; }
  .Wrapper .Item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
    margin-top: 16px; }
    .Wrapper .Item .Icon {
      flex: 0.06;
      background-color: #CD2033;
      border-radius: 100px;
      height: 28px;
      width: 28px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .Wrapper .Item .Content {
      flex: 0.9;
      margin-left: 12px;
      margin-top: 4px; }
      .Wrapper .Item .Content .Title {
        color: #202124;
        line-height: 20px;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        margin-bottom: 8px; }
      .Wrapper .Item .Content .Detail {
        font-size: 1.4rem;
        line-height: 22px;
        color: #818589; }
  .Wrapper .DetailOverview {
    color: #1A6FA3;
    font-size: 1.4rem;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    line-height: 18px;
    margin: 28px 0px;
    cursor: pointer; }
