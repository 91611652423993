/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 400;
  background: #1A6FA3; }
  .Wrapper .RightContent {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
    background: #FFFFFF;
    width: 45%;
    height: 100%;
    padding-left: 32px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box; }
    .Wrapper .RightContent .FormWrapper {
      position: relative;
      width: 365px;
      display: flex;
      align-items: center;
      height: 100vh;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box; }
      .Wrapper .RightContent .FormWrapper .Close {
        position: absolute;
        right: 0;
        top: 5%;
        cursor: pointer; }
      .Wrapper .RightContent .FormWrapper .Content {
        width: 100%; }
        .Wrapper .RightContent .FormWrapper .Content .Title {
          color: #202124;
          font-size: 2.2rem;
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500; }
        .Wrapper .RightContent .FormWrapper .Content .Form {
          margin-bottom: 40px; }
          .Wrapper .RightContent .FormWrapper .Content .Form .MultiColumn {
            display: flex; }
          .Wrapper .RightContent .FormWrapper .Content .Form .Input {
            margin-bottom: 24px;
            flex: 1; }
            .Wrapper .RightContent .FormWrapper .Content .Form .Input label {
              display: block;
              font-size: 1.4rem;
              margin-bottom: 8px; }
            .Wrapper .RightContent .FormWrapper .Content .Form .Input .Inline {
              display: flex; }
              .Wrapper .RightContent .FormWrapper .Content .Form .Input .Inline .DialCode {
                width: 110px;
                margin-right: 8px; }
              .Wrapper .RightContent .FormWrapper .Content .Form .Input .Inline .Phone {
                flex: 1; }
          .Wrapper .RightContent .FormWrapper .Content .Form .Input:last-child {
            margin-right: 0; }
        .Wrapper .RightContent .FormWrapper .Content .SocialMedia .Title {
          font-size: 1.4rem;
          margin-bottom: 16px;
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500; }
        .Wrapper .RightContent .FormWrapper .Content .SocialMedia .List {
          display: flex;
          padding: 0;
          margin: 0; }
          .Wrapper .RightContent .FormWrapper .Content .SocialMedia .List li {
            cursor: pointer;
            list-style: none;
            margin-right: 16px; }
  .Wrapper .JobDetail {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
    position: relative;
    z-index: 0; }
    .Wrapper .JobDetail .Container {
      height: 100%;
      width: 960px;
      margin: 0 auto; }
      .Wrapper .JobDetail .Container .Content.Left {
        padding: 60px 0 40px;
        background: #1A6FA3;
        color: #FFFFFF;
        padding-right: 32px;
        width: 55%;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box; }
      .Wrapper .JobDetail .Container .Content.Right {
        position: relative;
        padding-left: 32px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        flex: 45%;
        -moz-flex: 45%;
        -webkit-flex: 45%; }
