/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Panel {
  margin-top: 16px;
  margin-bottom: 0px; }
  .Panel .Card {
    padding: 0px 16px 0px 16px; }
    .Panel .Card .CardContent {
      margin-top: 16px;
      margin-bottom: 16px;
      display: flex;
      align-items: center; }
      .Panel .Card .CardContent .Image {
        width: 60px;
        height: 60px;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%; }
        .Panel .Card .CardContent .Image .Img {
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          height: auto;
          width: 60px;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%); }
      .Panel .Card .CardContent .Info {
        flex: 1; }
        .Panel .Card .CardContent .Info .Title {
          color: #202124;
          font-size: 1.6rem;
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500;
          margin-bottom: 12px; }
        .Panel .Card .CardContent .Info .Desc {
          color: #6A6E73;
          font-size: 1.3rem; }
  .Panel .Divider {
    border-top: 1px solid #E6E6E6; }
  .Panel .Detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px; }
    .Panel .Detail .Title {
      color: rgba(106, 110, 115, 0.7);
      font-size: 1.4rem; }
    .Panel .Detail .Value {
      color: #202124;
      font-size: 1.4rem; }
  .Panel .TourLeaderText {
    padding: 16px;
    font-size: 1.3rem;
    line-height: 1.5em;
    color: #202124; }
