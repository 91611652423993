/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  display: inline-flex;
  background: #FFFFFF;
  margin-bottom: 16px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  box-shadow: 0 1px 8px rgba(32, 33, 36, 0.1);
  -webkit-box-shadow: 0 1px 8px rgba(32, 33, 36, 0.1);
  -moz-box-shadow: 0 1px 8px rgba(32, 33, 36, 0.1);
  -ms-transform: 0 1px 8px rgba(32, 33, 36, 0.1);
  -o-transform: 0 1px 8px rgba(32, 33, 36, 0.1); }
  .Wrapper .Item {
    font-size: 1.2rem;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    color: #202124;
    padding: 0 16px;
    margin: 6px 0;
    display: flex;
    align-items: center; }
    .Wrapper .Item .Label {
      margin-left: 8px; }
  .Wrapper .Item:first-child {
    border-right: 1px solid #f5f5f5; }
