/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 700;
  background: #FAFAFA;
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; }
  .Wrapper .Header {
    height: 58px;
    padding: 0 16px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -webkit-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -moz-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -ms-transform: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -o-transform: 0px 1px 4px rgba(32, 33, 36, 0.05); }
    .Wrapper .Header .BackBtn {
      cursor: pointer;
      margin-right: 16px; }
    .Wrapper .Header .Title {
      font-size: 1.6rem;
      margin-top: 4px;
      color: #202124;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
  .Wrapper .Content {
    position: relative;
    padding: 78px 16px 16px; }
    .Wrapper .Content .BoxItem {
      margin-bottom: 16px; }
    .Wrapper .Content .Form {
      margin-bottom: 40px; }
      .Wrapper .Content .Form .MultiColumn {
        display: flex; }
      .Wrapper .Content .Form .Input {
        margin-bottom: 24px;
        flex: 1; }
        .Wrapper .Content .Form .Input label {
          display: block;
          font-size: 1.4rem;
          margin-bottom: 8px; }
        .Wrapper .Content .Form .Input .Inline {
          display: flex; }
          .Wrapper .Content .Form .Input .Inline .DialCode {
            width: 110px;
            margin-right: 8px; }
          .Wrapper .Content .Form .Input .Inline .Phone {
            flex: 1; }
      .Wrapper .Content .Form .Input:last-child {
        margin-right: 0; }

.Open {
  animation: openPopup 0.3s ease-out; }

.Close {
  animation: closePopup 0.3s ease-out; }

@keyframes openPopup {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translateY(0); } }

@keyframes closePopup {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(100%); } }
