p {
    font-size: 1.4rem;
    line-height: 1.6em;
}

h1, h2, h3, h4, h5 {
    @include font-weight-500();
    color: $c-black;
    margin-block-start: 1em !important;
    margin-block-end: 1em !important;
}

.label {
    font-size: 1.4rem;
    color: $c-grey;
    margin-bottom: 8px;
}
.link {
    font-size: 1.4rem;
    color: $c-red;
}
