/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  width: 220px;
  height: 300px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.2);
  -ms-transform: 0px 4px 8px 2px rgba(0, 0, 0, 0.2);
  -o-transform: 0px 4px 8px 2px rgba(0, 0, 0, 0.2); }
  .Wrapper img {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    width: auto;
    transition: height 0.2s;
    -webkit-transition: height 0.2s;
    -moz-transition: height 0.2s;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%); }
  .Wrapper .Includes {
    display: flex;
    margin: 12px 0; }
    .Wrapper .Includes .Item {
      margin-right: 8px; }
  .Wrapper .Description {
    bottom: 0;
    padding: 100px 16px 32px;
    position: absolute;
    color: #FFFFFF;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background: linear-gradient(rgba(255, 0, 0, 0) 5%, rgba(0, 0, 0, 0.7) 70%);
    background: -moz-linear-gradient(rgba(255, 0, 0, 0) 5%, rgba(0, 0, 0, 0.7) 70%);
    background: -webkit-linear-gradient(rgba(255, 0, 0, 0) 5%, rgba(0, 0, 0, 0.7) 70%);
    background: -o-linear-gradient(rgba(255, 0, 0, 0) 5%, rgba(0, 0, 0, 0.7) 70%);
    background: -ms-linear-gradient(rgba(255, 0, 0, 0) 5%, rgba(0, 0, 0, 0.7) 70%); }
    .Wrapper .Description .Label {
      display: inline-block;
      margin-bottom: 16px;
      color: #FFFFFF;
      background: rgba(194, 194, 194, 0.5);
      padding: 7px 15px 5px;
      font-size: 1rem;
      border-radius: 12px;
      -webkit-border-radius: 12px;
      -moz-border-radius: 12px; }
    .Wrapper .Description .Title {
      font-size: 1.5rem;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 1.4em; }
    .Wrapper .Description .StartFrom {
      font-size: 1.2rem;
      margin-bottom: 8px;
      color: #FFFFFF; }
    .Wrapper .Description .Price {
      display: flex;
      align-items: flex-end;
      font-size: 2rem;
      color: #FFFFFF;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
      .Wrapper .Description .Price .Perpax {
        font-family: "Geomanist-Regular", sans-serif;
        font-size: 1.2rem; }

.Wrapper:hover img {
  height: 110%; }
