/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.CardWrapper {
  background: #258da7;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, rgba(26, 111, 163, 0.6), #1A6FA3);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, rgba(26, 111, 163, 0.6), #1A6FA3);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius: 15px;
  width: 100%;
  height: 188px;
  padding: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .CardWrapper .Verified {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 11px;
    display: flex;
    align-items: center; }
  .CardWrapper .Logo {
    width: 100%;
    margin-top: 5px !important; }
    .CardWrapper .Logo .Image {
      width: 55px; }
  .CardWrapper .Number {
    color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    font-size: 1.8rem; }
    .CardWrapper .Number .Hide::before {
      content: "\2022 \2022 \2022 \2022 "; }
    .CardWrapper .Number .Show {
      margin-right: 30px; }
  .CardWrapper .Detail {
    display: flex;
    justify-content: space-between;
    color: #FFFFFF; }
    .CardWrapper .Detail .SubDetail {
      margin-right: 30px;
      flex: 1; }
      .CardWrapper .Detail .SubDetail.Small {
        flex: .6; }
      .CardWrapper .Detail .SubDetail .Label {
        font-size: 1rem;
        margin-bottom: 8px; }
      .CardWrapper .Detail .SubDetail .Value {
        font-size: 1.8rem; }
