/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .Head {
  padding: 0 16px; }
  .Wrapper .Head .Item {
    display: flex;
    font-size: 1.2rem;
    padding: 16px 0;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500; }
    .Wrapper .Head .Item .Column {
      flex: 1;
      -moz-flex: 1;
      -webkit-flex: 1; }
      .Wrapper .Head .Item .Column.Location {
        flex: 0.5;
        -moz-flex: 0.5;
        -webkit-flex: 0.5; }

.Wrapper .List {
  margin: 0;
  padding: 0; }
  .Wrapper .List li {
    list-style: none;
    padding: 0 16px;
    transition: background .3s, box-shadow .3s;
    -webkit-transition: background .3s, box-shadow .3s;
    -moz-transition: background .3s, box-shadow .3s;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px; }
    .Wrapper .List li a {
      text-decoration: none; }
    .Wrapper .List li .Item {
      position: relative;
      display: flex;
      align-items: center;
      color: #202124;
      padding: 30px 0;
      border-top: 1px solid #E6E6E6;
      transition: border 0.3s;
      -webkit-transition: border 0.3s;
      -moz-transition: border 0.3s; }
      .Wrapper .List li .Item .Column {
        flex: 1;
        -moz-flex: 1;
        -webkit-flex: 1; }
        .Wrapper .List li .Item .Column.Bold {
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500; }
        .Wrapper .List li .Item .Column.Location {
          flex: 0.5;
          -moz-flex: 0.5;
          -webkit-flex: 0.5; }
      .Wrapper .List li .Item .Icon {
        position: absolute;
        right: 0px; }
  .Wrapper .List li:not(.NotFound) {
    cursor: pointer; }
  .Wrapper .List li:hover:not(.NotFound) {
    background: #FFFFFF;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.08);
    -ms-transform: 0 5px 25px 0 rgba(0, 0, 0, 0.08);
    -o-transform: 0 5px 25px 0 rgba(0, 0, 0, 0.08); }
    .Wrapper .List li:hover:not(.NotFound) .Item {
      border-top: 1px solid #FFFFFF; }
  .Wrapper .List li:hover + li .Item {
    border-top: 1px solid #FFFFFF; }
  .Wrapper .List li:last-child .Item {
    border-bottom: 1px solid #E6E6E6; }
  .Wrapper .List li:last-child:hover:not(.NotFound) .Item {
    border-bottom: 1px solid #FFFFFF; }
