/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .Header {
  position: fixed;
  top: 0;
  padding: 16px;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  color: #202124;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .Wrapper .Header .Icon {
    cursor: pointer;
    margin-right: 8px; }
  .Wrapper .Header .Title {
    margin-top: 4px; }
  .Wrapper .Header.SecondNav {
    opacity: 0;
    background: #FFFFFF;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -webkit-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -moz-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -ms-transform: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -o-transform: 0px 1px 4px rgba(32, 33, 36, 0.05); }
  .Wrapper .Header.Show {
    opacity: 1; }

.Wrapper .Content {
  background: #FFFFFF;
  position: relative; }
  .Wrapper .Content .Title {
    text-align: center;
    font-size: 2rem;
    color: #202124;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    margin-bottom: 32px;
    margin-top: 18px; }
  .Wrapper .Content .SubTitle {
    font-size: 1.6rem;
    line-height: 1.6em;
    color: #202124;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500; }
    .Wrapper .Content .SubTitle .Image {
      width: 120px; }
  .Wrapper .Content .Description p {
    font-size: 1.4rem;
    line-height: 22px;
    color: #6A6E73; }
  .Wrapper .Content .TextContent {
    z-index: 1;
    background: #FFFFFF;
    position: relative;
    padding: 16px;
    margin: -50px 16px 0;
    border-top-left-radius: 16px;
    -webkit-border-top-left-radius: 16px;
    -moz-border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    -webkit-border-top-right-radius: 16px;
    -moz-border-top-right-radius: 16px; }
  .Wrapper .Content .Section {
    padding: 32px 16px; }
    .Wrapper .Content .Section .CosLogo {
      display: flex;
      justify-content: center; }
    .Wrapper .Content .Section .List {
      padding: 16px 0 0;
      margin: 0; }
      .Wrapper .Content .Section .List .Item {
        list-style: none;
        padding: 20px 16px;
        border: 1px solid #e6e6e6;
        border-radius: 8px;
        margin-bottom: 24px; }
        .Wrapper .Content .Section .List .Item .TitleWrapper {
          margin-bottom: 16px; }
          .Wrapper .Content .Section .List .Item .TitleWrapper .ServiceTitle {
            color: #202124;
            font-family: "Geomanist-Medium", sans-serif;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 24px;
            margin-bottom: 2px; }
          .Wrapper .Content .Section .List .Item .TitleWrapper .Description {
            font-size: 1.4rem;
            line-height: 22px;
            color: #202124; }
        .Wrapper .Content .Section .List .Item .SubItemWrapper {
          display: flex;
          align-items: flex-start;
          margin-bottom: 16px;
          margin-top: 16px; }
          .Wrapper .Content .Section .List .Item .SubItemWrapper .SubItemIcon {
            flex: 0.093;
            background-color: #CD2033;
            border-radius: 100px;
            height: 28px;
            width: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute; }
          .Wrapper .Content .Section .List .Item .SubItemWrapper .SubItemContent {
            flex: 0.9;
            margin-left: 12px;
            margin-top: 4px; }
            .Wrapper .Content .Section .List .Item .SubItemWrapper .SubItemContent .SubItemTitle {
              color: #202124;
              line-height: 20px;
              font-family: "Geomanist-Medium", sans-serif;
              font-weight: 500;
              margin-bottom: 8px; }
            .Wrapper .Content .Section .List .Item .SubItemWrapper .SubItemContent .SubItemDetail {
              font-size: 1.4rem;
              line-height: 22px;
              color: #818589; }
          .Wrapper .Content .Section .List .Item .SubItemWrapper .ContentLeft {
            flex: 0.9;
            margin-left: 36px;
            margin-top: 4px; }
            .Wrapper .Content .Section .List .Item .SubItemWrapper .ContentLeft .SubItemTitle {
              color: #202124;
              line-height: 20px;
              font-family: "Geomanist-Medium", sans-serif;
              font-weight: 500;
              margin-bottom: 8px; }
            .Wrapper .Content .Section .List .Item .SubItemWrapper .ContentLeft .SubItemDetail {
              font-size: 1.4rem;
              line-height: 22px;
              color: #818589; }
        .Wrapper .Content .Section .List .Item .DetailOverview {
          color: #1A6FA3;
          font-size: 1.4rem;
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500;
          line-height: 18px;
          margin: 0px 0px;
          cursor: pointer; }
      .Wrapper .Content .Section .List .OnlineItemWrapper {
        list-style: none;
        padding: 20px 16px;
        margin-bottom: 24px;
        border-radius: 12px;
        background-color: #ffffff;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06); }
        .Wrapper .Content .Section .List .OnlineItemWrapper .TitleWrapper {
          display: flex;
          align-items: flex-start;
          justify-content: space-between; }
          .Wrapper .Content .Section .List .OnlineItemWrapper .TitleWrapper .ServiceTitle {
            flex: 0.5;
            font-size: 1.6rem;
            font-family: "Geomanist-Medium", sans-serif;
            font-weight: 500;
            color: #202124;
            letter-spacing: 0;
            line-height: 24px; }
          .Wrapper .Content .Section .List .OnlineItemWrapper .TitleWrapper .StarWrapper {
            flex: 0.4;
            display: flex;
            justify-content: flex-end; }
        .Wrapper .Content .Section .List .OnlineItemWrapper .Divider {
          height: 1px;
          width: 100%;
          background-color: #f5f5f5;
          margin-top: 16px; }
        .Wrapper .Content .Section .List .OnlineItemWrapper .SubItemWrapper {
          margin-top: 16px;
          margin-bottom: 16px; }
          .Wrapper .Content .Section .List .OnlineItemWrapper .SubItemWrapper .SubItemTitle {
            color: #202124;
            font-family: "Geomanist-Medium", sans-serif;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 22px; }
          .Wrapper .Content .Section .List .OnlineItemWrapper .SubItemWrapper .SubItemContent {
            display: flex;
            align-items: flex-start; }
            .Wrapper .Content .Section .List .OnlineItemWrapper .SubItemWrapper .SubItemContent .SubItemDetail {
              flex: 0.95;
              color: #202124;
              font-size: 1.4rem;
              margin-left: 6px;
              line-height: 22px; }
        .Wrapper .Content .Section .List .OnlineItemWrapper .Term {
          font-size: 1.2rem;
          line-height: 14px;
          color: #202124;
          margin-top: 16px;
          margin-bottom: 12px; }
    .Wrapper .Content .Section .FooterTitle {
      color: #FFFFFF;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 24px; }
  .Wrapper .Content .Contact {
    min-height: 300px;
    padding: 32px 16px 42px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px; }
    .Wrapper .Content .Contact .Title {
      font-size: 24px;
      color: #202124;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
    .Wrapper .Content .Contact .Description {
      font-family: "Geomanist-Regular", sans-serif;
      line-height: 26px; }
    .Wrapper .Content .Contact .Body .MultiColumn {
      display: flex; }
    .Wrapper .Content .Contact .Body .Input {
      margin-bottom: 24px;
      flex: 1;
      margin-right: 24px; }
      .Wrapper .Content .Contact .Body .Input label {
        display: block;
        font-size: 1.4rem;
        margin-bottom: 8px; }
      .Wrapper .Content .Contact .Body .Input .Inline {
        display: flex; }
        .Wrapper .Content .Contact .Body .Input .Inline .DialCode {
          width: 110px;
          margin-right: 8px; }
        .Wrapper .Content .Contact .Body .Input .Inline .Phone {
          flex: 1; }
    .Wrapper .Content .Contact .Body .Input:last-child {
      margin-right: 0; }
    .Wrapper .Content .Contact .Body .Action {
      width: 200px;
      margin: 0 auto; }
    .Wrapper .Content .Contact .Body .Captcha {
      display: flex;
      justify-content: center;
      margin-bottom: 24px; }

.WrapperBanner {
  width: 100%;
  height: 230px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  .WrapperBanner .LogoBanner {
    text-align: center;
    position: relative;
    color: #FFFFFF;
    font-size: 2.8rem;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    z-index: 2;
    height: 90px; }
  .WrapperBanner .OverlayBanner {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(32, 33, 36, 0.4);
    z-index: 1; }
  .WrapperBanner .ImageBanner {
    position: absolute;
    left: 50%;
    top: 50%;
    height: auto;
    width: 100%;
    min-height: 230px;
    min-width: 800px;
    transition: height 0.2s;
    -webkit-transition: height 0.2s;
    -moz-transition: height 0.2s;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%); }
