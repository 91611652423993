/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .Form {
  padding: 24px 16px;
  margin: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .Wrapper .Form .Row {
    width: 100%;
    list-style: none;
    margin-bottom: 24px; }
    .Wrapper .Form .Row .Input {
      flex: 1; }
      .Wrapper .Form .Row .Input label {
        display: block;
        font-size: 1.3rem;
        margin-bottom: 8px;
        color: #202124; }
      .Wrapper .Form .Row .Input .Inline {
        display: flex; }
        .Wrapper .Form .Row .Input .Inline .DialCode {
          width: 110px;
          margin-right: 8px; }
        .Wrapper .Form .Row .Input .Inline .Phone {
          flex: 1; }
      .Wrapper .Form .Row .Input.PhoneWrapper {
        flex: 1.1; }
    .Wrapper .Form .Row.MultiColumn {
      display: flex; }
  .Wrapper .Form .Row:last-child {
    margin-bottom: 0; }
