/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  width: 100%; }
  .Wrapper .Label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4rem;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    padding: 16px; }
    .Wrapper .Label .ClearBtn {
      font-size: 1.3rem;
      font-family: "Geomanist-Regular", sans-serif;
      cursor: pointer; }
  .Wrapper .List {
    margin: 0;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.02);
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.02);
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.02);
    -ms-transform: 0px 1px 1px rgba(0, 0, 0, 0.02);
    -o-transform: 0px 1px 1px rgba(0, 0, 0, 0.02); }
    .Wrapper .List .Item {
      font-size: 1.4rem;
      padding: 12px 0;
      list-style: none;
      display: flex;
      justify-content: space-between; }
