/* ------------------------------
UTILITY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/

@import './mixins';
@import './UI/image';

@mixin margin-padding () {
    $values : -40 -35 -30 -20 -15 -10 -5 -2 0 2 4 6 8 10 12 14 15 16 20 24 28 30 40 48 50 60 80 100 140;
    @each $n in $values {
        // Margin
        .u-m#{$n} {
            margin: #{$n}px !important;
        }
        .u-mt-#{$n} {
            margin-top: #{$n}px !important;
        }
        .u-mb-#{$n} {
            margin-bottom: #{$n}px !important;
        }
        .u-ml-#{$n} {
            margin-left: #{$n}px !important;
        }
        .u-mr-#{$n} {
            margin-right: #{$n}px !important;
        }
        .u-my-#{$n} {
            margin-top: #{$n}px !important;
            margin-bottom: #{$n}px !important;
        }
        .u-mx-#{$n} {
            margin-left: #{$n}px !important;
            margin-right: #{$n}px !important;
        }
    
        // Padding
        .u-p-#{$n} {
            padding: #{$n}px !important;
        }
        .u-pt-#{$n} {
            padding-top: #{$n}px !important;
        }
        .u-pb-#{$n} {
            padding-bottom: #{$n}px !important;
        }
        .u-pl-#{$n} {
            padding-left: #{$n}px !important;
        }
        .u-pr-#{$n} {
            padding-right: #{$n}px !important;
        }
        .u-py-#{$n} {
            padding-top: #{$n}px !important;
            padding-bottom: #{$n}px !important;
        }
        .u-px-#{$n} {
            padding-left: #{$n}px !important;
            padding-right: #{$n}px !important;
        }
    }
}
@include margin-padding();

$aligns : left, center, right, justify;
@for $i from 1 through length($aligns) {
  .u-text-#{nth($aligns, $i)} {
    text-align: #{nth($aligns, $i)} !important;
  }
}

.u-bold {
    @include bold();
}

.u-regular {
    @include font-family();
}

//Color
$colors: (
    black     : $c-black,
    grey      : $c-grey,
    greyLight : $c-grey-light,
    white     : $c-white,
    red       : $c-red,
    green     : $c-green,
    blue      : $c-blue,
);
@each $element, $color in $colors {
    .u-color-#{$element} {
        color : $color !important;
    }
}

$flexs : 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;
@each $n in $flexs {
    .u-flex-#{$n} {
        @include flex($n/10);
    }
}

// backgraund
$colors: (
    black      : $c-black,
    grey       : $c-grey,
    grey_light : $c-grey-light,
    white_dark : $c-white-dark,
    white      : $c-white,
    red        : $c-red,
    blue       : $c-blue,
    green      : $c-green,
);
@each $element, $color in $colors {
    .u-bg-#{$element} {
        background : $color !important;
    }
}

$radius : 4, 6, 8, 10, 16, 24;
@each $n in $radius {
    .u-br-#{$n} {
        @include border-radius(border-radius, #{$n}px);
    }
}

.u-display-flex {
    display: flex;
    justify-content: space-around;
}

$alignItems: (
    start  : flex-start,
    center : center,
    end    : flex-end,
);
@each $element, $val in $alignItems {
    .u-align-items-#{$element} {
        align-items : $val !important;
    }
}

$justifyContent: (
    between : space-between,
    center  : center,
    around  : space-around,
    start   : flex-start,
    end     : flex-end,
);
@each $element, $val in $justifyContent {
    .u-justify-content-#{$element} {
        justify-content : $val !important;
    }
}

$fontSizes : 12, 13, 14, 16, 18, 20, 24, 32;
@each $n in $fontSizes {
    .u-fs-#{$n} {
        font-size: #{$n/10}rem !important; 
    }
}

.u-fw-normal {
    @include font-weight-normal();
}
.u-fw-300 {
    @include font-weight-300();
}
.u-fw-500 {
    @include font-weight-500();
}
