/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: thin solid #f5f5f5; }
  .Header .Info {
    flex: 1; }
    .Header .Info .Title {
      color: #202124;
      margin-bottom: 8px;
      line-height: 1.5em;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      font-size: 1.4rem; }
    .Header .Info .Desc {
      font-size: 1.3rem; }
  .Header .Image {
    width: 60px;
    height: 60px;
    margin-right: 16px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%; }
    .Header .Image .Img {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      height: 100%;
      width: auto;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%); }
  .Header .Arrow {
    transition: transform 0.3s;
    -webkit-transition: transform 0.3s;
    -moz-transition: transform 0.3s; }
    .Header .Arrow.Active {
      transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);
      -moz-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      -o-transform: rotate(-180deg); }

.Body {
  padding: 16px;
  font-size: 1.3rem; }
  .Body .Item {
    display: flex;
    align-items: center;
    margin-bottom: 8px; }
    .Body .Item .Label {
      margin-top: 2px;
      margin-left: 8px; }
  .Body .Item:last-child {
    margin-bottom: 0; }
