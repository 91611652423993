/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  min-height: 100vh;
  background: #FAFAFA; }
  .Wrapper .HeaderWrapper {
    height: 60px;
    overflow: hidden;
    background: #FFFFFF;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-shadow: 0px 2px 6px rgba(32, 33, 36, 0.1);
    -webkit-box-shadow: 0px 2px 6px rgba(32, 33, 36, 0.1);
    -moz-box-shadow: 0px 2px 6px rgba(32, 33, 36, 0.1);
    -ms-transform: 0px 2px 6px rgba(32, 33, 36, 0.1);
    -o-transform: 0px 2px 6px rgba(32, 33, 36, 0.1); }
  .Wrapper .Content {
    padding-top: 65px; }
    .Wrapper .Content .Banner {
      width: 100%; }
      .Wrapper .Content .Banner img {
        width: 100%; }
    .Wrapper .Content .Summary {
      padding: 24px; }
      .Wrapper .Content .Summary .Heading {
        color: #202124;
        font-size: 1.4em;
        line-height: 1.3em;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        margin-bottom: 15px; }
      .Wrapper .Content .Summary .Desc {
        line-height: 1.3em;
        margin-bottom: 20px;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500; }
    .Wrapper .Content .Form {
      margin: 0px 16px 0px 16px; }
      .Wrapper .Content .Form .Body {
        padding: 16px; }
        .Wrapper .Content .Form .Body .Row {
          width: 100%;
          margin-bottom: 24px; }
          .Wrapper .Content .Form .Body .Row .Input {
            flex: 1; }
            .Wrapper .Content .Form .Body .Row .Input label {
              display: block;
              font-size: 1.3rem;
              margin-bottom: 8px;
              color: #202124; }
            .Wrapper .Content .Form .Body .Row .Input .Inline {
              display: flex; }
              .Wrapper .Content .Form .Body .Row .Input .Inline .DialCode {
                width: 110px;
                margin-right: 8px; }
              .Wrapper .Content .Form .Body .Row .Input .Inline .Phone {
                flex: 1; }
            .Wrapper .Content .Form .Body .Row .Input.PhoneWrapper {
              flex: 1.1; }
          .Wrapper .Content .Form .Body .Row.MultiColumn {
            display: flex; }
