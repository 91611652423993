/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  background: #FFFFFF;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.18); }
  .Wrapper .Container {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 960px;
    margin: 0 auto; }
    .Wrapper .Container .Logo {
      cursor: pointer; }
      .Wrapper .Container .Logo img {
        max-height: 40px;
        display: block; }
    .Wrapper .Container .Nav {
      flex: 1;
      -moz-flex: 1;
      -webkit-flex: 1;
      justify-content: center;
      align-items: center;
      display: flex;
      font-size: 1.4rem;
      margin: 0;
      padding: 0; }
      .Wrapper .Container .Nav .Item {
        list-style: none;
        display: flex;
        align-items: center; }
        .Wrapper .Container .Nav .Item .Number {
          font-size: 1.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #FFFFFF;
          background: #6A6E73;
          width: 20px;
          height: 20px;
          padding-top: 2px;
          margin-right: 8px;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%; }
          .Wrapper .Container .Nav .Item .Number.Active {
            background: #1A6FA3; }
          .Wrapper .Container .Nav .Item .Number.Done {
            background: #FFFFFF; }
          .Wrapper .Container .Nav .Item .Number .Done::before {
            content: 'xxx';
            color: red; }
        .Wrapper .Container .Nav .Item .Text {
          margin-top: 5px; }
      .Wrapper .Container .Nav .Item:not(:last-child)::after {
        background-image: url("../../../../assets/images/ico-arrow-right.png");
        background-repeat: no-repeat;
        background-size: 16px 14px;
        width: 16px;
        height: 14px;
        margin: 0 24px;
        display: inline-flex;
        content: "";
        opacity: .7; }
