/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  position: fixed;
  z-index: 700;
  background: #FFFFFF;
  width: 100%;
  left: 0;
  bottom: 0;
  overflow: hidden; }
  .Wrapper .PriceHead {
    padding: 10px 16px;
    width: 100%;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    z-index: 2;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(32, 33, 36, 0.1);
    -webkit-box-shadow: 0 1px 8px rgba(32, 33, 36, 0.1);
    -moz-box-shadow: 0 1px 8px rgba(32, 33, 36, 0.1);
    -ms-transform: 0 1px 8px rgba(32, 33, 36, 0.1);
    -o-transform: 0 1px 8px rgba(32, 33, 36, 0.1); }
    .Wrapper .PriceHead .PriceWrapper .Label {
      font-size: 1.2rem; }
    .Wrapper .PriceHead .PriceWrapper .Price {
      margin-top: 6px;
      font-size: 1.6rem;
      color: #CD2033;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
      .Wrapper .PriceHead .PriceWrapper .Price .Perpax {
        color: #6A6E73;
        font-size: 1.2rem;
        font-family: "Geomanist-Regular", sans-serif; }
  .Wrapper .PriceDetail {
    padding: 16px;
    background: #FAFAFA;
    font-size: 1.2rem; }
    .Wrapper .PriceDetail .List .Item {
      display: flex;
      justify-content: space-between;
      padding: 8px 0;
      color: #202124; }
  .Wrapper .Header {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    border: 1px solid #f5f5f5; }
    .Wrapper .Header .Close {
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      cursor: pointer; }
    .Wrapper .Header .Title {
      color: #202124;
      font-size: 1.6rem;
      margin-left: 16px;
      margin-top: 4px;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
  .Wrapper .Content {
    padding: 20px; }

.Open {
  animation: openSort 0.2s ease-out; }

.Closed {
  animation: closeSort 0.25s ease-out; }

@keyframes openSort {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translateY(0); } }

@keyframes closeSort {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(100%); } }
