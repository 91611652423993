/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  margin-bottom: 16px;
  cursor: pointer; }
  .Wrapper .Image {
    height: 125px;
    overflow: hidden;
    background: #c2c2c2; }
  .Wrapper .Description {
    padding: 16px; }
