/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Panel {
  margin-top: 16px;
  margin-bottom: 0px; }
  .Panel .HeadTitle {
    font-size: 1.5rem;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    color: #202124;
    padding: 12px 0px 15px; }
  .Panel .Card {
    padding: 0px 16px 0px 16px; }
    .Panel .Card .Detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      margin-bottom: 16px; }
      .Panel .Card .Detail .Title {
        color: rgba(106, 110, 115, 0.7);
        font-size: 1.4rem; }
      .Panel .Card .Detail .Value {
        color: #202124;
        font-size: 1.4rem; }
