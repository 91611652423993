/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.TourItem {
  position: relative;
  margin-bottom: 16px;
  cursor: pointer; }
  .TourItem .RibbonWrapper {
    width: 100%;
    position: absolute;
    top: 16px;
    left: -9px;
    z-index: 1; }
  .TourItem .Image {
    height: 180px;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px; }
    .TourItem .Image .Img {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      height: auto;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%); }
    .TourItem .Image .Title {
      z-index: 1;
      font-size: 1.5rem;
      line-height: 1.4em;
      color: #FFFFFF;
      position: absolute;
      padding: 24px 16px 16px 16px;
      width: 100%;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      bottom: 0;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      background: linear-gradient(rgba(32, 33, 36, 0) 1%, rgba(32, 33, 36, 0.7) 30%);
      background: -moz-linear-gradient(rgba(32, 33, 36, 0) 1%, rgba(32, 33, 36, 0.7) 30%);
      background: -webkit-linear-gradient(rgba(32, 33, 36, 0) 1%, rgba(32, 33, 36, 0.7) 30%);
      background: -o-linear-gradient(rgba(32, 33, 36, 0) 1%, rgba(32, 33, 36, 0.7) 30%);
      background: -ms-linear-gradient(rgba(32, 33, 36, 0) 1%, rgba(32, 33, 36, 0.7) 30%); }
  .TourItem .Description {
    display: flex;
    justify-content: space-between;
    padding: 16px; }
    .TourItem .Description .Label {
      font-size: 1.2rem; }
    .TourItem .Description .Includes {
      margin-top: 6px;
      display: flex; }
      .TourItem .Description .Includes .Item {
        margin-right: 8px; }
    .TourItem .Description .PriceWrapper .Price {
      margin-top: 6px;
      font-size: 1.6rem;
      color: #CD2033;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
      .TourItem .Description .PriceWrapper .Price .Perpax {
        font-size: 1.2rem;
        font-family: "Geomanist-Regular", sans-serif; }
