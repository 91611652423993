/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .Label {
  padding: 16px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  color: #6A6E73;
  font-size: 1.4rem;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500; }

.Wrapper .List {
  background: #FFFFFF;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.02);
  -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.02);
  -ms-transform: 0px 1px 1px rgba(0, 0, 0, 0.02);
  -o-transform: 0px 1px 1px rgba(0, 0, 0, 0.02); }
  .Wrapper .List .DestinationItem {
    display: flex;
    padding: 12px 16px;
    align-items: center;
    border-bottom: 1px solid #FAFAFA;
    cursor: pointer; }
    .Wrapper .List .DestinationItem .DestinationName {
      margin-left: 8px;
      font-size: 1.4rem;
      color: #202124; }
