/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.DayPicker {
  outline: none;
  font-size: 1.4rem;
  color: #202124; }
  .DayPicker .DayPicker-wrapper:focus {
    outline: none; }
  .DayPicker .DayPicker-wrapper .DayPicker-NavBar {
    position: absolute;
    right: 10px; }
    .DayPicker .DayPicker-wrapper .DayPicker-NavBar .DayPicker-NavButton {
      width: 40px;
      height: 40px;
      color: #6A6E73;
      position: relative;
      right: 0;
      top: 0; }
      .DayPicker .DayPicker-wrapper .DayPicker-NavBar .DayPicker-NavButton.DayPicker-NavButton--prev {
        margin-right: 0; }
    .DayPicker .DayPicker-wrapper .DayPicker-NavBar .DayPicker-NavButton:focus {
      outline: none; }
    .DayPicker .DayPicker-wrapper .DayPicker-NavBar .DayPicker-NavButton--prev {
      background-image: url("../../../assets/images/ico-arrow-right.png");
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg); }
    .DayPicker .DayPicker-wrapper .DayPicker-NavBar .DayPicker-NavButton--next {
      background-image: url("../../../assets/images/ico-arrow-right.png"); }
  .DayPicker .DayPicker-Months .DayPicker-Month .DayPicker-Caption {
    font-size: 1.6rem; }
  .DayPicker .DayPicker-Months .DayPicker-Month .DayPicker-WeekdaysRow .DayPicker-Weekday {
    color: #6A6E73; }
    .DayPicker .DayPicker-Months .DayPicker-Month .DayPicker-WeekdaysRow .DayPicker-Weekday [title=Sunday] {
      color: #CD2033; }
  .DayPicker .DayPicker-Months .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day {
    display: table-cell;
    border-radius: 50%;
    box-sizing: border-box;
    padding: .8em; }
  .DayPicker .DayPicker-Months .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day:focus {
    outline: none; }
  .DayPicker .DayPicker-Months .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: #CD2033;
    color: #FFFFFF; }
  .DayPicker .DayPicker-Months .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)[aria-label*=Sun] {
    color: #FFFFFF; }
  .DayPicker .DayPicker-Months .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day[aria-label*=Sun] {
    color: #CD2033; }
    .DayPicker .DayPicker-Months .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day[aria-label*=Sun].DayPicker-Day--disabled {
      color: rgba(205, 32, 51, 0.4); }
  .DayPicker .DayPicker-Months .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day--today {
    color: #1A6FA3; }

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: rgba(205, 32, 51, 0.1) !important;
  color: #202124 !important; }

.Selectable .DayPicker-Day {
  border-radius: 0 !important; }

.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important; }

.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important; }

.DayPicker-Day--highlighted {
  color: #CD2033; }
  .DayPicker-Day--highlighted.DayPicker-Day--disabled {
    color: rgba(205, 32, 51, 0.4); }
