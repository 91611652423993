/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/

@import './font';
@import './variable';

@mixin box-shadow($val) {
    box-shadow: $val;
    -webkit-box-shadow: $val;
    -moz-box-shadow: $val;
    -ms-transform: $val;
    -o-transform: $val;
}

@mixin border-radius($prop, $val) {
    #{$prop}: $val;
    -webkit-#{$prop}: $val;
    -moz-#{$prop}: $val;
}

@mixin transition($val) {
    transition: #{$val};
    -webkit-transition: #{$val};
    -moz-transition: #{$val};
}

@mixin transform($val) {
    transform: $val;
    -webkit-transform: $val;
    -moz-transform: $val;
    -ms-transform: $val;
    -o-transform: $val;
}

@mixin animation($val) {
    animation: $val;
    -webkit-animation: $val;
    -moz-animation: $val;
    -ms-transform: $val;
    -o-transform: $val;
}

@mixin background-gradient($val) {
    background: $val;
    background: -moz-#{$val};
    background: -webkit-#{$val};
    background: -o-#{$val};
    background: -ms-#{$val};  
}

@mixin font-weight-normal() {
    font-family: $v-geomanist-regular, sans-serif;
}
@mixin font-weight-300() {
    font-family: $v-geomanist-book, sans-serif;
    font-weight: 300;
}

@mixin font-weight-500() {
    font-family: $v-geomanist-medium, sans-serif;
    font-weight: 500;
}

@mixin font-family() {
    @include font-weight-normal();
}
@mixin bold() {
    @include font-weight-500();
}

@mixin scroll() {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
}

@mixin container-1() {
    width: $v-container-1;
    margin: 0 auto;
}

@mixin container-2() {
    width: $v-container-2;
    margin: 0 auto;
}

@mixin container-3() {
    width: $v-container-3;
    margin: 0 auto;
}

@mixin container-4() {
    width: $v-container-4;
    margin: 0 auto;
}

@mixin box-sizing($val) {
    box-sizing: $val;
    -moz-box-sizing: $val;
    -webkit-box-sizing: $val;    
}

@mixin flex($val) {
    flex: $val;
    -moz-flex: $val;
    -webkit-flex: $val;
}

@mixin appearance($val) {
    -webkit-appearance: $val;
    -moz-appearance: $val;
    appearance: $val;
}

@mixin overflow-y($val) {
    overflow-y: $val;
    -webkit-overflow-scrolling: touch;
}

@mixin safe-area() {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    padding: content(safe-area-inset-top) content(safe-area-inset-right) content(safe-area-inset-bottom) content(safe-area-inset-left);
}