/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  min-width: 1080px;
  min-height: 100vh;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .Wrapper .Container {
    width: 960px;
    margin: 0 auto; }
    .Wrapper .Container .Loading {
      flex: 1;
      display: flex;
      align-items: center;
      min-height: 300px; }
    .Wrapper .Container .NotFound {
      margin: 48px auto; }
    .Wrapper .Container .Header {
      margin: 48px 0 32px; }
      .Wrapper .Container .Header .Title {
        color: #202124;
        font-size: 2.4rem;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        margin-bottom: 8px; }
      .Wrapper .Container .Header .SubTitle {
        font-size: 1.4rem; }
    .Wrapper .Container .Body {
      display: flex;
      min-height: 450px;
      padding-bottom: 100px; }
      .Wrapper .Container .Body .LeftContent {
        width: 590px;
        margin-right: 16px; }
      .Wrapper .Container .Body .RightContent {
        flex: 1; }
