/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  margin-bottom: 100px;
  min-width: 1080px; }
  .Wrapper .Container {
    position: relative;
    min-height: 250px;
    width: 960px;
    margin: 0 auto; }
    .Wrapper .Container .Title {
      font-size: 3.2rem;
      color: #202124;
      margin-bottom: 16px;
      text-align: center;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
    .Wrapper .Container .SubTitle {
      margin-bottom: 48px;
      text-align: center; }
