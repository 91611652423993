/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  position: relative; }
  .Wrapper .Container {
    background: #FFFFFF;
    width: 100%;
    min-height: 190px;
    width: 764px;
    margin: 0 auto;
    margin-top: -130px;
    padding: 40px 32px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px; }
    .Wrapper .Container .Title {
      font-size: 24px;
      color: #202124;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
    .Wrapper .Container .Description {
      font-family: "Geomanist-Regular", sans-serif;
      line-height: 26px; }
      .Wrapper .Container .Description .SelectBoxWrapper {
        position: relative;
        display: flex;
        align-items: center; }
        .Wrapper .Container .Description .SelectBoxWrapper .SelectBox {
          width: 100%;
          border: 1px solid #E6E6E6;
          color: #6A6E73;
          padding: 12px 36px 12px 16px;
          font-size: 1.4rem;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          transition: border 0.3s;
          -webkit-transition: border 0.3s;
          -moz-transition: border 0.3s;
          font-family: "Geomanist-Regular", sans-serif; }
        .Wrapper .Container .Description .SelectBoxWrapper .SelectBox:focus {
          outline: none;
          border: 1px solid #6A6E73; }
        .Wrapper .Container .Description .SelectBoxWrapper .SelectBox::placeholder {
          color: #c2c2c2; }
        .Wrapper .Container .Description .SelectBoxWrapper .Caret {
          position: absolute;
          right: 8px; }
        .Wrapper .Container .Description .SelectBoxWrapper .ListWrapper {
          position: absolute;
          top: 115%;
          z-index: 500;
          width: 100%;
          overflow: hidden;
          border-radius: 8px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.05);
          -webkit-box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.05);
          -moz-box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.05);
          -ms-transform: 0 1px 4px 1px rgba(0, 0, 0, 0.05);
          -o-transform: 0 1px 4px 1px rgba(0, 0, 0, 0.05); }
          .Wrapper .Container .Description .SelectBoxWrapper .ListWrapper .List {
            max-height: 550px;
            overflow-y: auto;
            padding: 10px 0;
            margin: 0;
            background: #FFFFFF;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box; }
            .Wrapper .Container .Description .SelectBoxWrapper .ListWrapper .List li {
              padding: 10px 15px;
              font-size: 1.4rem;
              box-sizing: border-box;
              display: flex;
              align-items: center;
              cursor: pointer;
              transition: background 0.4s;
              -webkit-transition: background 0.4s;
              -moz-transition: background 0.4s; }
            .Wrapper .Container .Description .SelectBoxWrapper .ListWrapper .List li:hover {
              background: #FAFAFA; }

@keyframes openDropdown {
  0% {
    opacity: 0;
    transform: translateY(-30%);
    -webkit-transform: translateY(-30%);
    -moz-transform: translateY(-30%);
    -ms-transform: translateY(-30%);
    -o-transform: translateY(-30%); }
  30% {
    opacity: .3;
    transform: translateY(-5%);
    -webkit-transform: translateY(-5%);
    -moz-transform: translateY(-5%);
    -ms-transform: translateY(-5%);
    -o-transform: translateY(-5%); }
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0); } }

@keyframes closeDropdown {
  0% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0); }
  50% {
    opacity: 0;
    transform: translateY(-10%);
    -webkit-transform: translateY(-10%);
    -moz-transform: translateY(-10%);
    -ms-transform: translateY(-10%);
    -o-transform: translateY(-10%); }
  100% {
    opacity: 0;
    transform: translateY(-10%);
    -webkit-transform: translateY(-10%);
    -moz-transform: translateY(-10%);
    -ms-transform: translateY(-10%);
    -o-transform: translateY(-10%); } }

.Open {
  animation: openDropdown 0.3s ease-out;
  -webkit-animation: openDropdown 0.3s ease-out;
  -moz-animation: openDropdown 0.3s ease-out;
  -ms-transform: openDropdown 0.3s ease-out;
  -o-transform: openDropdown 0.3s ease-out; }

.Close {
  animation: closeDropdown 0.3s ease-out;
  -webkit-animation: closeDropdown 0.3s ease-out;
  -moz-animation: closeDropdown 0.3s ease-out;
  -ms-transform: closeDropdown 0.3s ease-out;
  -o-transform: closeDropdown 0.3s ease-out; }
