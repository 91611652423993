/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Ld {
  margin: 0 auto;
  border-radius: 50%;
  border: 6px solid #E6E6E6;
  border-top: 6px solid #CD2033;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite; }
  .Ld.Small {
    border: 4px solid #E6E6E6 !important;
    border-top: 4px solid #CD2033 !important;
    width: 20px !important;
    height: 20px !important; }
  .Ld.Medium {
    border: 6px solid #E6E6E6 !important;
    border-top: 6px solid #CD2033 !important;
    width: 30px !important;
    height: 30px !important; }
  .Ld.Large {
    border: 8px solid #E6E6E6 !important;
    border-top: 8px solid #CD2033 !important;
    width: 40px !important;
    height: 40px !important; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
