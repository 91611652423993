/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  position: relative;
  z-index: 1; }
  .Wrapper .RibbonLeft {
    position: absolute;
    top: 0;
    left: 0;
    background: #2CADB5;
    color: #FFFFFF;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500; }
    .Wrapper .RibbonLeft::before {
      content: '';
      position: absolute;
      left: 0px;
      bottom: -10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 0;
      border-color: transparent #1E888E transparent transparent; }
    .Wrapper .RibbonLeft.Mobile {
      font-size: 1.2rem;
      padding: 6px 12px 4px 12px;
      border-radius: 8px 8px 8px 0;
      text-transform: capitalize; }
    .Wrapper .RibbonLeft.Desktop {
      font-size: 1.4rem;
      padding: 8px 20px;
      border-radius: 8px 8px 8px 0;
      text-transform: uppercase; }
      .Wrapper .RibbonLeft.Desktop.Small {
        font-size: 1rem;
        padding: 6px 12px 4px 12px; }
  .Wrapper .RibbonRight {
    position: absolute;
    top: 0;
    right: 0;
    background: #2CADB5;
    color: #FFFFFF;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500; }
    .Wrapper .RibbonRight::after {
      content: '';
      position: absolute;
      right: 0px;
      bottom: -10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 0;
      border-color: #1E888E transparent transparent transparent; }
    .Wrapper .RibbonRight.Mobile {
      font-size: 1.2rem;
      padding: 6px 12px 4px 12px;
      border-radius: 8px 8px 0 8px;
      text-transform: capitalize; }
    .Wrapper .RibbonRight.Desktop {
      font-size: 1.4rem;
      padding: 10px 20px 8px 20px;
      border-radius: 8px 8px 0 8px;
      text-transform: uppercase; }
