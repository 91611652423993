/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 655;
  background: #FAFAFA;
  padding: 16px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .Wrapper .Header {
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 2; }
  .Wrapper .Content {
    position: relative;
    z-index: 1;
    min-height: 100%;
    padding-top: 70px;
    padding-bottom: 65px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box; }
    .Wrapper .Content .PriceDetails .Title {
      margin-bottom: 12px;
      font-size: 1.6rem;
      color: #202124;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
    .Wrapper .Content .PriceDetails .Body {
      padding: 16px;
      font-size: 1.4rem;
      color: #202124; }
      .Wrapper .Content .PriceDetails .Body .Item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px; }
      .Wrapper .Content .PriceDetails .Body .Item:last-child {
        margin-bottom: 0px; }
    .Wrapper .Content .PriceDetails .Footer {
      border-top: thin solid #E6E6E6;
      margin: 0px 16px 16px;
      padding-top: 16px;
      font-size: 1.4rem; }
      .Wrapper .Content .PriceDetails .Footer .Item {
        display: flex;
        justify-content: space-between; }
        .Wrapper .Content .PriceDetails .Footer .Item .Label {
          color: #202124;
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500; }
        .Wrapper .Content .PriceDetails .Footer .Item .Total {
          color: #CD2033;
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500; }
  .Wrapper .FooterWrapper {
    padding: 16px;
    width: 100%;
    background: #FFFFFF;
    position: fixed;
    bottom: 0;
    z-index: 2;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box; }

.Open {
  animation: openPopup 0.3s; }

.Close {
  animation: closePopup 0.3s; }

@keyframes openPopup {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translateY(0); } }

@keyframes closePopup {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(100%); } }
