/* ------------------------------
STYLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
UTILITY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.img {
  width: 64px;
  height: 64px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px; }
  .img.img-circle {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%; }
  .img.img-80 {
    width: 80px !important;
    height: 80px !important; }
  .img.img-100 {
    width: 100px !important;
    height: 100px !important; }
  .img.img-120 {
    width: 120px !important;
    height: 120px !important; }
  .img .bg {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    width: auto;
    transition: height 0.2s;
    -webkit-transition: height 0.2s;
    -moz-transition: height 0.2s;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%); }

.u-m-40 {
  margin: -40px !important; }

.u-mt--40 {
  margin-top: -40px !important; }

.u-mb--40 {
  margin-bottom: -40px !important; }

.u-ml--40 {
  margin-left: -40px !important; }

.u-mr--40 {
  margin-right: -40px !important; }

.u-my--40 {
  margin-top: -40px !important;
  margin-bottom: -40px !important; }

.u-mx--40 {
  margin-left: -40px !important;
  margin-right: -40px !important; }

.u-p--40 {
  padding: -40px !important; }

.u-pt--40 {
  padding-top: -40px !important; }

.u-pb--40 {
  padding-bottom: -40px !important; }

.u-pl--40 {
  padding-left: -40px !important; }

.u-pr--40 {
  padding-right: -40px !important; }

.u-py--40 {
  padding-top: -40px !important;
  padding-bottom: -40px !important; }

.u-px--40 {
  padding-left: -40px !important;
  padding-right: -40px !important; }

.u-m-35 {
  margin: -35px !important; }

.u-mt--35 {
  margin-top: -35px !important; }

.u-mb--35 {
  margin-bottom: -35px !important; }

.u-ml--35 {
  margin-left: -35px !important; }

.u-mr--35 {
  margin-right: -35px !important; }

.u-my--35 {
  margin-top: -35px !important;
  margin-bottom: -35px !important; }

.u-mx--35 {
  margin-left: -35px !important;
  margin-right: -35px !important; }

.u-p--35 {
  padding: -35px !important; }

.u-pt--35 {
  padding-top: -35px !important; }

.u-pb--35 {
  padding-bottom: -35px !important; }

.u-pl--35 {
  padding-left: -35px !important; }

.u-pr--35 {
  padding-right: -35px !important; }

.u-py--35 {
  padding-top: -35px !important;
  padding-bottom: -35px !important; }

.u-px--35 {
  padding-left: -35px !important;
  padding-right: -35px !important; }

.u-m-30 {
  margin: -30px !important; }

.u-mt--30 {
  margin-top: -30px !important; }

.u-mb--30 {
  margin-bottom: -30px !important; }

.u-ml--30 {
  margin-left: -30px !important; }

.u-mr--30 {
  margin-right: -30px !important; }

.u-my--30 {
  margin-top: -30px !important;
  margin-bottom: -30px !important; }

.u-mx--30 {
  margin-left: -30px !important;
  margin-right: -30px !important; }

.u-p--30 {
  padding: -30px !important; }

.u-pt--30 {
  padding-top: -30px !important; }

.u-pb--30 {
  padding-bottom: -30px !important; }

.u-pl--30 {
  padding-left: -30px !important; }

.u-pr--30 {
  padding-right: -30px !important; }

.u-py--30 {
  padding-top: -30px !important;
  padding-bottom: -30px !important; }

.u-px--30 {
  padding-left: -30px !important;
  padding-right: -30px !important; }

.u-m-20 {
  margin: -20px !important; }

.u-mt--20 {
  margin-top: -20px !important; }

.u-mb--20 {
  margin-bottom: -20px !important; }

.u-ml--20 {
  margin-left: -20px !important; }

.u-mr--20 {
  margin-right: -20px !important; }

.u-my--20 {
  margin-top: -20px !important;
  margin-bottom: -20px !important; }

.u-mx--20 {
  margin-left: -20px !important;
  margin-right: -20px !important; }

.u-p--20 {
  padding: -20px !important; }

.u-pt--20 {
  padding-top: -20px !important; }

.u-pb--20 {
  padding-bottom: -20px !important; }

.u-pl--20 {
  padding-left: -20px !important; }

.u-pr--20 {
  padding-right: -20px !important; }

.u-py--20 {
  padding-top: -20px !important;
  padding-bottom: -20px !important; }

.u-px--20 {
  padding-left: -20px !important;
  padding-right: -20px !important; }

.u-m-15 {
  margin: -15px !important; }

.u-mt--15 {
  margin-top: -15px !important; }

.u-mb--15 {
  margin-bottom: -15px !important; }

.u-ml--15 {
  margin-left: -15px !important; }

.u-mr--15 {
  margin-right: -15px !important; }

.u-my--15 {
  margin-top: -15px !important;
  margin-bottom: -15px !important; }

.u-mx--15 {
  margin-left: -15px !important;
  margin-right: -15px !important; }

.u-p--15 {
  padding: -15px !important; }

.u-pt--15 {
  padding-top: -15px !important; }

.u-pb--15 {
  padding-bottom: -15px !important; }

.u-pl--15 {
  padding-left: -15px !important; }

.u-pr--15 {
  padding-right: -15px !important; }

.u-py--15 {
  padding-top: -15px !important;
  padding-bottom: -15px !important; }

.u-px--15 {
  padding-left: -15px !important;
  padding-right: -15px !important; }

.u-m-10 {
  margin: -10px !important; }

.u-mt--10 {
  margin-top: -10px !important; }

.u-mb--10 {
  margin-bottom: -10px !important; }

.u-ml--10 {
  margin-left: -10px !important; }

.u-mr--10 {
  margin-right: -10px !important; }

.u-my--10 {
  margin-top: -10px !important;
  margin-bottom: -10px !important; }

.u-mx--10 {
  margin-left: -10px !important;
  margin-right: -10px !important; }

.u-p--10 {
  padding: -10px !important; }

.u-pt--10 {
  padding-top: -10px !important; }

.u-pb--10 {
  padding-bottom: -10px !important; }

.u-pl--10 {
  padding-left: -10px !important; }

.u-pr--10 {
  padding-right: -10px !important; }

.u-py--10 {
  padding-top: -10px !important;
  padding-bottom: -10px !important; }

.u-px--10 {
  padding-left: -10px !important;
  padding-right: -10px !important; }

.u-m-5 {
  margin: -5px !important; }

.u-mt--5 {
  margin-top: -5px !important; }

.u-mb--5 {
  margin-bottom: -5px !important; }

.u-ml--5 {
  margin-left: -5px !important; }

.u-mr--5 {
  margin-right: -5px !important; }

.u-my--5 {
  margin-top: -5px !important;
  margin-bottom: -5px !important; }

.u-mx--5 {
  margin-left: -5px !important;
  margin-right: -5px !important; }

.u-p--5 {
  padding: -5px !important; }

.u-pt--5 {
  padding-top: -5px !important; }

.u-pb--5 {
  padding-bottom: -5px !important; }

.u-pl--5 {
  padding-left: -5px !important; }

.u-pr--5 {
  padding-right: -5px !important; }

.u-py--5 {
  padding-top: -5px !important;
  padding-bottom: -5px !important; }

.u-px--5 {
  padding-left: -5px !important;
  padding-right: -5px !important; }

.u-m-2 {
  margin: -2px !important; }

.u-mt--2 {
  margin-top: -2px !important; }

.u-mb--2 {
  margin-bottom: -2px !important; }

.u-ml--2 {
  margin-left: -2px !important; }

.u-mr--2 {
  margin-right: -2px !important; }

.u-my--2 {
  margin-top: -2px !important;
  margin-bottom: -2px !important; }

.u-mx--2 {
  margin-left: -2px !important;
  margin-right: -2px !important; }

.u-p--2 {
  padding: -2px !important; }

.u-pt--2 {
  padding-top: -2px !important; }

.u-pb--2 {
  padding-bottom: -2px !important; }

.u-pl--2 {
  padding-left: -2px !important; }

.u-pr--2 {
  padding-right: -2px !important; }

.u-py--2 {
  padding-top: -2px !important;
  padding-bottom: -2px !important; }

.u-px--2 {
  padding-left: -2px !important;
  padding-right: -2px !important; }

.u-m0 {
  margin: 0px !important; }

.u-mt-0 {
  margin-top: 0px !important; }

.u-mb-0 {
  margin-bottom: 0px !important; }

.u-ml-0 {
  margin-left: 0px !important; }

.u-mr-0 {
  margin-right: 0px !important; }

.u-my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important; }

.u-mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important; }

.u-p-0 {
  padding: 0px !important; }

.u-pt-0 {
  padding-top: 0px !important; }

.u-pb-0 {
  padding-bottom: 0px !important; }

.u-pl-0 {
  padding-left: 0px !important; }

.u-pr-0 {
  padding-right: 0px !important; }

.u-py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important; }

.u-px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.u-m2 {
  margin: 2px !important; }

.u-mt-2 {
  margin-top: 2px !important; }

.u-mb-2 {
  margin-bottom: 2px !important; }

.u-ml-2 {
  margin-left: 2px !important; }

.u-mr-2 {
  margin-right: 2px !important; }

.u-my-2 {
  margin-top: 2px !important;
  margin-bottom: 2px !important; }

.u-mx-2 {
  margin-left: 2px !important;
  margin-right: 2px !important; }

.u-p-2 {
  padding: 2px !important; }

.u-pt-2 {
  padding-top: 2px !important; }

.u-pb-2 {
  padding-bottom: 2px !important; }

.u-pl-2 {
  padding-left: 2px !important; }

.u-pr-2 {
  padding-right: 2px !important; }

.u-py-2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important; }

.u-px-2 {
  padding-left: 2px !important;
  padding-right: 2px !important; }

.u-m4 {
  margin: 4px !important; }

.u-mt-4 {
  margin-top: 4px !important; }

.u-mb-4 {
  margin-bottom: 4px !important; }

.u-ml-4 {
  margin-left: 4px !important; }

.u-mr-4 {
  margin-right: 4px !important; }

.u-my-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important; }

.u-mx-4 {
  margin-left: 4px !important;
  margin-right: 4px !important; }

.u-p-4 {
  padding: 4px !important; }

.u-pt-4 {
  padding-top: 4px !important; }

.u-pb-4 {
  padding-bottom: 4px !important; }

.u-pl-4 {
  padding-left: 4px !important; }

.u-pr-4 {
  padding-right: 4px !important; }

.u-py-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important; }

.u-px-4 {
  padding-left: 4px !important;
  padding-right: 4px !important; }

.u-m6 {
  margin: 6px !important; }

.u-mt-6 {
  margin-top: 6px !important; }

.u-mb-6 {
  margin-bottom: 6px !important; }

.u-ml-6 {
  margin-left: 6px !important; }

.u-mr-6 {
  margin-right: 6px !important; }

.u-my-6 {
  margin-top: 6px !important;
  margin-bottom: 6px !important; }

.u-mx-6 {
  margin-left: 6px !important;
  margin-right: 6px !important; }

.u-p-6 {
  padding: 6px !important; }

.u-pt-6 {
  padding-top: 6px !important; }

.u-pb-6 {
  padding-bottom: 6px !important; }

.u-pl-6 {
  padding-left: 6px !important; }

.u-pr-6 {
  padding-right: 6px !important; }

.u-py-6 {
  padding-top: 6px !important;
  padding-bottom: 6px !important; }

.u-px-6 {
  padding-left: 6px !important;
  padding-right: 6px !important; }

.u-m8 {
  margin: 8px !important; }

.u-mt-8 {
  margin-top: 8px !important; }

.u-mb-8 {
  margin-bottom: 8px !important; }

.u-ml-8 {
  margin-left: 8px !important; }

.u-mr-8 {
  margin-right: 8px !important; }

.u-my-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important; }

.u-mx-8 {
  margin-left: 8px !important;
  margin-right: 8px !important; }

.u-p-8 {
  padding: 8px !important; }

.u-pt-8 {
  padding-top: 8px !important; }

.u-pb-8 {
  padding-bottom: 8px !important; }

.u-pl-8 {
  padding-left: 8px !important; }

.u-pr-8 {
  padding-right: 8px !important; }

.u-py-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important; }

.u-px-8 {
  padding-left: 8px !important;
  padding-right: 8px !important; }

.u-m10 {
  margin: 10px !important; }

.u-mt-10 {
  margin-top: 10px !important; }

.u-mb-10 {
  margin-bottom: 10px !important; }

.u-ml-10 {
  margin-left: 10px !important; }

.u-mr-10 {
  margin-right: 10px !important; }

.u-my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important; }

.u-mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important; }

.u-p-10 {
  padding: 10px !important; }

.u-pt-10 {
  padding-top: 10px !important; }

.u-pb-10 {
  padding-bottom: 10px !important; }

.u-pl-10 {
  padding-left: 10px !important; }

.u-pr-10 {
  padding-right: 10px !important; }

.u-py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important; }

.u-px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important; }

.u-m12 {
  margin: 12px !important; }

.u-mt-12 {
  margin-top: 12px !important; }

.u-mb-12 {
  margin-bottom: 12px !important; }

.u-ml-12 {
  margin-left: 12px !important; }

.u-mr-12 {
  margin-right: 12px !important; }

.u-my-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important; }

.u-mx-12 {
  margin-left: 12px !important;
  margin-right: 12px !important; }

.u-p-12 {
  padding: 12px !important; }

.u-pt-12 {
  padding-top: 12px !important; }

.u-pb-12 {
  padding-bottom: 12px !important; }

.u-pl-12 {
  padding-left: 12px !important; }

.u-pr-12 {
  padding-right: 12px !important; }

.u-py-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important; }

.u-px-12 {
  padding-left: 12px !important;
  padding-right: 12px !important; }

.u-m14 {
  margin: 14px !important; }

.u-mt-14 {
  margin-top: 14px !important; }

.u-mb-14 {
  margin-bottom: 14px !important; }

.u-ml-14 {
  margin-left: 14px !important; }

.u-mr-14 {
  margin-right: 14px !important; }

.u-my-14 {
  margin-top: 14px !important;
  margin-bottom: 14px !important; }

.u-mx-14 {
  margin-left: 14px !important;
  margin-right: 14px !important; }

.u-p-14 {
  padding: 14px !important; }

.u-pt-14 {
  padding-top: 14px !important; }

.u-pb-14 {
  padding-bottom: 14px !important; }

.u-pl-14 {
  padding-left: 14px !important; }

.u-pr-14 {
  padding-right: 14px !important; }

.u-py-14 {
  padding-top: 14px !important;
  padding-bottom: 14px !important; }

.u-px-14 {
  padding-left: 14px !important;
  padding-right: 14px !important; }

.u-m15 {
  margin: 15px !important; }

.u-mt-15 {
  margin-top: 15px !important; }

.u-mb-15 {
  margin-bottom: 15px !important; }

.u-ml-15 {
  margin-left: 15px !important; }

.u-mr-15 {
  margin-right: 15px !important; }

.u-my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.u-mx-15 {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.u-p-15 {
  padding: 15px !important; }

.u-pt-15 {
  padding-top: 15px !important; }

.u-pb-15 {
  padding-bottom: 15px !important; }

.u-pl-15 {
  padding-left: 15px !important; }

.u-pr-15 {
  padding-right: 15px !important; }

.u-py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.u-px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.u-m16 {
  margin: 16px !important; }

.u-mt-16 {
  margin-top: 16px !important; }

.u-mb-16 {
  margin-bottom: 16px !important; }

.u-ml-16 {
  margin-left: 16px !important; }

.u-mr-16 {
  margin-right: 16px !important; }

.u-my-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important; }

.u-mx-16 {
  margin-left: 16px !important;
  margin-right: 16px !important; }

.u-p-16 {
  padding: 16px !important; }

.u-pt-16 {
  padding-top: 16px !important; }

.u-pb-16 {
  padding-bottom: 16px !important; }

.u-pl-16 {
  padding-left: 16px !important; }

.u-pr-16 {
  padding-right: 16px !important; }

.u-py-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important; }

.u-px-16 {
  padding-left: 16px !important;
  padding-right: 16px !important; }

.u-m20 {
  margin: 20px !important; }

.u-mt-20 {
  margin-top: 20px !important; }

.u-mb-20 {
  margin-bottom: 20px !important; }

.u-ml-20 {
  margin-left: 20px !important; }

.u-mr-20 {
  margin-right: 20px !important; }

.u-my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.u-mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.u-p-20 {
  padding: 20px !important; }

.u-pt-20 {
  padding-top: 20px !important; }

.u-pb-20 {
  padding-bottom: 20px !important; }

.u-pl-20 {
  padding-left: 20px !important; }

.u-pr-20 {
  padding-right: 20px !important; }

.u-py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important; }

.u-px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important; }

.u-m24 {
  margin: 24px !important; }

.u-mt-24 {
  margin-top: 24px !important; }

.u-mb-24 {
  margin-bottom: 24px !important; }

.u-ml-24 {
  margin-left: 24px !important; }

.u-mr-24 {
  margin-right: 24px !important; }

.u-my-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important; }

.u-mx-24 {
  margin-left: 24px !important;
  margin-right: 24px !important; }

.u-p-24 {
  padding: 24px !important; }

.u-pt-24 {
  padding-top: 24px !important; }

.u-pb-24 {
  padding-bottom: 24px !important; }

.u-pl-24 {
  padding-left: 24px !important; }

.u-pr-24 {
  padding-right: 24px !important; }

.u-py-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important; }

.u-px-24 {
  padding-left: 24px !important;
  padding-right: 24px !important; }

.u-m28 {
  margin: 28px !important; }

.u-mt-28 {
  margin-top: 28px !important; }

.u-mb-28 {
  margin-bottom: 28px !important; }

.u-ml-28 {
  margin-left: 28px !important; }

.u-mr-28 {
  margin-right: 28px !important; }

.u-my-28 {
  margin-top: 28px !important;
  margin-bottom: 28px !important; }

.u-mx-28 {
  margin-left: 28px !important;
  margin-right: 28px !important; }

.u-p-28 {
  padding: 28px !important; }

.u-pt-28 {
  padding-top: 28px !important; }

.u-pb-28 {
  padding-bottom: 28px !important; }

.u-pl-28 {
  padding-left: 28px !important; }

.u-pr-28 {
  padding-right: 28px !important; }

.u-py-28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important; }

.u-px-28 {
  padding-left: 28px !important;
  padding-right: 28px !important; }

.u-m30 {
  margin: 30px !important; }

.u-mt-30 {
  margin-top: 30px !important; }

.u-mb-30 {
  margin-bottom: 30px !important; }

.u-ml-30 {
  margin-left: 30px !important; }

.u-mr-30 {
  margin-right: 30px !important; }

.u-my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.u-mx-30 {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.u-p-30 {
  padding: 30px !important; }

.u-pt-30 {
  padding-top: 30px !important; }

.u-pb-30 {
  padding-bottom: 30px !important; }

.u-pl-30 {
  padding-left: 30px !important; }

.u-pr-30 {
  padding-right: 30px !important; }

.u-py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.u-px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.u-m40 {
  margin: 40px !important; }

.u-mt-40 {
  margin-top: 40px !important; }

.u-mb-40 {
  margin-bottom: 40px !important; }

.u-ml-40 {
  margin-left: 40px !important; }

.u-mr-40 {
  margin-right: 40px !important; }

.u-my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.u-mx-40 {
  margin-left: 40px !important;
  margin-right: 40px !important; }

.u-p-40 {
  padding: 40px !important; }

.u-pt-40 {
  padding-top: 40px !important; }

.u-pb-40 {
  padding-bottom: 40px !important; }

.u-pl-40 {
  padding-left: 40px !important; }

.u-pr-40 {
  padding-right: 40px !important; }

.u-py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important; }

.u-px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important; }

.u-m48 {
  margin: 48px !important; }

.u-mt-48 {
  margin-top: 48px !important; }

.u-mb-48 {
  margin-bottom: 48px !important; }

.u-ml-48 {
  margin-left: 48px !important; }

.u-mr-48 {
  margin-right: 48px !important; }

.u-my-48 {
  margin-top: 48px !important;
  margin-bottom: 48px !important; }

.u-mx-48 {
  margin-left: 48px !important;
  margin-right: 48px !important; }

.u-p-48 {
  padding: 48px !important; }

.u-pt-48 {
  padding-top: 48px !important; }

.u-pb-48 {
  padding-bottom: 48px !important; }

.u-pl-48 {
  padding-left: 48px !important; }

.u-pr-48 {
  padding-right: 48px !important; }

.u-py-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important; }

.u-px-48 {
  padding-left: 48px !important;
  padding-right: 48px !important; }

.u-m50 {
  margin: 50px !important; }

.u-mt-50 {
  margin-top: 50px !important; }

.u-mb-50 {
  margin-bottom: 50px !important; }

.u-ml-50 {
  margin-left: 50px !important; }

.u-mr-50 {
  margin-right: 50px !important; }

.u-my-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.u-mx-50 {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.u-p-50 {
  padding: 50px !important; }

.u-pt-50 {
  padding-top: 50px !important; }

.u-pb-50 {
  padding-bottom: 50px !important; }

.u-pl-50 {
  padding-left: 50px !important; }

.u-pr-50 {
  padding-right: 50px !important; }

.u-py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.u-px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.u-m60 {
  margin: 60px !important; }

.u-mt-60 {
  margin-top: 60px !important; }

.u-mb-60 {
  margin-bottom: 60px !important; }

.u-ml-60 {
  margin-left: 60px !important; }

.u-mr-60 {
  margin-right: 60px !important; }

.u-my-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important; }

.u-mx-60 {
  margin-left: 60px !important;
  margin-right: 60px !important; }

.u-p-60 {
  padding: 60px !important; }

.u-pt-60 {
  padding-top: 60px !important; }

.u-pb-60 {
  padding-bottom: 60px !important; }

.u-pl-60 {
  padding-left: 60px !important; }

.u-pr-60 {
  padding-right: 60px !important; }

.u-py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important; }

.u-px-60 {
  padding-left: 60px !important;
  padding-right: 60px !important; }

.u-m80 {
  margin: 80px !important; }

.u-mt-80 {
  margin-top: 80px !important; }

.u-mb-80 {
  margin-bottom: 80px !important; }

.u-ml-80 {
  margin-left: 80px !important; }

.u-mr-80 {
  margin-right: 80px !important; }

.u-my-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }

.u-mx-80 {
  margin-left: 80px !important;
  margin-right: 80px !important; }

.u-p-80 {
  padding: 80px !important; }

.u-pt-80 {
  padding-top: 80px !important; }

.u-pb-80 {
  padding-bottom: 80px !important; }

.u-pl-80 {
  padding-left: 80px !important; }

.u-pr-80 {
  padding-right: 80px !important; }

.u-py-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important; }

.u-px-80 {
  padding-left: 80px !important;
  padding-right: 80px !important; }

.u-m100 {
  margin: 100px !important; }

.u-mt-100 {
  margin-top: 100px !important; }

.u-mb-100 {
  margin-bottom: 100px !important; }

.u-ml-100 {
  margin-left: 100px !important; }

.u-mr-100 {
  margin-right: 100px !important; }

.u-my-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important; }

.u-mx-100 {
  margin-left: 100px !important;
  margin-right: 100px !important; }

.u-p-100 {
  padding: 100px !important; }

.u-pt-100 {
  padding-top: 100px !important; }

.u-pb-100 {
  padding-bottom: 100px !important; }

.u-pl-100 {
  padding-left: 100px !important; }

.u-pr-100 {
  padding-right: 100px !important; }

.u-py-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important; }

.u-px-100 {
  padding-left: 100px !important;
  padding-right: 100px !important; }

.u-m140 {
  margin: 140px !important; }

.u-mt-140 {
  margin-top: 140px !important; }

.u-mb-140 {
  margin-bottom: 140px !important; }

.u-ml-140 {
  margin-left: 140px !important; }

.u-mr-140 {
  margin-right: 140px !important; }

.u-my-140 {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.u-mx-140 {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.u-p-140 {
  padding: 140px !important; }

.u-pt-140 {
  padding-top: 140px !important; }

.u-pb-140 {
  padding-bottom: 140px !important; }

.u-pl-140 {
  padding-left: 140px !important; }

.u-pr-140 {
  padding-right: 140px !important; }

.u-py-140 {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.u-px-140 {
  padding-left: 140px !important;
  padding-right: 140px !important; }

.u-text-left {
  text-align: left !important; }

.u-text-center {
  text-align: center !important; }

.u-text-right {
  text-align: right !important; }

.u-text-justify {
  text-align: justify !important; }

.u-bold {
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500; }

.u-regular {
  font-family: "Geomanist-Regular", sans-serif; }

.u-color-black {
  color: #202124 !important; }

.u-color-grey {
  color: #6A6E73 !important; }

.u-color-greyLight {
  color: #c2c2c2 !important; }

.u-color-white {
  color: #FFFFFF !important; }

.u-color-red {
  color: #CD2033 !important; }

.u-color-green {
  color: #73C700 !important; }

.u-color-blue {
  color: #1A6FA3 !important; }

.u-flex-1 {
  flex: 0.1;
  -moz-flex: 0.1;
  -webkit-flex: 0.1; }

.u-flex-2 {
  flex: 0.2;
  -moz-flex: 0.2;
  -webkit-flex: 0.2; }

.u-flex-3 {
  flex: 0.3;
  -moz-flex: 0.3;
  -webkit-flex: 0.3; }

.u-flex-4 {
  flex: 0.4;
  -moz-flex: 0.4;
  -webkit-flex: 0.4; }

.u-flex-5 {
  flex: 0.5;
  -moz-flex: 0.5;
  -webkit-flex: 0.5; }

.u-flex-6 {
  flex: 0.6;
  -moz-flex: 0.6;
  -webkit-flex: 0.6; }

.u-flex-7 {
  flex: 0.7;
  -moz-flex: 0.7;
  -webkit-flex: 0.7; }

.u-flex-8 {
  flex: 0.8;
  -moz-flex: 0.8;
  -webkit-flex: 0.8; }

.u-flex-9 {
  flex: 0.9;
  -moz-flex: 0.9;
  -webkit-flex: 0.9; }

.u-flex-10 {
  flex: 1;
  -moz-flex: 1;
  -webkit-flex: 1; }

.u-bg-black {
  background: #202124 !important; }

.u-bg-grey {
  background: #6A6E73 !important; }

.u-bg-grey_light {
  background: #c2c2c2 !important; }

.u-bg-white_dark {
  background: #FAFAFA !important; }

.u-bg-white {
  background: #FFFFFF !important; }

.u-bg-red {
  background: #CD2033 !important; }

.u-bg-blue {
  background: #1A6FA3 !important; }

.u-bg-green {
  background: #73C700 !important; }

.u-br-4 {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px; }

.u-br-6 {
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px; }

.u-br-8 {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px; }

.u-br-10 {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px; }

.u-br-16 {
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px; }

.u-br-24 {
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px; }

.u-display-flex {
  display: flex;
  justify-content: space-around; }

.u-align-items-start {
  align-items: flex-start !important; }

.u-align-items-center {
  align-items: center !important; }

.u-align-items-end {
  align-items: flex-end !important; }

.u-justify-content-between {
  justify-content: space-between !important; }

.u-justify-content-center {
  justify-content: center !important; }

.u-justify-content-around {
  justify-content: space-around !important; }

.u-justify-content-start {
  justify-content: flex-start !important; }

.u-justify-content-end {
  justify-content: flex-end !important; }

.u-fs-12 {
  font-size: 1.2rem !important; }

.u-fs-13 {
  font-size: 1.3rem !important; }

.u-fs-14 {
  font-size: 1.4rem !important; }

.u-fs-16 {
  font-size: 1.6rem !important; }

.u-fs-18 {
  font-size: 1.8rem !important; }

.u-fs-20 {
  font-size: 2rem !important; }

.u-fs-24 {
  font-size: 2.4rem !important; }

.u-fs-32 {
  font-size: 3.2rem !important; }

.u-fw-normal {
  font-family: "Geomanist-Regular", sans-serif; }

.u-fw-300 {
  font-family: "Geomanist-Book", sans-serif;
  font-weight: 300; }

.u-fw-500 {
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500; }

/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.ui-tab {
  margin: 0;
  padding: 0;
  display: flex;
  border-bottom: 1px solid #f5f5f5; }
  .ui-tab .tab-item {
    list-style: none;
    padding: 12px 16px;
    font-size: 1.3rem;
    border-bottom: 2px solid rgba(205, 32, 51, 0);
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    cursor: pointer; }
    .ui-tab .tab-item.active {
      color: #CD2033;
      border-bottom: 2px solid #CD2033; }
  .ui-tab .tab-item:hover {
    color: #CD2033;
    border-bottom: 2px solid #CD2033; }

.img {
  width: 64px;
  height: 64px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px; }
  .img.img-circle {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%; }
  .img.img-80 {
    width: 80px !important;
    height: 80px !important; }
  .img.img-100 {
    width: 100px !important;
    height: 100px !important; }
  .img.img-120 {
    width: 120px !important;
    height: 120px !important; }
  .img .bg {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    width: auto;
    transition: height 0.2s;
    -webkit-transition: height 0.2s;
    -moz-transition: height 0.2s;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%); }

p {
  font-size: 1.4rem;
  line-height: 1.6em; }

h1, h2, h3, h4, h5 {
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500;
  color: #202124;
  margin-block-start: 1em !important;
  margin-block-end: 1em !important; }

.label {
  font-size: 1.4rem;
  color: #6A6E73;
  margin-bottom: 8px; }

.link {
  font-size: 1.4rem;
  color: #CD2033; }

table {
  font-size: 1.4rem;
  line-height: 1.6em;
  width: 100%;
  margin-block-start: .5em;
  margin-block-end: .5em;
  border-collapse: collapse; }
  table tfoot {
    border-top: 1px solid #c2c2c2;
    font-family: "Geomanist-Book", sans-serif;
    font-weight: 300;
    color: #202124; }
  table tr td {
    padding: 6px; }

@font-face {
  font-family: 'Geomanist';
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  margin: 0; }

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #6A6E73;
  background: #FFFFFF;
  font-size: 1.6rem;
  font-family: "Geomanist-Regular", sans-serif;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  padding: content(safe-area-inset-top) content(safe-area-inset-right) content(safe-area-inset-bottom) content(safe-area-inset-left); }

.bg-white {
  background: #FFFFFF; }

.bg-white-dark {
  background: #FAFAFA; }

.bg-black {
  background: #202124; }

.min-width {
  min-width: 1080px; }

.slick-active .banner-slider {
  background: #FFFFFF !important; }

.slick-slider .slick-dots.gallery-nav {
  height: 100px;
  overflow-x: scroll; }

.slick-active .banner-product {
  background: #CD2033 !important; }

.slick-active .banner-slider {
  background: #CD2033 !important; }

.SocialMediaShareButton {
  outline: none; }

.Toastify__toast {
  border-radius: 8px !important;
  padding: 8px 16px !important; }

.otp-input {
  width: 50px !important;
  height: 50px !important;
  margin: 0 8px;
  outline: none !important;
  border: none !important;
  border-bottom: 2px solid #E6E6E6 !important;
  color: #CD2033 !important;
  font-size: 3.2rem;
  background: transparent;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition: border .3s, color .3s;
  -webkit-transition: border .3s, color .3s;
  -moz-transition: border .3s, color .3s;
  font-family: "Geomanist-Regular", sans-serif;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important; }
  .otp-input.has-error {
    border: 1px solid #e44631 !important;
    color: #e44631; }
  .otp-input.has-error::placeholder {
    color: #e44631; }

.sm-dot-light li {
  width: 2px; }
  .sm-dot-light li.slick-active {
    width: 12px; }
    .sm-dot-light li.slick-active div {
      width: 16px;
      border-radius: 6px;
      background: #CD2033;
      margin: 2px auto;
      height: 6px; }
  .sm-dot-light li div {
    margin: 2px auto;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #c2c2c2; }

.md-dot-light li {
  width: 8px; }
  .md-dot-light li.slick-active {
    width: 15px; }
    .md-dot-light li.slick-active div {
      width: 16px;
      border-radius: 6px;
      background: #CD2033;
      margin: 2px auto;
      height: 8px; }
  .md-dot-light li div {
    margin: 2px auto;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #c2c2c2; }

.lg-dot-light li {
  width: 8px; }
  .lg-dot-light li.slick-active {
    width: 22px; }
    .lg-dot-light li.slick-active div {
      width: 22px;
      border-radius: 6px;
      background: #CD2033;
      margin: 2px auto;
      height: 12px; }
  .lg-dot-light li div {
    margin: 2px auto;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #c2c2c2; }

.Toastify__toast--error {
  background: #CD2033 !important; }

.Toastify__toast--info {
  background: #1A6FA3 !important; }

.Toastify__toast--warning {
  background: #F99E00 !important; }

.Toastify__toast--success {
  background: #73C700 !important; }

.rc-swipeout-content {
  background-color: transparent; }
