/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .Head {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500; }
  .Wrapper .Head .Title {
    font-size: 2rem;
    color: #202124; }
  .Wrapper .Head .BackBtn {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    color: #1A6FA3;
    cursor: pointer; }
    .Wrapper .Head .BackBtn span {
      margin-left: 12px; }
  .Wrapper .Head .BackBtn:hover {
    opacity: .8; }

.Wrapper .Box {
  background: #FAFAFA;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px; }
  .Wrapper .Box.Newsletter {
    margin-bottom: 16px;
    padding: 20px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .Wrapper .Box.Newsletter .Label {
      font-size: 1.6rem;
      margin-bottom: 8px;
      color: #202124;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
    .Wrapper .Box.Newsletter .Desc {
      font-size: 1.4rem;
      color: #818589; }
  .Wrapper .Box.Participant .HeadParticipant {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500; }
    .Wrapper .Box.Participant .HeadParticipant .Label {
      font-size: 1.6rem;
      color: #202124; }
    .Wrapper .Box.Participant .HeadParticipant .Link {
      font-size: 1.4rem;
      color: #CD2033;
      cursor: pointer; }
    .Wrapper .Box.Participant .HeadParticipant .Link:hover {
      opacity: .8; }
  .Wrapper .Box.Participant .List {
    margin: 0;
    padding: 0; }
    .Wrapper .Box.Participant .List .Item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 24px;
      list-style: none;
      font-size: 1.4rem;
      color: #202124;
      min-height: 24px;
      transition: background 0.5s;
      -webkit-transition: background 0.5s;
      -moz-transition: background 0.5s; }
      .Wrapper .Box.Participant .List .Item .Action {
        display: flex;
        justify-content: space-between;
        display: none; }
        .Wrapper .Box.Participant .List .Item .Action .Icon {
          margin-left: 4px;
          cursor: pointer; }
        .Wrapper .Box.Participant .List .Item .Action .Icon:hover svg g path {
          fill: #CD2033; }
    .Wrapper .Box.Participant .List .Item:hover {
      background: #f5f5f5;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px; }
      .Wrapper .Box.Participant .List .Item:hover .Action {
        display: flex; }
    .Wrapper .Box.Participant .List .Item:last-child {
      margin-bottom: 0; }

.InputWrapper {
  margin-bottom: 24px; }
  .InputWrapper .Label {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    color: #202124;
    margin-bottom: 8px; }
  .InputWrapper .Input {
    display: flex;
    align-items: center; }
  .InputWrapper .Desc {
    text-align: center;
    margin-bottom: 24px;
    font-size: 1em;
    line-height: 1.5em; }

.InputWrapper:last-child {
  margin-bottom: 0; }
