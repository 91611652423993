/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Banner {
  width: 100%; }
  .Banner img {
    width: 100%; }

.Wrapper {
  width: 1080px;
  margin: 0 auto;
  padding-bottom: 100px; }
  .Wrapper .Summary {
    padding: 50px 0px; }
    .Wrapper .Summary .Heading {
      text-align: center;
      font-size: 1.7em;
      color: #202124;
      margin-bottom: 50px;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
    .Wrapper .Summary .Detail > p {
      text-align: center;
      font-size: 1.2em;
      color: #202124; }
  .Wrapper .Form {
    width: 764px;
    margin: 0 auto; }
    .Wrapper .Form .Body {
      padding: 30px; }
      .Wrapper .Form .Body .MultiColumn {
        display: flex; }
      .Wrapper .Form .Body .Input {
        margin-bottom: 24px;
        flex: 1;
        margin-right: 24px; }
        .Wrapper .Form .Body .Input label {
          display: block;
          font-size: 1.4rem;
          margin-bottom: 8px; }
        .Wrapper .Form .Body .Input .Inline {
          display: flex; }
          .Wrapper .Form .Body .Input .Inline .DialCode {
            width: 100px;
            margin-right: 16px; }
          .Wrapper .Form .Body .Input .Inline .Phone {
            flex: 1; }
      .Wrapper .Form .Body .Input:last-child {
        margin-right: 0; }
      .Wrapper .Form .Body .Action {
        width: 200px;
        margin: 0 auto; }
      .Wrapper .Form .Body .Captcha {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 30px;
        position: relative; }
        .Wrapper .Form .Body .Captcha .ErrorMsg {
          position: absolute;
          bottom: -15px;
          color: #CD2033;
          font-size: 1.2rem; }
    .Wrapper .Form .Footer {
      background: #E6E6E6;
      padding: 16px;
      font-size: 1.2rem; }
