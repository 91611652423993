/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  background: #f5f5f5;
  padding: 16px 16px 50px;
  box-sizing: border-box; }
  .Wrapper .Title {
    padding: 20px 0px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-size: 1.8rem;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    color: #202124; }
  .Wrapper .Content {
    padding: 15px;
    box-sizing: border-box;
    display: flex; }
    .Wrapper .Content .Left .Image {
      width: 85px !important;
      height: 85px !important;
      background: red;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      overflow: hidden;
      position: relative; }
      .Wrapper .Content .Left .Image img {
        overflow: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: auto;
        height: 110%;
        transition: height 0.2s;
        -webkit-transition: height 0.2s;
        -moz-transition: height 0.2s;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%); }
    .Wrapper .Content .Detail {
      width: 100%;
      margin-left: 15px; }
      .Wrapper .Content .Detail .Badge {
        display: table;
        background: #2CADB5;
        border-radius: 3px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        padding: 3px 8px;
        position: relative;
        font-size: 1.0rem;
        z-index: 800;
        color: #FFFFFF;
        margin-bottom: 10px; }
        .Wrapper .Content .Detail .Badge::before {
          content: '';
          position: absolute;
          width: 6px;
          height: 6px;
          background: #FFFFFF;
          z-index: 900;
          top: 5px;
          left: -3px;
          border-radius: 3px;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px; }
        .Wrapper .Content .Detail .Badge::after {
          content: '';
          position: absolute;
          width: 6px;
          height: 6px;
          background: #FFFFFF;
          z-index: 900;
          top: 5px;
          right: -3px;
          border-radius: 3px;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px; }
      .Wrapper .Content .Detail .Name {
        font-size: 1.6rem;
        line-height: 2.0rem;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        color: #202124; }
      .Wrapper .Content .Detail .DateTime {
        margin-top: 4px; }
        .Wrapper .Content .Detail .DateTime .Date {
          display: flex;
          align-items: center; }
          .Wrapper .Content .Detail .DateTime .Date .DateDetail {
            margin-top: 3px;
            margin-left: 5px;
            font-size: 1.2rem;
            color: #202124; }
      .Wrapper .Content .Detail .Price {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        align-items: center; }
        .Wrapper .Content .Detail .Price .Label {
          color: #818589;
          font-size: 1.2rem; }
        .Wrapper .Content .Detail .Price .PriceNumber {
          color: #CD2033;
          font-size: 1.6rem;
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500; }
