/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Header {
  width: 100%;
  height: 58px;
  background: #CD2033;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  font-size: 1.8rem;
  padding: 0 15px;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500; }
  .Header .HeaderAction {
    margin-right: 16px; }

.Content {
  padding: 78px 20px 20px; }
  .Content .Section {
    margin-bottom: 20px; }
    .Content .Section .Title {
      font-size: 2rem;
      color: #202124;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      margin-bottom: 12px; }
    .Content .Section .Text {
      font-size: 1.4rem;
      color: #202124;
      line-height: 1.8em;
      margin-bottom: 30px; }
    .Content .Section .Otp {
      display: flex;
      justify-content: center;
      margin-bottom: 30px; }
    .Content .Section .Button {
      margin-bottom: 20px; }
    .Content .Section .Link {
      color: #CD2033;
      font-size: 1.4rem;
      text-align: center; }
