/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.PromoList {
  position: relative;
  width: 100%;
  height: 360px;
  min-width: 1080px; }
  .PromoList .Item {
    cursor: pointer;
    height: 360px;
    width: auto;
    width: 100%;
    overflow: hidden;
    display: flex !important;
    align-items: center;
    justify-content: center; }
    .PromoList .Item img {
      height: 360px; }
  .PromoList .Item:focus {
    outline: none; }

.DotWrapper {
  position: absolute;
  bottom: 80px;
  z-index: 300; }
  .DotWrapper li {
    width: 10px; }
    .DotWrapper li div {
      margin: 2px auto;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #c2c2c2; }

.Arrow {
  z-index: 1;
  position: absolute;
  top: 50%; }
  .Arrow.PrevArrow {
    cursor: pointer;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    display: block;
    left: 20px; }
    .Arrow.PrevArrow svg {
      margin-bottom: -75%; }
  .Arrow.NextArrow {
    cursor: pointer;
    display: block;
    right: 20px; }
    .Arrow.NextArrow svg {
      margin-top: -75%; }
