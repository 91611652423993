/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  position: relative; }
  .Wrapper .TextArea {
    border: 1px solid #eee;
    width: 100%;
    padding: 10px;
    height: 100%;
    margin: 0 auto;
    font-size: 1.3rem;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    font-family: "Geomanist-Regular", sans-serif;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transition: border .3s, color .3s;
    -webkit-transition: border .3s, color .3s;
    -moz-transition: border .3s, color .3s;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
    .Wrapper .TextArea.HasError {
      border: 1px solid #e44631 !important;
      color: #e44631; }
    .Wrapper .TextArea.HasError::placeholder {
      color: #e44631; }
  .Wrapper .TextArea:focus {
    outline: none;
    border: 1px solid #6A6E73; }
  .Wrapper .TextArea::placeholder {
    color: #c2c2c2; }
  .Wrapper .ErrorMsg {
    color: #CD2033;
    font-size: 1.2rem;
    position: absolute;
    left: 0;
    bottom: -16px; }

@keyframes openAutocomplete {
  0% {
    opacity: 0;
    transform: translateY(-30%);
    -webkit-transform: translateY(-30%);
    -moz-transform: translateY(-30%);
    -ms-transform: translateY(-30%);
    -o-transform: translateY(-30%); }
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0); } }

@keyframes closeAutocomplete {
  0% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(-30%);
    -webkit-transform: translateY(-30%);
    -moz-transform: translateY(-30%);
    -ms-transform: translateY(-30%);
    -o-transform: translateY(-30%); } }

.Open {
  animation: openAutocomplete 0.3s;
  -webkit-animation: openAutocomplete 0.3s;
  -moz-animation: openAutocomplete 0.3s;
  -ms-transform: openAutocomplete 0.3s;
  -o-transform: openAutocomplete 0.3s; }

.Close {
  animation: closeAutocomplete 0.3s;
  -webkit-animation: closeAutocomplete 0.3s;
  -moz-animation: closeAutocomplete 0.3s;
  -ms-transform: closeAutocomplete 0.3s;
  -o-transform: closeAutocomplete 0.3s; }
