/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Card {
  padding: 16px; }
  .Card .Detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px; }
    .Card .Detail:last-child {
      margin-bottom: 0; }
    .Card .Detail .Title {
      color: rgba(106, 110, 115, 0.7);
      font-size: 1.4rem;
      flex: .42; }
      .Card .Detail .Title.Total {
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        color: #202124; }
    .Card .Detail .Value {
      text-align: right;
      flex: .58;
      color: #202124;
      font-size: 1.4rem;
      line-height: 1.6em; }
      .Card .Detail .Value.Center {
        margin-top: 2px; }
      .Card .Detail .Value.Price {
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        color: #CD2033; }

.Foot {
  padding: 10px 16px;
  background: #FAFAFA;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: #73C700;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500; }
  .Foot .TextSuccess {
    margin-top: 2px;
    margin-left: 12px; }
