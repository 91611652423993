/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/

$v-geomanist-regular : 'Geomanist-Regular';
$v-geomanist-medium  : 'Geomanist-Medium';
$v-geomanist-book   : 'Geomanist-Book';

$v-container-1 : 1080px;
$v-container-2 : 960px;
$v-container-3 : 764px;
$v-container-4 : 688px;

