/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  min-height: 100vh;
  background: #FAFAFA;
  position: relative; }
  .Wrapper .HeaderWrapper {
    height: 60px;
    overflow: hidden;
    background: #FFFFFF;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%; }
  .Wrapper .Step {
    position: sticky;
    z-index: 2;
    top: 80;
    box-shadow: 0px 2px 2px rgba(106, 110, 115, 0.1);
    -webkit-box-shadow: 0px 2px 2px rgba(106, 110, 115, 0.1);
    -moz-box-shadow: 0px 2px 2px rgba(106, 110, 115, 0.1);
    -ms-transform: 0px 2px 2px rgba(106, 110, 115, 0.1);
    -o-transform: 0px 2px 2px rgba(106, 110, 115, 0.1); }
  .Wrapper .Content {
    position: relative;
    padding: 20px 20px 40px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box; }
    .Wrapper .Content .SubTitle {
      font-size: 1.5rem;
      margin-top: 4px;
      color: #202124;
      margin-bottom: 12px;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
    .Wrapper .Content .Card {
      font-size: 1.4rem;
      display: flex;
      padding: 20px 16px;
      align-items: center;
      justify-content: space-between;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      color: #202124; }
    .Wrapper .Content .Item {
      width: 100%;
      margin-bottom: 16px; }
      .Wrapper .Content .Item .Image {
        width: 100%;
        position: relative; }
        .Wrapper .Content .Item .Image img {
          width: 100%;
          display: block;
          height: 100%;
          position: relative; }
      .Wrapper .Content .Item .Body {
        padding: 16px;
        min-height: 107px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box; }
        .Wrapper .Content .Item .Body .Benefit {
          font-size: 1.4rem;
          color: #E6E6E6;
          display: flex;
          margin-bottom: 8px; }
          .Wrapper .Content .Item .Body .Benefit.Active {
            color: #202124; }
          .Wrapper .Content .Item .Body .Benefit .Text {
            margin-left: 8px;
            margin-top: 2px; }
      .Wrapper .Content .Item .Dates {
        border-top: 1px dashed #E6E6E6;
        padding: 16px;
        display: flex;
        align-items: flex-end; }
        .Wrapper .Content .Item .Dates .ItemDate {
          flex: 1; }
          .Wrapper .Content .Item .Dates .ItemDate .Label {
            font-size: 1.2rem;
            color: #6A6E73;
            margin-bottom: 5px; }
          .Wrapper .Content .Item .Dates .ItemDate .Date {
            font-size: 1.4rem;
            color: #202124;
            display: flex;
            align-items: center; }
            .Wrapper .Content .Item .Dates .ItemDate .Date .Text {
              margin-left: 8px;
              margin-top: 2px; }
      .Wrapper .Content .Item .Footer {
        padding: 16px;
        display: flex;
        align-items: flex-end;
        background: #FAFAFA; }
        .Wrapper .Content .Item .Footer .ItemPrice {
          flex: 1; }
          .Wrapper .Content .Item .Footer .ItemPrice .Label {
            font-size: 1.2rem;
            color: #6A6E73;
            margin-bottom: 5px; }
