/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.RedBackground {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: absolute;
  z-index: 0; }
  .RedBackground .Oval {
    background: #CD2033;
    width: 100%;
    height: 130px;
    position: absolute;
    border-bottom-left-radius: 30%;
    -webkit-border-bottom-left-radius: 30%;
    -moz-border-bottom-left-radius: 30%;
    border-bottom-right-radius: 30%;
    -webkit-border-bottom-right-radius: 30%;
    -moz-border-bottom-right-radius: 30%; }

.Header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%; }
  .Header.Shadow {
    box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.2);
    -webkit-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.2);
    -moz-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.2);
    -ms-transform: 0px 1px 4px rgba(32, 33, 36, 0.2);
    -o-transform: 0px 1px 4px rgba(32, 33, 36, 0.2); }

.Content {
  padding-bottom: 60px;
  padding-top: 60px;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.Logo {
  height: 38px; }
