/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .Header {
  background: #1A6FA3;
  padding: 24px;
  color: #FFFFFF;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500;
  text-align: center; }

.Wrapper .SectionList {
  margin: 0;
  padding: 0; }
  .Wrapper .SectionList .Item {
    padding: 16px 24px;
    list-style: none;
    border-bottom: 1px solid #f0f0f0; }
    .Wrapper .SectionList .Item .PriceWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 0; }
      .Wrapper .SectionList .Item .PriceWrapper .Label {
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        color: #202124; }
      .Wrapper .SectionList .Item .PriceWrapper .Price {
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        color: #CD2033;
        font-size: 2.4rem; }
        .Wrapper .SectionList .Item .PriceWrapper .Price .Perpax {
          font-size: 1.4rem;
          color: #6A6E73;
          font-family: "Geomanist-Regular", sans-serif; }
  .Wrapper .SectionList .Item:last-child {
    border-bottom: none; }

.SocialMedia {
  padding: 16px 24px; }
  .SocialMedia .Title {
    font-size: 1.4rem;
    margin-bottom: 16px;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500; }
  .SocialMedia .List {
    display: flex;
    padding: 0;
    margin: 0; }
    .SocialMedia .List li {
      cursor: pointer;
      list-style: none;
      margin-right: 16px; }
