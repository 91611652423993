/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .HeadTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.Wrapper .Nav {
  top: 0;
  left: 0;
  width: 100%;
  position: fixed;
  z-index: 2;
  background: linear-gradient(rgba(32, 33, 36, 0.5), rgba(32, 33, 36, 0));
  background: -moz-linear-gradient(rgba(32, 33, 36, 0.5), rgba(32, 33, 36, 0));
  background: -webkit-linear-gradient(rgba(32, 33, 36, 0.5), rgba(32, 33, 36, 0));
  background: -o-linear-gradient(rgba(32, 33, 36, 0.5), rgba(32, 33, 36, 0));
  background: -ms-linear-gradient(rgba(32, 33, 36, 0.5), rgba(32, 33, 36, 0));
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .Wrapper .Nav.SecondNav {
    opacity: 0;
    background: #FFFFFF;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s; }
  .Wrapper .Nav.Show {
    opacity: 1; }

.Wrapper .Content {
  position: relative;
  padding-bottom: 63px;
  z-index: 1;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background: #FAFAFA; }
  .Wrapper .Content .Image {
    height: 270px;
    overflow: hidden;
    position: relative; }
    .Wrapper .Content .Image img {
      position: absolute;
      left: 50%;
      top: 50%;
      height: auto;
      min-height: 100%;
      width: 100%;
      transition: width 0.3s;
      -webkit-transition: width 0.3s;
      -moz-transition: width 0.3s;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%); }
  .Wrapper .Content .TopContent {
    background: #FFFFFF;
    padding: 16px;
    margin-bottom: 24px; }
    .Wrapper .Content .TopContent .Title {
      font-size: 2.0rem;
      color: #202124;
      line-height: 3.0rem;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
    .Wrapper .Content .TopContent .Location {
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      margin-top: 10px; }
      .Wrapper .Content .TopContent .Location .LocationName {
        margin-left: 8px;
        margin-top: 3px; }
    .Wrapper .Content .TopContent .DateWrapper {
      margin-top: 16px;
      padding: 16px 0px;
      border-top: thin solid #f5f5f5; }
      .Wrapper .Content .TopContent .DateWrapper label {
        font-size: 1.2rem;
        color: #818589; }
      .Wrapper .Content .TopContent .DateWrapper .ValidDate {
        display: flex;
        align-items: center;
        margin-top: 5px; }
        .Wrapper .Content .TopContent .DateWrapper .ValidDate .Date {
          margin-left: 6px;
          font-size: 1.4rem;
          margin-top: 3px;
          color: #202124; }
  .Wrapper .Content .Heading {
    font-size: 1.6rem;
    color: #202124;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    margin-bottom: 16px; }
  .Wrapper .Content .SplitSpace {
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    display: flex;
    align-items: center !important; }
    .Wrapper .Content .SplitSpace .FreeVoucherSpace {
      flex: 1;
      height: 48px;
      margin-right: 20px; }
    .Wrapper .Content .SplitSpace .WhatsappSpace {
      width: 48px;
      height: 48px;
      margin-top: 15px;
      margin-right: 3px; }
      .Wrapper .Content .SplitSpace .WhatsappSpace .Button {
        background: #25d366;
        z-index: 1;
        width: 34px;
        height: 34px;
        padding: 8px;
        display: flex;
        align-items: center;
        font-size: 1.6rem;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border: 1px solid rgba(37, 211, 102, 0.5);
        box-shadow: 0 3px 10px 2px rgba(37, 211, 102, 0.2);
        -webkit-box-shadow: 0 3px 10px 2px rgba(37, 211, 102, 0.2);
        -moz-box-shadow: 0 3px 10px 2px rgba(37, 211, 102, 0.2);
        -ms-transform: 0 3px 10px 2px rgba(37, 211, 102, 0.2);
        -o-transform: 0 3px 10px 2px rgba(37, 211, 102, 0.2); }
        .Wrapper .Content .SplitSpace .WhatsappSpace .Button .Label {
          color: #FFFFFF;
          margin-left: 8px;
          margin-top: 4px; }
  .Wrapper .Content .Card {
    font-size: 1.4rem;
    display: flex;
    padding: 20px 16px;
    align-items: center;
    justify-content: space-between;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    color: #202124; }
  .Wrapper .Content .FooterWrapper {
    padding: 10px 16px;
    width: 100%;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    z-index: 2;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(32, 33, 36, 0.1);
    -webkit-box-shadow: 0 1px 8px rgba(32, 33, 36, 0.1);
    -moz-box-shadow: 0 1px 8px rgba(32, 33, 36, 0.1);
    -ms-transform: 0 1px 8px rgba(32, 33, 36, 0.1);
    -o-transform: 0 1px 8px rgba(32, 33, 36, 0.1); }
    .Wrapper .Content .FooterWrapper .PriceWrapper .Label {
      font-size: 1.2rem; }
    .Wrapper .Content .FooterWrapper .PriceWrapper .Price {
      margin-top: 6px;
      font-size: 1.6rem;
      color: #CD2033;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500; }
      .Wrapper .Content .FooterWrapper .PriceWrapper .Price .Perpax {
        color: #6A6E73;
        font-size: 1.2rem;
        font-family: "Geomanist-Regular", sans-serif; }
