/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 58px;
  background: #CD2033;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  font-size: 1.8rem;
  padding: 0 15px;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500; }
  .Header.Shadow {
    box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.2);
    -webkit-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.2);
    -moz-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.2);
    -ms-transform: 0px 1px 4px rgba(32, 33, 36, 0.2);
    -o-transform: 0px 1px 4px rgba(32, 33, 36, 0.2); }
  .Header .Action {
    margin-right: 16px; }

.ContentWrapper {
  margin: 58px 0 0;
  position: relative;
  overflow: hidden;
  min-height: 100vh; }
  .ContentWrapper .ContentSwiper {
    padding: 10px 0; }
    .ContentWrapper .ContentSwiper .SwiperItem {
      width: 100% !important;
      padding-top: 8px !important;
      padding-bottom: 8px !important;
      padding-left: 40px; }
      .ContentWrapper .ContentSwiper .SwiperItem .Delete {
        padding: 10px;
        background: #CD2033;
        border-radius: 25px; }
  .ContentWrapper .AddCard {
    width: 280px;
    border: 2px solid #E6E6E6;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 106px;
    margin-left: 40px;
    background: #FAFAFA; }
    .ContentWrapper .AddCard .Label {
      text-align: center;
      font-family: "Geomanist-Medium", sans-serif;
      font-weight: 500;
      color: #c2c2c2;
      font-size: 1.4rem; }
      .ContentWrapper .AddCard .LabelIcon {
        display: flex;
        justify-content: center;
        margin-bottom: 6px; }

.Form {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .Form .Row {
    width: 100%;
    list-style: none;
    margin-bottom: 24px; }
    .Form .Row .Input {
      flex: 1; }
      .Form .Row .Input label {
        display: block;
        font-size: 1.3rem;
        margin-bottom: 8px;
        color: #202124; }
    .Form .Row .Inputs {
      display: flex;
      justify-content: space-around; }
      .Form .Row .Inputs .Valid {
        flex: 0.55; }
        .Form .Row .Inputs .Valid label {
          display: block;
          font-size: 1.3rem;
          margin-bottom: 8px;
          color: #202124; }
      .Form .Row .Inputs .Cvv {
        flex: 0.45;
        margin-left: 10px; }
        .Form .Row .Inputs .Cvv label {
          display: block;
          font-size: 1.3rem;
          margin-bottom: 8px;
          color: #202124; }
  .Form .Row:last-child {
    margin-bottom: 0; }
