/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  z-index: 670; }
  .Wrapper .Bg {
    position: relative;
    width: 70px;
    height: 70px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px; }

.Open {
  animation: openDetail 0.3s;
  -webkit-animation: openDetail 0.3s;
  -moz-animation: openDetail 0.3s;
  -ms-transform: openDetail 0.3s;
  -o-transform: openDetail 0.3s; }

.Close {
  animation: closeDetail 0.3s;
  -webkit-animation: closeDetail 0.3s;
  -moz-animation: closeDetail 0.3s;
  -ms-transform: closeDetail 0.3s;
  -o-transform: closeDetail 0.3s; }

@keyframes openDetail {
  0% {
    opacity: 0; }
  100% {
    opacity: .7; } }

@keyframes closeDetail {
  0% {
    opacity: .7; }
  100% {
    opacity: 0; } }

.Spiner {
  color: #CD2033;
  font-size: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1s infinite linear;
  animation: load4 1s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0); }

@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0; }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em; }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0; }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em; } }

@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0; }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em; }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0; }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em; } }

@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #cd2133, 1.8em -1.8em 0 0em rgba(205, 33, 51, 0.2), 2.5em 0em 0 0em rgba(205, 33, 51, 0.2), 1.75em 1.75em 0 0em rgba(205, 33, 51, 0.2), 0em 2.5em 0 0em rgba(205, 33, 51, 0.2), -1.8em 1.8em 0 0em rgba(205, 33, 51, 0.2), -2.6em 0em 0 0em rgba(205, 33, 51, 0.5), -1.8em -1.8em 0 0em rgba(205, 33, 51, 0.7); }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(205, 33, 51, 0.7), 1.8em -1.8em 0 0em #cd2133, 2.5em 0em 0 0em rgba(205, 33, 51, 0.2), 1.75em 1.75em 0 0em rgba(205, 33, 51, 0.2), 0em 2.5em 0 0em rgba(205, 33, 51, 0.2), -1.8em 1.8em 0 0em rgba(205, 33, 51, 0.2), -2.6em 0em 0 0em rgba(205, 33, 51, 0.2), -1.8em -1.8em 0 0em rgba(205, 33, 51, 0.5); }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(205, 33, 51, 0.5), 1.8em -1.8em 0 0em rgba(205, 33, 51, 0.7), 2.5em 0em 0 0em #cd2133, 1.75em 1.75em 0 0em rgba(205, 33, 51, 0.2), 0em 2.5em 0 0em rgba(205, 33, 51, 0.2), -1.8em 1.8em 0 0em rgba(205, 33, 51, 0.2), -2.6em 0em 0 0em rgba(205, 33, 51, 0.2), -1.8em -1.8em 0 0em rgba(205, 33, 51, 0.2); }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(205, 33, 51, 0.2), 1.8em -1.8em 0 0em rgba(205, 33, 51, 0.5), 2.5em 0em 0 0em rgba(205, 33, 51, 0.7), 1.75em 1.75em 0 0em #cd2133, 0em 2.5em 0 0em rgba(205, 33, 51, 0.2), -1.8em 1.8em 0 0em rgba(205, 33, 51, 0.2), -2.6em 0em 0 0em rgba(205, 33, 51, 0.2), -1.8em -1.8em 0 0em rgba(205, 33, 51, 0.2); }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(205, 33, 51, 0.2), 1.8em -1.8em 0 0em rgba(205, 33, 51, 0.2), 2.5em 0em 0 0em rgba(205, 33, 51, 0.5), 1.75em 1.75em 0 0em rgba(205, 33, 51, 0.7), 0em 2.5em 0 0em #cd2133, -1.8em 1.8em 0 0em rgba(205, 33, 51, 0.2), -2.6em 0em 0 0em rgba(205, 33, 51, 0.2), -1.8em -1.8em 0 0em rgba(205, 33, 51, 0.2); }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(205, 33, 51, 0.2), 1.8em -1.8em 0 0em rgba(205, 33, 51, 0.2), 2.5em 0em 0 0em rgba(205, 33, 51, 0.2), 1.75em 1.75em 0 0em rgba(205, 33, 51, 0.5), 0em 2.5em 0 0em rgba(205, 33, 51, 0.7), -1.8em 1.8em 0 0em #cd2133, -2.6em 0em 0 0em rgba(205, 33, 51, 0.2), -1.8em -1.8em 0 0em rgba(205, 33, 51, 0.2); }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(205, 33, 51, 0.2), 1.8em -1.8em 0 0em rgba(205, 33, 51, 0.2), 2.5em 0em 0 0em rgba(205, 33, 51, 0.2), 1.75em 1.75em 0 0em rgba(205, 33, 51, 0.2), 0em 2.5em 0 0em rgba(205, 33, 51, 0.5), -1.8em 1.8em 0 0em rgba(205, 33, 51, 0.7), -2.6em 0em 0 0em #cd2133, -1.8em -1.8em 0 0em rgba(205, 33, 51, 0.2); }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(205, 33, 51, 0.2), 1.8em -1.8em 0 0em rgba(205, 33, 51, 0.2), 2.5em 0em 0 0em rgba(205, 33, 51, 0.2), 1.75em 1.75em 0 0em rgba(205, 33, 51, 0.2), 0em 2.5em 0 0em rgba(205, 33, 51, 0.2), -1.8em 1.8em 0 0em rgba(205, 33, 51, 0.5), -2.6em 0em 0 0em rgba(205, 33, 51, 0.7), -1.8em -1.8em 0 0em #cd2133; } }

@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #cd2133, 1.8em -1.8em 0 0em rgba(205, 33, 51, 0.2), 2.5em 0em 0 0em rgba(205, 33, 51, 0.2), 1.75em 1.75em 0 0em rgba(205, 33, 51, 0.2), 0em 2.5em 0 0em rgba(205, 33, 51, 0.2), -1.8em 1.8em 0 0em rgba(205, 33, 51, 0.2), -2.6em 0em 0 0em rgba(205, 33, 51, 0.5), -1.8em -1.8em 0 0em rgba(205, 33, 51, 0.7); }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(205, 33, 51, 0.7), 1.8em -1.8em 0 0em #cd2133, 2.5em 0em 0 0em rgba(205, 33, 51, 0.2), 1.75em 1.75em 0 0em rgba(205, 33, 51, 0.2), 0em 2.5em 0 0em rgba(205, 33, 51, 0.2), -1.8em 1.8em 0 0em rgba(205, 33, 51, 0.2), -2.6em 0em 0 0em rgba(205, 33, 51, 0.2), -1.8em -1.8em 0 0em rgba(205, 33, 51, 0.5); }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(205, 33, 51, 0.5), 1.8em -1.8em 0 0em rgba(205, 33, 51, 0.7), 2.5em 0em 0 0em #cd2133, 1.75em 1.75em 0 0em rgba(205, 33, 51, 0.2), 0em 2.5em 0 0em rgba(205, 33, 51, 0.2), -1.8em 1.8em 0 0em rgba(205, 33, 51, 0.2), -2.6em 0em 0 0em rgba(205, 33, 51, 0.2), -1.8em -1.8em 0 0em rgba(205, 33, 51, 0.2); }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(205, 33, 51, 0.2), 1.8em -1.8em 0 0em rgba(205, 33, 51, 0.5), 2.5em 0em 0 0em rgba(205, 33, 51, 0.7), 1.75em 1.75em 0 0em #cd2133, 0em 2.5em 0 0em rgba(205, 33, 51, 0.2), -1.8em 1.8em 0 0em rgba(205, 33, 51, 0.2), -2.6em 0em 0 0em rgba(205, 33, 51, 0.2), -1.8em -1.8em 0 0em rgba(205, 33, 51, 0.2); }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(205, 33, 51, 0.2), 1.8em -1.8em 0 0em rgba(205, 33, 51, 0.2), 2.5em 0em 0 0em rgba(205, 33, 51, 0.5), 1.75em 1.75em 0 0em rgba(205, 33, 51, 0.7), 0em 2.5em 0 0em #cd2133, -1.8em 1.8em 0 0em rgba(205, 33, 51, 0.2), -2.6em 0em 0 0em rgba(205, 33, 51, 0.2), -1.8em -1.8em 0 0em rgba(205, 33, 51, 0.2); }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(205, 33, 51, 0.2), 1.8em -1.8em 0 0em rgba(205, 33, 51, 0.2), 2.5em 0em 0 0em rgba(205, 33, 51, 0.2), 1.75em 1.75em 0 0em rgba(205, 33, 51, 0.5), 0em 2.5em 0 0em rgba(205, 33, 51, 0.7), -1.8em 1.8em 0 0em #cd2133, -2.6em 0em 0 0em rgba(205, 33, 51, 0.2), -1.8em -1.8em 0 0em rgba(205, 33, 51, 0.2); }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(205, 33, 51, 0.2), 1.8em -1.8em 0 0em rgba(205, 33, 51, 0.2), 2.5em 0em 0 0em rgba(205, 33, 51, 0.2), 1.75em 1.75em 0 0em rgba(205, 33, 51, 0.2), 0em 2.5em 0 0em rgba(205, 33, 51, 0.5), -1.8em 1.8em 0 0em rgba(205, 33, 51, 0.7), -2.6em 0em 0 0em #cd2133, -1.8em -1.8em 0 0em rgba(205, 33, 51, 0.2); }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(205, 33, 51, 0.2), 1.8em -1.8em 0 0em rgba(205, 33, 51, 0.2), 2.5em 0em 0 0em rgba(205, 33, 51, 0.2), 1.75em 1.75em 0 0em rgba(205, 33, 51, 0.2), 0em 2.5em 0 0em rgba(205, 33, 51, 0.2), -1.8em 1.8em 0 0em rgba(205, 33, 51, 0.5), -2.6em 0em 0 0em rgba(205, 33, 51, 0.7), -1.8em -1.8em 0 0em #cd2133; } }
