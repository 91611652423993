/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  margin: 30px 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  .Wrapper .Title {
    font-size: 1.4rem;
    margin: 20px 0 15px;
    color: #202124;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500; }
  .Wrapper .PriceList {
    padding: 0; }
    .Wrapper .PriceList .PriceItem {
      display: flex;
      list-style: none;
      justify-content: space-between;
      font-size: 1.4rem;
      color: #202124;
      padding: 10px 15px; }
      .Wrapper .PriceList .PriceItem .Name {
        min-width: 180px;
        text-align: left; }
    .Wrapper .PriceList .PriceItem:last-child {
      padding: 10px 15px 0; }
  .Wrapper .Total {
    margin: 16px;
    padding: 16px 0 5px;
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
    color: #202124;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    border-top: 2px solid #f0f0f0; }
    .Wrapper .Total .TotalPrice {
      color: #CD2033; }
