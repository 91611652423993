/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  min-width: 1080px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  .Wrapper .Background {
    position: relative;
    height: 400px;
    overflow: hidden;
    width: 100%; }
    .Wrapper .Background img {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      height: auto;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%); }
  .Wrapper .Container {
    position: absolute;
    display: flex;
    align-items: center;
    width: 960px;
    margin: 0 auto; }
    .Wrapper .Container .SearchForm {
      width: 100%; }
      .Wrapper .Container .SearchForm .Title {
        font-size: 5.1rem;
        color: #FFFFFF;
        text-align: center;
        margin-bottom: 20px;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500; }
      .Wrapper .Container .SearchForm .Desc {
        font-size: 2.2rem;
        color: #FFFFFF;
        text-align: center; }
