/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper .Header {
  background: #1A6FA3;
  padding: 24px;
  color: #FFFFFF;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500;
  text-align: center; }

.Wrapper .SectionList {
  margin: 0;
  padding: 0; }
  .Wrapper .SectionList .Item {
    padding: 16px 24px;
    list-style: none;
    border-bottom: 1px solid #f0f0f0; }
    .Wrapper .SectionList .Item .Wrapper {
      display: flex;
      justify-content: space-between; }
      .Wrapper .SectionList .Item .Wrapper .Info {
        flex: 1;
        margin-right: 16px; }
        .Wrapper .SectionList .Item .Wrapper .Info .Title {
          color: #202124;
          margin-top: 24px;
          line-height: 1.6em;
          font-family: "Geomanist-Medium", sans-serif;
          font-weight: 500; }
        .Wrapper .SectionList .Item .Wrapper .Info .Desc {
          font-size: 1.4rem; }
    .Wrapper .SectionList .Item .Detail {
      display: flex;
      padding: 6px 0px;
      align-items: center; }
      .Wrapper .SectionList .Item .Detail .Label {
        margin-left: 10px;
        margin-top: 4px;
        font-size: 1.4rem;
        color: #818589; }
    .Wrapper .SectionList .Item .PriceWrapper {
      padding: 5px 0px; }
      .Wrapper .SectionList .Item .PriceWrapper .Details .Title {
        font-size: 1.4rem;
        color: #202124;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        margin-bottom: 16px; }
      .Wrapper .SectionList .Item .PriceWrapper .Details .ItemPrice {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        font-size: 1.4rem;
        color: #202124; }
      .Wrapper .SectionList .Item .PriceWrapper .Total {
        border-top: thin solid #f5f5f5;
        padding-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.4rem;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        color: #202124; }
        .Wrapper .SectionList .Item .PriceWrapper .Total .Price {
          color: #CD2033;
          font-size: 2.2rem; }
  .Wrapper .SectionList .Item:last-child {
    border-bottom: none; }
