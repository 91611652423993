/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  position: fixed;
  z-index: 600;
  bottom: 70px;
  right: 10px; }
  .Wrapper .Button {
    background: #25d366;
    z-index: 1;
    width: 34px;
    height: 34px;
    padding: 8px;
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border: 1px solid rgba(37, 211, 102, 0.5);
    box-shadow: 0 3px 10px 2px rgba(37, 211, 102, 0.2);
    -webkit-box-shadow: 0 3px 10px 2px rgba(37, 211, 102, 0.2);
    -moz-box-shadow: 0 3px 10px 2px rgba(37, 211, 102, 0.2);
    -ms-transform: 0 3px 10px 2px rgba(37, 211, 102, 0.2);
    -o-transform: 0 3px 10px 2px rgba(37, 211, 102, 0.2); }
    .Wrapper .Button .Label {
      color: #FFFFFF;
      margin-left: 8px;
      margin-top: 4px; }
