/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  margin-top: 32px; }
  .Wrapper .Title {
    font-size: 1.6rem;
    color: #202124;
    font-family: "Geomanist-Medium", sans-serif;
    font-weight: 500;
    margin-bottom: 16px; }
  .Wrapper .Item {
    padding: 16px;
    box-sizing: "border-box";
    -moz-box-sizing: "border-box";
    -webkit-box-sizing: "border-box";
    display: flex; }
    .Wrapper .Item .Left {
      flex: 1; }
      .Wrapper .Item .Left .Title {
        font-size: 1.4rem;
        color: #202124;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        margin-bottom: 8px;
        line-height: 20px; }
      .Wrapper .Item .Left .Price {
        font-size: 1.6rem;
        color: #CD2033;
        font-family: "Geomanist-Medium", sans-serif;
        font-weight: 500;
        float: left; }
      .Wrapper .Item .Left .Light {
        font-size: 1.2rem !important;
        font-weight: lighter !important;
        padding: 2px;
        color: #CD2033; }
    .Wrapper .Item .Right {
      width: 30px;
      display: flex;
      justify-content: center;
      padding-top: 10px; }
