/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Button {
  display: inline-block;
  padding: 0 1.4em;
  font-size: 1.3rem;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  outline: none !important;
  border: none;
  color: #6A6E73;
  background: #E6E6E6;
  cursor: pointer;
  font-family: "Geomanist-Medium", sans-serif;
  font-weight: 500;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s; }
  .Button:hover {
    opacity: .9; }
  .Button:disabled {
    opacity: .7; }

.BtnBlock {
  width: 100%; }

.BtnOutline {
  border: 1px solid #f0f0f0;
  color: #CD2033;
  background: none; }

.BtnOutlineRed {
  border: 1px solid #CD2033;
  color: #CD2033;
  background: none; }

.BtnRed {
  color: #FFFFFF;
  background: linear-gradient(to right, #CD2033, #e44631);
  background: -moz-linear-gradient(to right, #CD2033, #e44631);
  background: -webkit-linear-gradient(to right, #CD2033, #e44631);
  background: -o-linear-gradient(to right, #CD2033, #e44631);
  background: -ms-linear-gradient(to right, #CD2033, #e44631); }

.BtnBlue {
  color: #FFFFFF;
  background: #1A6FA3; }

.BtnXSmall {
  height: 28px !important;
  padding: 0 1.4em; }

.BtnSmall {
  height: 34px !important;
  padding: 0 1.4em; }

.BtnMedium {
  height: 40px !important;
  padding: 0 1.4em; }

.BtnLarge {
  height: 46px !important;
  padding: 0 1.4em; }

.BtnXLarge {
  height: 50px !important;
  padding: 0 1.4em; }

.BtnFontXSmall {
  font-size: 1.3rem !important; }

.BtnFontSmall {
  font-size: 1.4rem !important; }

.BtnFontMedium {
  font-size: 1.6rem !important; }

.BtnFontLarge {
  font-size: 1.8rem !important; }

.BtnFontXLarge {
  font-size: 2rem !important; }

.Loader {
  margin: 0 auto;
  border: 2px solid #E6E6E6;
  border-radius: 50%;
  border-top: 2px solid #CD2033;
  width: 12px;
  height: 12px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
