// Pergi Fontfamily
$font-families: 'Geomanist-Regular', 'Geomanist-Medium', 'Geomanist-Book';

@for $i from 1 through length($font-families) {
    @font-face {
        font-family : #{nth($font-families, $i)};
        src         : url('#{"/fonts/"}#{nth($font-families, $i)}.woff') format('woff');
        font-style  : normal;
        font-weight : normal;
    }
}