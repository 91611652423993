/* ------------------------------
ANIMATION
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
COLOR LIBRARY
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
/* ------------------------------
MIXIN
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
@font-face {
  font-family: Geomanist-Regular;
  src: url("/fonts/Geomanist-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Medium;
  src: url("/fonts/Geomanist-Medium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Geomanist-Book;
  src: url("/fonts/Geomanist-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

/* ------------------------------
VARIABLE
Style Sheet for antavaya.com
version: 1.0
author: I Made Widhyarmawan
email: widhyarmawan20@gmail.com
web: widhyarmawan-imade.web.app
---------------------------------*/
.Wrapper {
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: 700;
  left: 0;
  top: 0;
  background: #FAFAFA;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; }
  .Wrapper .Header {
    background: #FFFFFF;
    padding: 0 16px;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    height: 58px;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -webkit-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -moz-box-shadow: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -ms-transform: 0px 1px 4px rgba(32, 33, 36, 0.05);
    -o-transform: 0px 1px 4px rgba(32, 33, 36, 0.05); }
    .Wrapper .Header .BackBtn {
      margin-left: 16px; }
    .Wrapper .Header .SearchForm {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center; }
      .Wrapper .Header .SearchForm .FirstIcon {
        left: 12px;
        position: absolute; }
      .Wrapper .Header .SearchForm .Input {
        width: 100%;
        border: 1px solid #FFFFFF;
        color: #6A6E73;
        padding: 11px 16px 11px 42px;
        font-size: 1.3rem;
        background: #f5f5f5;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        transition: border .3s, color .3s;
        -webkit-transition: border .3s, color .3s;
        -moz-transition: border .3s, color .3s;
        font-family: "Geomanist-Regular", sans-serif;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; }
      .Wrapper .Header .SearchForm .Input:focus {
        outline: none; }
  .Wrapper .Content {
    position: relative;
    padding-top: 58px; }
    .Wrapper .Content .List {
      overflow: auto;
      padding: 8px 20px;
      margin: 0; }
      .Wrapper .Content .List .Item {
        padding: 16px 0;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #f0f0f0;
        font-size: 1.4rem; }
        .Wrapper .Content .List .Item .Country {
          display: flex;
          align-items: center; }
          .Wrapper .Content .List .Item .Country .Image {
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            overflow: hidden; }
          .Wrapper .Content .List .Item .Country .Label {
            margin-left: 16px; }
      .Wrapper .Content .List .NotFound {
        padding: 12px 16px;
        list-style: none; }
      .Wrapper .Content .List .Item:hover {
        background: #FAFAFA; }
      .Wrapper .Content .List .Item:last-child {
        border-bottom: none; }

.Open {
  animation: openPopup 0.3s; }

.Close {
  animation: closePopup 0.3s; }

@keyframes openPopup {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translateY(0); } }

@keyframes closePopup {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(100%); } }
